const moduleRoutes = [
  {
    path: "/facility-booking/dashboard",
    name: "facilityDashboard",
    component: () =>
      import("@/modules/facilityBooking/dashboard/Dashboard.vue"),
    meta: {
      action: "view",
      resource: "facility|facility-slot|facility-booking|facility-court",
      layout: "default",
      title: "Dashboard",
    },
  },
];

export default moduleRoutes;
