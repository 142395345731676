import Content from "@/modules/cms/content/view/Content";
import NewContent from "@/modules/cms/content/entry/NewContent";
// import ContentSetting from "@/modules/cms/ContentSetting";
import EditContent from "@/modules/cms/content/entry/EditContent";

const routes = [
  {
    path: "/cms/contents",
    name: "cms-contents",
    children: [
      {
        path: "",
        name: "contentList",
        component: Content,
        meta: {
          layout: "default",
          action: "view",
          resource: "content",
          title: "Contents",
          auth: true,
          breadcrumbs: [{ title: "Content List", routeName: "contentList" }],
        },
      },
      {
        path: "create",
        name: "newContent",
        component: NewContent,
        meta: {
          layout: "default",
          action: "create",
          resource: "content",
          title: "Create Content",
          auth: true,
          breadcrumbs: [
            { title: "Content List", routeName: "contentList" },
            { title: "Create", routeName: "newContent" },
          ],
        },
      },
      {
        path: ":id/edit/:type",
        name: "editContent",
        component: EditContent,
        meta: {
          layout: "default",
          action: "view",
          resource: "content",
          title: "Edit Content",
          auth: true,
          breadcrumbs: [
            { title: "Content List", routeName: "contentList" },
            { title: "Edit", routeName: "editContent" },
          ],
        },
      },
      // {
      //   path: "/cms/setting",
      //   name: "contentSetting",
      //   component: ContentSetting,
      //   meta: {
      //     layout: "default",
      //     // action: "update",
      //     // resource: "scsc-contents",
      //     title: "Setting Content",
      //     breadcrumbs: [
      //       { title: "Content List", routeName: "contentList" },
      //       { title: "Setting", routeName: "contentSetting" },
      //     ],
      //   },
      // },
    ],
  },
];

export default routes;
