<template>
  <div class="card">
    <div class="flex flex-wrap justify-content-between align-items-center">
      <h5 class="m-0 md:mb-2 block lg:hidden">Edit Content</h5>

      <div class="mt-3 md:mt-0 ml-0 lg:ml-auto">
        <Button
          v-if="$can('delete', 'content')"
          type="button"
          icon="pi pi-trash"
          class="p-button-danger p-button-outlined"
          @click="showConfirmDialog(state.id)"
        />
      </div>
    </div>
    <form class="p-fluid" @submit.prevent="handleSubmit()">
      <div class="grid">
        <!-- Photo -->
        <!-- <label class="col-12 m-0 text-center font-bold">Photo</label>
        <div class="col-12 mb-4">
          <div class="custom-upload text-center" @click="pictureDialog">
            <img
              v-if="crop_photo"
              :src="crop_photo"
              class="member-profile"
              alt="profile"
            />
            <i v-else class="pi pi-plus" style="font-size: 2rem"></i>
          </div>
          <small v-if="errors.has('image')" class="p-error">
            <div
              v-for="error in errors.get('image')"
              :key="error"
              class="text-center"
            >
              {{ error }}
            </div>
          </small>
        </div> -->
        <!-- Phooto -->

        <!-- <div class="field col-12 md:col-6 lg:col-6"> -->

        <div class="field col-12 md:col-6 lg:col-6">
          <div class="grid mt-5 ml-1">
            <div class="flex field-checkbox pr-4 mt-2">
              <Checkbox
                class="mr-0"
                inputId="pinned"
                :binary="true"
                v-model="state.is_pinned"
              />
              <label class="font-bold ml-2 checkbox-label" for="pinned">
                Show on home screen
              </label>
            </div>
          </div>
        </div>

        <div class="field col-12 md:col-6 lg:col-6">
          <div v-if="state.is_pinned">
            <label class="font-bold"> Sort Order </label>
            <div
              v-if="categoryTitle === 'PROMOTION'"
              class="flex flex-row justify-content-start align-items-center mt-3"
            >
              <!-- <Badge
              class="btn-order"
              v-for="(item, index) in orderLimit"
              size="large"
              :key="index"
              :value="item"
              :severity="item === state.order_number ? 'success' : ''"
              @click="changeOrder(item)"
            /> -->

              <span class="p-buttonset">
                <Button
                  label="1"
                  size="small"
                  :class="button.btnColor_1.value"
                  @click="changeOrder(1)"
                />
                <Button
                  label="2"
                  size="small"
                  :class="button.btnColor_2.value"
                  @click="changeOrder(2)"
                />
                <Button
                  label="3"
                  size="small"
                  :class="button.btnColor_3.value"
                  @click="changeOrder(3)"
                /><Button
                  label="4"
                  size="small"
                  :class="button.btnColor_4.value"
                  @click="changeOrder(4)"
                />
                <Button
                  label="5"
                  size="small"
                  :class="button.btnColor_5.value"
                  @click="changeOrder(5)"
                />
                <Button
                  v-tooltip.bottom="'remove selected order no'"
                  icon="pi pi-times"
                  class="p-button-danger px-3"
                  @click="changeOrder('remove')"
                />
              </span>
            </div>

            <div
              v-if="categoryTitle !== 'PROMOTION'"
              class="flex flex-row justify-content-start align-items-center"
            >
              <Badge
                class="btn-order"
                size="large"
                :value="1"
                :severity="state.order_number ? 'success' : ''"
                @click="changeOrder(1)"
              />
            </div>
          </div>
        </div>

        <div class="field col-12 md:col-6 lg:col-6">
          <label for="name" class="font-bold">Title*</label>
          <InputText
            id="name"
            v-model="state.titleEn"
            placeholder="Content title"
            class="w-full"
            :maxlength="maxNameLength"
          />

          <p v-if="state.titleEn.length >= maxNameLength" style="color: red">
            Title must be {{ maxNameLength }} characters or less.
          </p>

          <small v-if="errors.has('title.en')" class="p-error">
            <div v-for="error in errors.get('title.en')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <!-- <div class="field col-12 md:col-6 lg:col-6">
          <label for="name" class="font-bold"> Title (Myanmar)* </label>
          <InputText
            id="name"
            v-model="state.titleMm"
            placeholder="Content title (Myanmar)"
            class="w-full"
          />

          <small v-if="errors.has('title.mm')" class="p-error">
            <div v-for="error in errors.get('title.mm')" :key="error">
              {{ error }}
            </div>
          </small>
        </div> -->

        <div class="field col-12 md:col-6 lg:col-6">
          <label for="category" class="font-bold">Category*</label>

          <Dropdown
            id="category"
            v-model="state.categoryId"
            :options="optionsCategory"
            optionLabel="title"
            optionValue="id"
            placeholder="Select unit"
            class="w-full"
            @change="changeCategoryHandler"
            disabled
          />

          <small v-if="errors.has('category_id')" class="p-error">
            <div v-for="error in errors.get('category_id')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <!-- <div
          class="
            field
            col-6
            md:col-3
            lg:col-3
            flex flex-row
            align-items-center
            h-7rem
          "
        >
          <Checkbox
            class="mr-3"
            inputId="is_notifiable"
            :binary="true"
            v-model="state.is_notifiable"
          />
          <label class="font-bold mt-2" for="is_notifiable">
            Is Notifiable
          </label>
        </div> -->

        <div class="field col-12 md:col-6 lg:col-6">
          <label class="font-bold"> Published At* </label>
          <Calendar
            class="w-full"
            dateFormat="yy-mm-dd"
            v-model="state.published_at"
            placeholder="Choose published Date"
            :showTime="true"
            :showSeconds="true"
          />

          <small v-if="errors.has('published_at')" class="p-error">
            <div v-for="error in errors.get('published_at')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <div class="field col-12 md:col-6 lg:col-6">
          <label for="status" class="font-bold">Status</label>

          <Dropdown
            id="status"
            v-model="state.status"
            :options="optionsStatus"
            optionLabel="name"
            optionValue="code"
            placeholder="Select Status"
            class="w-full"
          />
          <small v-if="errors.has('status')" class="p-error">
            <div v-for="error in errors.get('status')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <div
          v-if="categoryTitle == 'PROMOTION'"
          class="field col-12 md:col-12 lg:col-12"
        >
          <label class="font-bold"> Tags* </label>
          <AutoComplete
            multiple
            v-model="state.tags"
            :suggestions="filteredTags"
            optionLabel="name"
            placeholder="enter tags name"
            @complete="selectedTags"
            @change="changeTags"
          >
            <template #option="slotprops">
              <span> {{ slotprops.option.name }} </span>
              <small class="ml-3 create-new-tag" v-if="slotprops.option.new">
                create new tags
              </small>
            </template>
          </AutoComplete>
          <small v-if="errors.has('tags')" class="p-error">
            <div v-for="error in errors.get('tags')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <div
          class="field col-12 md:col-6 lg:col-6"
          v-if="categoryTitle == 'PROMOTION' || categoryTitle == 'ANNOUNCEMENT'"
        >
          <div class="grid mt-5 ml-1">
            <div class="flex field-checkbox pr-4 mt-2">
              <Checkbox
                class="mr-0"
                inputId="isDeepLink"
                :binary="true"
                v-model="state.isDeepLink"
              />
              <label class="font-bold ml-2" for="isDeepLink">
                Is Deep Link?
              </label>
            </div>
          </div>
        </div>

        <div class="field col-12 md:col-6 lg:col-6">
          <div
            v-if="
              (categoryTitle == 'PROMOTION' ||
                categoryTitle == 'ANNOUNCEMENT') &&
              state.isDeepLink
            "
          >
            <label for="name" class="font-bold">Deep Link*</label>
            <InputText
              id="name"
              v-model="state.deepLink"
              placeholder="Deep Link"
              class="w-full"
            />

            <small v-if="errors.has('deep_link')" class="p-error">
              <div v-for="error in errors.get('deep_link')" :key="error">
                {{ error }}
              </div>
            </small>
          </div>
        </div>

        <div
          class="field col-12 md:col-6 lg:col-6"
          v-if="categoryTitle == 'PROMOTION' && !state.isDeepLink"
        >
          <!-- Banner Photo -->
          <label class="col-12 m-0 text-center font-bold"
            >PROMOTION Photo(Home Screen)*</label
          >
          <div class="col-12 mb-4">
            <div
              class="custom-upload text-center"
              @click="pictureDialog('bannerPhoto')"
            >
              <img
                v-if="crop_banner_photo"
                :src="crop_banner_photo"
                class="member-profile"
                alt="profile"
              />
              <i v-else class="pi pi-plus" style="font-size: 2rem"></i>
            </div>
            <small v-if="errors.has('banner_photo')" class="p-error">
              <div
                v-for="error in errors.get('banner_photo')"
                :key="error"
                class="text-center"
              >
                {{ error }}
              </div>
            </small>
          </div>
          <!-- Banner Photo -->
        </div>

        <div
          class="field col-12 md:col-6 lg:col-6"
          v-if="categoryTitle == 'PROMOTION' && !state.isDeepLink"
        >
          <!-- Banner Cover Photo -->
          <label class="col-12 m-0 text-center font-bold"
            >Cover Photo(Detail Screen)*</label
          >
          <div class="col-12 mb-4">
            <div
              class="custom-upload text-center"
              @click="pictureDialog('bannerCoverPhoto')"
            >
              <img
                v-if="crop_banner_cover_photo"
                :src="crop_banner_cover_photo"
                class="member-profile"
                alt="profile"
              />
              <i v-else class="pi pi-plus" style="font-size: 2rem"></i>
            </div>
            <small v-if="errors.has('cover_photo')" class="p-error">
              <div
                v-for="error in errors.get('cover_photo')"
                :key="error"
                class="text-center"
              >
                {{ error }}
              </div>
            </small>
          </div>
          <!-- Banner Cover Photo -->
        </div>

        <div
          class="field col-12 md:col-12 lg:col-12"
          v-if="categoryTitle == 'PROMOTION' && state.isDeepLink"
        >
          <!-- Banner Deeplink Photo -->
          <label class="col-12 m-0 text-center font-bold"
            >PROMOTION Photo(Home Screen)*</label
          >
          <div class="col-12 mb-4">
            <div
              class="custom-upload text-center"
              @click="pictureDialog('bannerDeeplinkPhoto')"
            >
              <img
                v-if="crop_banner_deeplink_photo"
                :src="crop_banner_deeplink_photo"
                class="member-profile"
                alt="profile"
              />
              <i v-else class="pi pi-plus" style="font-size: 2rem"></i>
            </div>
            <small v-if="errors.has('banner_photo')" class="p-error">
              <div
                v-for="error in errors.get('banner_photo')"
                :key="error"
                class="text-center"
              >
                {{ error }}
              </div>
            </small>
          </div>
          <!-- Banner Deeplink Photo -->
        </div>

        <div
          class="field col-12 md:col-12 lg:col-12"
          v-if="categoryTitle == 'ANNOUNCEMENT' && !state.isDeepLink"
        >
          <!-- Announcement Cover Photo -->
          <label class="col-12 m-0 text-center font-bold"
            >Cover Photo(Home Screen)*</label
          >
          <div class="col-12 mb-4">
            <div
              class="custom-upload text-center"
              @click="pictureDialog('announcementCoverPhoto')"
            >
              <img
                v-if="crop_announcement_cover_photo"
                :src="crop_announcement_cover_photo"
                class="member-profile"
                alt="profile"
              />
              <i v-else class="pi pi-plus" style="font-size: 2rem"></i>
            </div>
            <small v-if="errors.has('cover_photo')" class="p-error">
              <div
                v-for="error in errors.get('cover_photo')"
                :key="error"
                class="text-center"
              >
                {{ error }}
              </div>
            </small>
          </div>
          <!-- Announcement Cover Photo -->
        </div>

        <div
          class="field col-12 md:col-12 lg:col-12"
          v-if="categoryTitle == 'NEWSLETTER'"
        >
          <!-- Newsletter Photo -->
          <label class="col-12 m-0 text-center font-bold"
            >Newsletter Photo(Home Screen)*</label
          >
          <div class="col-12 mb-4">
            <div
              class="custom-upload text-center"
              @click="pictureDialog('newsletterPhoto')"
            >
              <img
                v-if="crop_newsletter_photo"
                :src="crop_newsletter_photo"
                class="member-profile"
                alt="profile"
              />
              <i v-else class="pi pi-plus" style="font-size: 2rem"></i>
            </div>
            <small v-if="errors.has('banner_photo')" class="p-error">
              <div
                v-for="error in errors.get('banner_photo')"
                :key="error"
                class="text-center"
              >
                {{ error }}
              </div>
            </small>
          </div>
          <!-- Newsletter Photo -->
        </div>

        <div class="field col-12 md:col-12 lg:col-12">
          <label for="entries" class="font-bold">Content*</label>
          <Editor v-model="state.contentEn" editorStyle="height: 320px">
            <template #toolbar>
              <span class="ql-formats">
                <select class="ql-header" defaultValue="0">
                  <option value="1">Heading</option>
                  <option value="2">Subheading</option>
                  <option value="0">Normal</option>
                </select>
                <select class="ql-font">
                  <option></option>
                  <option value="serif"></option>
                  <option value="monospace"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-bold" type="button"></button>
                <button class="ql-italic" type="button"></button>
                <button class="ql-underline" type="button"></button>
              </span>
              <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </span>
              <span class="ql-formats">
                <button class="ql-list" value="ordered" type="button"></button>
                <button class="ql-list" value="bullet" type="button"></button>
                <select class="ql-align">
                  <option defaultValue></option>
                  <option value="center"></option>
                  <option value="right"></option>
                  <option value="justify"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-link" type="button"></button>
                <button class="ql-image" type="button"></button>
                <button class="ql-code-block" type="button"></button>
              </span>
            </template>
          </Editor>

          <small v-if="errors.has('content.en')" class="p-error">
            <div v-for="error in errors.get('content.en')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <!-- <div class="field col-12 md:col-6 lg:col-6">
          <label for="entries" class="font-bold">Content MM*</label>
          <Editor v-model="state.contentMm" editorStyle="height: 320px">
            <template #toolbar>
              <span class="ql-formats">
                <select class="ql-header" defaultValue="0">
                  <option value="1">Heading</option>
                  <option value="2">Subheading</option>
                  <option value="0">Normal</option>
                </select>
                <select class="ql-font">
                  <option></option>
                  <option value="serif"></option>
                  <option value="monospace"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-bold" type="button"></button>
                <button class="ql-italic" type="button"></button>
                <button class="ql-underline" type="button"></button>
              </span>
              <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </span>
              <span class="ql-formats">
                <button class="ql-list" value="ordered" type="button"></button>
                <button class="ql-list" value="bullet" type="button"></button>
                <select class="ql-align">
                  <option defaultValue></option>
                  <option value="center"></option>
                  <option value="right"></option>
                  <option value="justify"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-link" type="button"></button>
                <button class="ql-code-block" type="button"></button>
              </span>
            </template>
          </Editor>
          <small v-if="errors.has('content.mm')" class="p-error">
            <div v-for="error in errors.get('content.mm')" :key="error">
              {{ error }}
            </div>
          </small>
        </div> -->

        <!-- <div
          class="
            field
            col-6
            md:col-3
            lg:col-3
            flex flex-row
            align-items-center
            h-7rem
          "
        >
          <Checkbox
            class="mr-3"
            inputId="is_notifiable"
            :binary="true"
            v-model="state.is_notifiable"
          />
          <label class="font-bold mt-2" for="is_notifiable">
            Notify Users
          </label>
        </div> -->

        <!-- <div
          class="
            field
            col-6
            md:col-3
            lg:col-3
            flex flex-row
            align-items-center
            h-7rem
          "
        >
          <Checkbox
            class="mr-3"
            inputId="has_notified"
            :binary="true"
            v-model="state.has_notified"
          />
          <label class="font-bold mt-2" for="has_notified">
            Has Notified
          </label>
        </div> -->

        <!-- <div class="field col-12 md:col-6 lg:col-6"> -->
        <!-- <div class="field col-12 md:col-6 lg:col-6">
          <label class="font-bold"> Start Date </label>
          <Calendar
            class="w-full"
            dateFormat="dd-mm-yy"
            v-model="state.started_at"
            placeholder="Choose Start Date"
          />

          <small v-if="errors.has('started_at')" class="p-error">
            <div v-for="error in errors.get('started_at')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>
        </div> -->

        <!-- <div class="field col-12 md:col-6 lg:col-6">
          <label class="font-bold"> End Date </label>
          <Calendar
            class="w-full"
            dateFormat="dd-mm-yy"
            v-model="state.ended_at"
            placeholder="Choose End Date"
          />

          <small v-if="errors.has('ended_at')" class="p-error">
            <div v-for="error in errors.get('ended_at')" :key="error">
              {{ error }}
            </div>
          </small>
        </div> -->

        <div
          class="field col-12 md:col-12 lg:col-12"
          v-if="
            ((categoryTitle == 'PROMOTION' ||
              categoryTitle == 'ANNOUNCEMENT') &&
              !state.isDeepLink) ||
            categoryTitle == 'NEWSLETTER'
          "
        >
          <label class="font-bold"> Attachments </label>
          <div v-if="attachment_url" class="p-fileupload-files">
            <div v-if="categoryTitle == 'NEWSLETTER'">
              <div
                v-for="(file, index) of attachment_url"
                :key="file"
                class="p-fileupload-row"
              >
                <div>
                  <img
                    v-if="getFileExtension(file) == 'pdf'"
                    :src="require('@/assets/images/pdf.svg')"
                    alt="pdf file"
                    width="50"
                    height="50"
                  />
                </div>
                <!-- <div class="p-fileupload-filename">{{ file.file_name }}</div> -->
                <div class="ml-5">{{ file.formattedSize }}</div>
                <div>
                  <FileUploadButton
                    type="button"
                    icon="pi pi-times"
                    @click="remove(index)"
                  />
                </div>
              </div>
            </div>

            <div v-else>
              <div
                v-for="(file, index) of attachment_url"
                :key="file"
                class="p-fileupload-row"
              >
                <div>
                  <img
                    v-if="
                      getFileExtension(file.url) == 'ppt' ||
                      getFileExtension(file.url) == 'pptx'
                    "
                    :src="require('@/assets/images/power-point.svg')"
                    alt="power point"
                    width="50"
                    height="50"
                  />
                  <img
                    v-if="
                      getFileExtension(file.url) == 'docx' ||
                      getFileExtension(file.url) == 'doc'
                    "
                    :src="require('@/assets/images/word.svg')"
                    alt="word file"
                    width="50"
                    height="50"
                  />
                  <img
                    v-if="getFileExtension(file.url) == 'pdf'"
                    :src="require('@/assets/images/pdf.svg')"
                    alt="pdf file"
                    width="50"
                    height="50"
                  />
                  <img
                    v-if="getFileExtension(file.url) == 'pub'"
                    :src="require('@/assets/images/publisher.svg')"
                    alt="publisher file"
                    width="50"
                    height="50"
                  />
                  <img
                    v-if="
                      getFileExtension(file.url) == 'csv' ||
                      getFileExtension(file.url) == 'xlsx' ||
                      getFileExtension(file.url) == 'xls'
                    "
                    :src="require('@/assets/images/excel.svg')"
                    alt="power point"
                    width="50"
                    height="50"
                  />
                  <img
                    v-if="
                      getFileExtension(file.url) == 'png' ||
                      getFileExtension(file.url) == 'jpg' ||
                      getFileExtension(file.url) == 'jpeg'
                    "
                    :src="file.url"
                    alt="image file"
                    width="50"
                    height="50"
                  />
                </div>
                <!-- <div class="p-fileupload-filename">{{ file.file_name }}</div> -->
                <div class="ml-5">{{ file.formattedSize }}</div>
                <div>
                  <FileUploadButton
                    type="button"
                    icon="pi pi-times"
                    @click="remove(index)"
                  />
                </div>
              </div>
            </div>
          </div>

          <FileUpload
            ref="fileUpload"
            v-if="categoryTitle == 'NEWSLETTER'"
            :showUploadButton="false"
            :showCancelButton="false"
            :multiple="true"
            :maxFileSize="20000000"
            @select="onSelectedFiles($event)"
            @remove="removeUploadedFile($event)"
            accept="application/pdf"
            :fileLimit="1"
          >
            <template #empty>
              <div
                class="flex align-items-center justify-content-center flex-column"
              >
                <i
                  class="pi pi-cloud-upload border-2 border-circle p-5 text-5xl text-400 border-400"
                />
                <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
              </div>
            </template>
          </FileUpload>

          <FileUpload
            ref="fileUpload"
            :showUploadButton="false"
            :showCancelButton="false"
            :multiple="true"
            :maxFileSize="20000000"
            @select="onSelectedFiles($event)"
            @remove="removeUploadedFile($event)"
            v-else
          >
            <template #empty>
              <div
                class="flex align-items-center justify-content-center flex-column"
              >
                <i
                  class="pi pi-cloud-upload border-2 border-circle p-5 text-5xl text-400 border-400"
                />
                <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
              </div>
            </template>
          </FileUpload>

          <small v-if="errors.has('newsletter')" class="p-error">
            <div v-for="error in errors.get('newsletter')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>
      </div>

      <div class="col-12 md:col-12 flex justify-content-end">
        <!-- v-if="$can('index', 'cms-contents')" -->
        <router-link :to="{ name: 'contentList' }">
          <div><Button label="Cancel" class="mt-2 p-button-outlined" /></div>
        </router-link>

        <div>
          <Button
            v-if="$can('update', 'content')"
            type="submit"
            label="Update"
            class="ml-2 mt-2"
          />
        </div>
      </div>
    </form>

    <!-- Banner Photo -->
    <Dialog
      header="Crop Image"
      :closable="false"
      v-model:visible="showBannerPhotoCropDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '300px' }"
    >
      <div style="max-width: 300px">
        <cropper
          class="cropper"
          v-if="banner_photo"
          :src="banner_photo"
          :stencil-props="{
            aspectRatio: 2 / 1,
            movable: true,
            resizable: true,
          }"
          @change="cropBannerPhotoImage"
        ></cropper>
      </div>

      <template #footer>
        <Button label="Got it" @click="showBannerPhotoCropDialog = false" />
      </template>
    </Dialog>

    <Dialog
      header="Photo"
      v-model:visible="showBannerPhotoPictureDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      position="bottom"
      :modal="true"
    >
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeBannerPhotoPicture"
      >
        <i class="ri-image-line mr-3"></i>From Photos
      </div>
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeBannerPhotoCamera"
      >
        <i class="ri-camera-line mr-3"></i>Take Picture
      </div>
    </Dialog>
    <!-- Banner Photo -->

    <!-- Banner Cover Photo -->
    <Dialog
      header="Crop Image"
      :closable="false"
      v-model:visible="showBannerCoverPhotoCropDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '300px' }"
    >
      <div style="max-width: 300px">
        <cropper
          class="cropper"
          v-if="banner_cover_photo"
          :src="banner_cover_photo"
          :stencil-props="{
            aspectRatio: 2 / 1,
            movable: true,
            resizable: true,
          }"
          @change="cropBannerCoverPhotoImage"
        ></cropper>
      </div>

      <template #footer>
        <Button
          label="Got it"
          @click="showBannerCoverPhotoCropDialog = false"
        />
      </template>
    </Dialog>

    <Dialog
      header="Photo"
      v-model:visible="showBannerCoverPhotoPictureDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      position="bottom"
      :modal="true"
    >
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeBannerCoverPhotoPicture"
      >
        <i class="ri-image-line mr-3"></i>From Photos
      </div>
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeBannerCoverPhotoCamera"
      >
        <i class="ri-camera-line mr-3"></i>Take Picture
      </div>
    </Dialog>
    <!-- Banner Cover Photo -->

    <!-- Banner Deeplink Photo -->
    <Dialog
      header="Crop Image"
      :closable="false"
      v-model:visible="showBannerDeeplinkPhotoCropDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '300px' }"
    >
      <div style="max-width: 300px">
        <cropper
          class="cropper"
          v-if="banner_deeplink_photo"
          :src="banner_deeplink_photo"
          :stencil-props="{
            aspectRatio: 2 / 1,
            movable: true,
            resizable: true,
          }"
          @change="cropBannerDeeplinkPhotoImage"
        ></cropper>
      </div>

      <template #footer>
        <Button
          label="Got it"
          @click="showBannerDeeplinkPhotoCropDialog = false"
        />
      </template>
    </Dialog>

    <Dialog
      header="Photo"
      v-model:visible="showBannerDeeplinkPhotoPictureDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      position="bottom"
      :modal="true"
    >
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeBannerDeeplinkPhotoPicture"
      >
        <i class="ri-image-line mr-3"></i>From Photos
      </div>
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeBannerDeeplinkPhotoCamera"
      >
        <i class="ri-camera-line mr-3"></i>Take Picture
      </div>
    </Dialog>
    <!-- Banner Deeplink Photo -->

    <!-- Announcement Cover Photo -->
    <Dialog
      header="Crop Image"
      :closable="false"
      v-model:visible="showAnnouncementCoverPhotoCropDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '300px' }"
    >
      <div style="max-width: 300px">
        <cropper
          class="cropper"
          v-if="announcement_cover_photo"
          :src="announcement_cover_photo"
          :stencil-props="{
            aspectRatio: 2 / 1,
            movable: true,
            resizable: true,
          }"
          @change="cropAnnouncementCoverPhotoImage"
        ></cropper>
      </div>

      <template #footer>
        <Button
          label="Got it"
          @click="showAnnouncementCoverPhotoCropDialog = false"
        />
      </template>
    </Dialog>

    <Dialog
      header="Photo"
      v-model:visible="showAnnouncementCoverPhotoPictureDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      position="bottom"
      :modal="true"
    >
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeAnnouncementCoverPhotoPicture"
      >
        <i class="ri-image-line mr-3"></i>From Photos
      </div>
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeAnnouncementCoverPhotoCamera"
      >
        <i class="ri-camera-line mr-3"></i>Take Picture
      </div>
    </Dialog>
    <!-- Announcement Photo -->

    <!-- Newsletter Photo -->
    <Dialog
      header="Crop Image"
      :closable="false"
      v-model:visible="showNewsletterPhotoCropDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '300px' }"
    >
      <div style="max-width: 300px">
        <cropper
          class="cropper"
          v-if="newsletter_photo"
          :src="newsletter_photo"
          :stencil-props="{
            aspectRatio: 4 / 5,
            movable: true,
            resizable: true,
          }"
          @change="cropNewsletterPhotoImage"
        ></cropper>
      </div>

      <template #footer>
        <Button label="Got it" @click="showNewsletterPhotoCropDialog = false" />
      </template>
    </Dialog>

    <Dialog
      header="Photo"
      v-model:visible="showNewsletterPhotoPictureDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      position="bottom"
      :modal="true"
    >
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeNewsletterPhotoPicture"
      >
        <i class="ri-image-line mr-3"></i>From Photos
      </div>
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeNewsletterPhotoCamera"
      >
        <i class="ri-camera-line mr-3"></i>Take Picture
      </div>
    </Dialog>
    <!-- Newsletter Photo -->

    <ConfirmDialog
      :showHeader="false"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '400px' }"
    >
      <template #message="slotProps">
        <div class="text-center w-full">
          <Button
            type="button"
            :icon="slotProps.message.icon"
            :class="
              infoDialog == 'info'
                ? 'p-button-lg p-button-info p-button-rounded p-button-outlined mt-4'
                : 'p-button-lg p-button-danger p-button-rounded p-button-outlined mt-4'
            "
          />
          <h4>{{ slotProps.message.message }}</h4>
        </div>
      </template>
    </ConfirmDialog>

    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
// import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Loading from "@/components/Loading.vue";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Editor from "primevue/editor";
import ConfirmDialog from "primevue/confirmdialog";
import FileUpload from "@/components/FileUpload.vue";
// import FileUploadButton from "@/components/FileUpload.vue";
import { Cropper } from "vue-advanced-cropper";
import { defineComponent } from "vue";
import { useEditContent } from "@/modules/cms/content/entry/useEditContent";
// import Quill from "quill";

export default defineComponent({
  name: "EditContent",
  components: {
    Loading,
    Button,
    InputText,
    Dialog,
    Cropper,
    Dropdown,
    Editor,
    ConfirmDialog,
    FileUpload,
    FileUploadButton: Button,
  },
  setup() {
    const {
      isLoading,
      state,
      submitted,
      handleSubmit,
      errors,
      optionsStatus,
      optionsCategory,
      showConfirmDialog,
      crop_photo,
      cropImage,
      showCropDialog,
      takePicture,
      photo,
      onSelectedFiles,
      showPictureDialog,
      pictureDialog,
      takeCamera,
      remove,
      attachment_url,
      formatSize,
      categoryTitle,
      banner_photo,
      banner_cover_photo,
      banner_deeplink_photo,
      announcement_cover_photo,
      newsletter_photo,
      crop_banner_photo,
      crop_banner_cover_photo,
      crop_banner_deeplink_photo,
      crop_announcement_cover_photo,
      crop_newsletter_photo,
      cropBannerPhotoImage,
      cropBannerCoverPhotoImage,
      cropBannerDeeplinkPhotoImage,
      cropAnnouncementCoverPhotoImage,
      cropNewsletterPhotoImage,
      showBannerPhotoCropDialog,
      showBannerCoverPhotoCropDialog,
      showBannerDeeplinkPhotoCropDialog,
      showAnnouncementCoverPhotoCropDialog,
      showNewsletterPhotoCropDialog,
      showBannerPhotoPictureDialog,
      showBannerCoverPhotoPictureDialog,
      showBannerDeeplinkPhotoPictureDialog,
      showAnnouncementCoverPhotoPictureDialog,
      showNewsletterPhotoPictureDialog,
      filteredTags,
      changeOrder,
      selectedTags,
      changeTags,
      takeBannerPhotoPicture,
      takeBannerCoverPhotoPicture,
      takeBannerDeeplinkPhotoPicture,
      takeAnnouncementCoverPhotoPicture,
      takeNewsletterPhotoPicture,
      takeBannerPhotoCamera,
      takeBannerCoverPhotoCamera,
      takeBannerDeeplinkPhotoCamera,
      takeAnnouncementCoverPhotoCamera,
      takeNewsletterPhotoCamera,
      orderLimit,
      changeCategoryHandler,
      infoDialog,
      button,
      fileUpload,
      getFileExtension,
      removeUploadedFile,
      maxNameLength,
    } = useEditContent();

    return {
      isLoading,
      state,
      submitted,
      handleSubmit,
      selectedTags,
      errors,
      optionsStatus,
      optionsCategory,
      showConfirmDialog,
      crop_photo,
      cropImage,
      showCropDialog,
      takePicture,
      photo,
      onSelectedFiles,
      showPictureDialog,
      pictureDialog,
      takeCamera,
      remove,
      attachment_url,
      formatSize,
      categoryTitle,
      banner_photo,
      banner_cover_photo,
      banner_deeplink_photo,
      announcement_cover_photo,
      newsletter_photo,
      crop_banner_photo,
      crop_banner_cover_photo,
      crop_banner_deeplink_photo,
      crop_announcement_cover_photo,
      crop_newsletter_photo,
      cropBannerPhotoImage,
      cropBannerCoverPhotoImage,
      cropBannerDeeplinkPhotoImage,
      cropAnnouncementCoverPhotoImage,
      cropNewsletterPhotoImage,
      showBannerPhotoCropDialog,
      showBannerCoverPhotoCropDialog,
      showBannerDeeplinkPhotoCropDialog,
      showAnnouncementCoverPhotoCropDialog,
      showNewsletterPhotoCropDialog,
      showBannerPhotoPictureDialog,
      showBannerCoverPhotoPictureDialog,
      showBannerDeeplinkPhotoPictureDialog,
      showAnnouncementCoverPhotoPictureDialog,
      showNewsletterPhotoPictureDialog,
      filteredTags,
      changeTags,
      changeOrder,
      takeBannerPhotoPicture,
      takeBannerCoverPhotoPicture,
      takeBannerDeeplinkPhotoPicture,
      takeAnnouncementCoverPhotoPicture,
      takeNewsletterPhotoPicture,
      takeBannerPhotoCamera,
      takeBannerCoverPhotoCamera,
      takeBannerDeeplinkPhotoCamera,
      takeAnnouncementCoverPhotoCamera,
      takeNewsletterPhotoCamera,
      orderLimit,
      changeCategoryHandler,
      infoDialog,
      button,
      fileUpload,
      getFileExtension,
      removeUploadedFile,
      maxNameLength,
    };
  },
});

// var toolbarOptions = ["bold", "italic", "underline", "strike"];

// var quill = new Quill("#editor", {
//   modules: {
//     toolbar: toolbarOptions,
//   },
// });
</script>
<style lang="scss" scoped>
.card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.p-fileupload-files > .p-fileupload-row {
  padding: 10px;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1rem;
}

.p-fileupload-filename {
  word-break: break-all;
}

.order-btn {
  border-radius: 0px;
}

.order-btn-active {
  background-color: green;
}
.checkbox-label {
  margin-bottom: 3px;
}

.btn-order {
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 0px;
}

.btn-order:hover {
  background-color: #6d737a;
}

.p-autocomplete-token {
  background: var(--primary-color) !important;
  color: var(--primary-color-text) !important;
}
</style>
