import { createRouter, createWebHistory } from "vue-router";

/**
 * Import module's routes
 */
import dashboardRoutes from "@/modules/dashboard/homeRoute";
import authRoutes from "@/modules/auth/authRoute";
import memberRoutes from "@/modules/scsc/member/memberRoute";
import familyMemberRoutes from "@/modules/scsc/familyMember/familyMemberRoute";
import prepaidRoutes from "@/modules/scsc/sales/prepaid/prepaidRoute";

import subscriptionRoutes from "@/modules/scsc/sales/subscription/subscriptionRoute";
import userRoutes from "@/modules/userManagement/users/userRoute";
import categoryRoutes from "@/modules/cms/category/categoryRoute";
import contentRoutes from "@/modules/cms/content/contentRoute";
// import permissionRoutes from "@/modules/applicationPermission/permission/permissionRoute";
import roleRoutes from "@/modules/applicationPermission/role/roleRoute";
import caseSettingRoutes from "@/modules/caseManagement/setting/caseSettingRoute";
import settingRoutes from "@/modules/setting/settingRoute";
// import familyMemberRoutes from "@/modules/member/familymember/familyMemberRoute";

import { useAuthStore } from "@/modules/auth/authStore";
import scscDashboardRoutes from "@/modules/scsc/dashboard/scscDashboardRoute";
import caseManagementRoutes from "@/modules/caseManagement/caseManagementRoutes";
// import emailRecipientRoutes from "@/modules/caseManagement/emailRecipientSetting/route";
import { userAccountRoutes } from "@/modules/user/userProfileRoute";
import { communityRoutes } from "@/modules/community/communityRoutes";
import termsAndConditionsRoutes from "@/modules/termsAndConditions/termsAndConditionsRoute";

//resident Module

import residentActiveRoutes from "@/modules/resident/active/route";
import residentInactiveRoutes from "@/modules/resident/inactive/route";
import residentBillPaymentRoutes from "@/modules/resident/billpayment/route";
import residentPendingRoutes from "@/modules/resident/pending/route";
import residentRejectRoutes from "@/modules/resident/reject/route";
import residentRejectRemarkRoutes from "@/modules/resident/rejectRemark/route";
// import residentSettingRoutes from "@/modules/resident/setting/route";

// facility booking
import sportTypeRoutes from "@/modules/facilityBooking/sportType/route";
import courtRoutes from "@/modules/facilityBooking/court/route";
import bookingRoutes from "@/modules/facilityBooking/booking/route";
import timeslotRoutes from "@/modules/facilityBooking/timeslot/route";
import facilityDashboardRoutes from "@/modules/facilityBooking/dashboard/route";
import faqCategoryRoutes from "@/modules/faq/useFAQRoutes";
import appVersionRoute from "@/modules/appVersion/route";

import { canNavigate } from "@/libs/acl/routeProtection";

const routes = [
  ...appVersionRoute,
  ...residentPendingRoutes,
  ...residentBillPaymentRoutes,
  ...residentActiveRoutes,
  ...residentRejectRoutes,
  ...residentInactiveRoutes,
  ...residentRejectRemarkRoutes,
  // ...residentSettingRoutes,
  ...dashboardRoutes,
  ...authRoutes,
  ...memberRoutes,
  ...familyMemberRoutes,
  ...categoryRoutes,
  ...contentRoutes,
  ...prepaidRoutes,
  ...subscriptionRoutes,
  ...memberRoutes,
  ...userRoutes,
  // ...permissionRoutes,
  ...roleRoutes,
  ...memberRoutes,
  ...familyMemberRoutes,
  ...scscDashboardRoutes,
  ...caseManagementRoutes,
  ...caseSettingRoutes,
  // ...emailRecipientRoutes,
  ...userAccountRoutes,
  ...settingRoutes,
  ...communityRoutes,
  ...termsAndConditionsRoutes,

  ...sportTypeRoutes,
  ...courtRoutes,
  ...bookingRoutes,
  ...timeslotRoutes,
  ...facilityDashboardRoutes,
  ...faqCategoryRoutes,

  {
    path: "/error-404",
    name: "error404",
    component: () => import("@/pages/NotFound.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/not-authorized",
    name: "not-authorized",
    component: () => import("@/pages/Access.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/redirect",
    name: "redirect",
    component: () => import("@/pages/Redirect.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/invalid-token",
    name: "invalid-token",
    component: () => import("@/pages/InvalidToken.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/:catchAll(.*)*",
    redirect: "error-404",
    meta: {
      layout: "full",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  linkActiveClass: "router-link-active",
  linkExactActiveClass: "router-link-exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  const authStore = useAuthStore();
  const isLoggedIn = authStore.isAuth;

  const userData = authStore.getUserData || "";

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn)
      return next({ name: "login", query: { redirect: to.fullPath } });

    // If logged in => not authorized
    return next({ name: "not-authorized" });
  }
  // // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next({ name: "dashboard", query: { redirect: to.fullPath } });
  }

  if (to.name === "SetPasscode" && !userData.is_need_passcode) {
    // If the user has already passed the passcode screen, redirect to the dashboard
    return next({ name: "dashboard" });
  } else if (
    to.name !== "SetPasscode" &&
    userData.is_need_passcode &&
    isLoggedIn
  ) {
    // If the user needs to pass the passcode screen and is logged in, redirect to the passcode screen
    return next({ name: "SetPasscode" });
  }

  return next();
});

export default router;
