const moduleRoutes = [
  {
    path: "/resident/active",
    name: "active",
    children: [
      {
        path: "",
        name: "residentActive",
        component: () =>
          import("@/modules/resident/active/list/ActiveResident.vue"),
        meta: {
          action: "view",
          resource: "resident",
          layout: "default",
          title: "Active List",
          breadcrumbs: [{ title: "Active List", routeName: "residentActive" }],
        },
      },
      {
        path: ":id/edit",
        name: "ActiveResidentDetail",
        component: () =>
          import("@/modules/resident/active/entry/ActiveResidentDetail.vue"),
        meta: {
          layout: "default",
          action: "view",
          resource: "resident",
          title: "Active Detail",
          breadcrumbs: [
            { title: "Active List", routeName: "residentActive" },
            { title: "Detail", routeName: "ActiveResidentDetail" },
          ],
        },
      },
    ],
  },
];

export default moduleRoutes;
