import {
  onMounted,
  reactive,
  ref,
  watch,
  onBeforeUnmount,
  computed,
} from "vue";
import { useContentStore } from "@/modules/cms/content/contentStore";
import { useCategoryStore } from "@/modules/cms/category/categoryStore";
import { useRoute, useRouter } from "vue-router";
import EventBus from "@/libs/AppEventBus";
import { useConfirm } from "primevue/useconfirm";
import { Errors } from "@/utils/validation";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { utcToLocal } from "@/utils/formatter";
// import axios from "axios";

export const useEditContent = () => {
  const contentStore = useContentStore();
  const categoryStore = useCategoryStore();
  const route = useRoute();
  const router = useRouter();
  const confirm = useConfirm();
  const fileUpload = ref([]);

  const isLoading = ref(false);
  const submitted = ref(false);
  const errors = new Errors();
  const showCropDialog = ref(false);
  const crop_photo = ref(null);
  const photo = ref(null);
  const optionsCategory = ref([]);
  const attachments = ref([]);
  const newsletter = ref([]);
  const attachment_url = ref([]);
  const categoryTitle = ref("");
  const filteredTags = ref([]);
  const showBannerPhotoCropDialog = ref(false);
  const showBannerCoverPhotoCropDialog = ref(false);
  const showBannerDeeplinkPhotoCropDialog = ref(false);
  const showAnnouncementCoverPhotoCropDialog = ref(false);
  const showNewsletterPhotoCropDialog = ref(false);
  const crop_banner_photo = ref(null);
  const crop_banner_cover_photo = ref(null);
  const crop_banner_deeplink_photo = ref(null);
  const crop_announcement_cover_photo = ref(null);
  const crop_newsletter_photo = ref(null);
  const banner_photo = ref(null);
  const banner_cover_photo = ref(null);
  const banner_deeplink_photo = ref(null);
  const announcement_cover_photo = ref(null);
  const newsletter_photo = ref(null);
  const bannerFileUrl = ref("");
  const bannerCoverFileUrl = ref("");
  const bannerDeeplinkFileUrl = ref("");
  const announcementCoverFileUrl = ref("");
  const newsletterFileUrl = ref("");
  const showBannerPhotoPictureDialog = ref(false);
  const showBannerCoverPhotoPictureDialog = ref(false);
  const showBannerDeeplinkPhotoPictureDialog = ref(false);
  const showAnnouncementCoverPhotoPictureDialog = ref(false);
  const showNewsletterPhotoPictureDialog = ref(false);
  const tags = ref([]);
  const showPictureDialog = ref(false);
  const sortOrderArr = ref([]);
  const getOrderNumber = ref(null);
  const infoDialog = ref("");
  const sortOrderResponse = ref([]);
  const goLinkId = ref("");
  const noData = ref("");

  const button = {
    btnColor_1: ref("p-button-outlined"),
    btnColor_2: ref("p-button-outlined"),
    btnColor_3: ref("p-button-outlined"),
    btnColor_4: ref("p-button-outlined"),
    btnColor_5: ref("p-button-outlined"),
  };

  const state = reactive({
    titleEn: "",
    titleMm: "",
    status: "",
    categoryId: "",
    contentEn: "",
    contentMm: "",
    is_notifiable: true,
    // has_notified: false,
    published_at: "",
    isDeepLink: false,
    deepLink: "",
    is_pinned: false,
    tags: [],
    category_title: null,
    order_number: 10,
    // started_at: null,
    // ended_at: null,
  });

  const optionsStatus = ref([
    { name: "Active", code: "ACTIVE" },
    { name: "Disabled", code: "DISABLED" },
    { name: "Draft", code: "DRAFT" },
  ]);

  const tagsData = async () => {
    const response = await contentStore.tagsListAction();

    if (response) {
      tags.value = response.data;
    }
  };

  onMounted(() => {
    tagsData();
    categoryData();
  });

  onBeforeUnmount(() => {
    contentStore.$reset();
    contentStore.$dispose();
    categoryStore.$reset();
    categoryStore.$dispose();
  });

  const categoryData = async () => {
    isLoading.value = true;

    try {
      await categoryStore.allCategoryAction();

      const response = categoryStore.getAllCategory;

      if (response) {
        optionsCategory.value = response.data;
        state.categoryId = response.data[0];
        editContent();
      }

      isLoading.value = false;
    } catch (error) {
      isLoading.value = false;
      if (error.status === 422) {
        const err = error.data.data;
        errors.record(err);
      }
    }
  };

  const editContent = async () => {
    // isLoading.value = true;
    state.tags = [];
    sortOrderArr.value = [];
    attachments.value = [];
    crop_banner_photo.value = "";
    crop_banner_cover_photo.value = "";
    crop_banner_deeplink_photo.value = "";
    crop_announcement_cover_photo.value = "";
    crop_newsletter_photo.value = "";

    if (fileUpload.value) {
      fileUpload.value.files = [];
      fileUpload.value.uploadedFileCount = 0;
    }

    await contentStore.editContentAction({
      id: route.params.id,
    });

    const response = contentStore.getEditContent;
    if (response) {
      (state.id = route.params.id), (state.name = response.data.name);
      state.titleEn = response.data.title ? response.data.title.en : "";
      state.titleMm = response.data.title ? response.data.title.mm : "";
      state.contentEn = response.data.content ? response.data.content.en : "";
      state.contentMm = response.data.content ? response.data.content.mm : "";
      state.status = response.data.status;
      state.categoryId = response.data.category_id;
      state.isDeepLink = response.data.deep_link ? true : false;
      state.is_pinned = response.data.order_number
        ? response.data.is_pinned
        : false;
      state.deepLink = response.data.deep_link;
      state.category_title = response.data.category_title;
      response.data.tags.map((value) => {
        state.tags.push({ name: value.name.en });
      });

      state.order_number = response.data.order_number
        ? response.data.order_number
        : 10;
      getOrderNumber.value = response.data.order_number;

      button[`btnColor_1`].value = "p-button-outlined";
      button[`btnColor_2`].value = "p-button-outlined";
      button[`btnColor_3`].value = "p-button-outlined";
      button[`btnColor_4`].value = "p-button-outlined";
      button[`btnColor_5`].value = "p-button-outlined";

      for (let i = 1; i < 6; i++) {
        if (state.order_number == i) {
          button[`btnColor_${i}`].value = "p-button-success";
        }
      }

      if (state.category_title == "PROMOTION") {
        await contentStore.sortOrderAction({
          id: route.params.id,
          type: "PROMOTION",
        });

        sortOrderResponse.value = contentStore.getSortOrderState.data;
      }

      if (sortOrderResponse.value.length > 0) {
        for (let i = 0; i < sortOrderResponse.value.length; i++) {
          const orderNumber = sortOrderResponse.value[i].order_number;
          sortOrderArr.value.push(orderNumber);
          button[`btnColor_${orderNumber}`].value = "p-button-secondary";
        }
      }

      // state.is_notifiable = response.data.is_notifiable;
      // state.has_notified = response.data.has_notified;
      bindPhoto(
        response.data.category_id,
        response.data.banner_photo,
        response.data.cover_photo
      );
      state.published_at = response.data.published_at
        ? utcToLocal(response.data.published_at)
        : null;

      if (response.data.category_title == "NEWSLETTER") {
        attachment_url.value =
          response.data.newsletter != null ? [response.data.newsletter] : [];
      }

      if (response.data.category_title != "NEWSLETTER") {
        attachment_url.value = response.data.attachments || [];
      }

      if (response.data.category_title != "NEWSLETTER") {
        for (let file of attachment_url.value) {
          file.formattedSize = await formatSize(file.file_size);
        }
      }
    }
    // isLoading.value = false;
  };

  const handleSubmit = () => {
    updateContent();
  };

  const updateContent = async () => {
    errors.clear();
    isLoading.value = true;

    // const content = JSON.stringify({
    //   en: state.contentEn ? state.contentEn : state.contentMm,
    //   mm: state.contentMm ? state.contentMm : state.contentEn,
    // });

    // const title = JSON.stringify({
    //   en: state.titleEn ? state.titleEn : state.titleMm,
    //   mm: state.titleMm ? state.titleMm : state.titleEn,
    // });

    var formData = new FormData();
    formData.append("id", route.params.id);

    if (!state.isDeepLink && categoryTitle.value == "PROMOTION") {
      formData.append(
        "banner_photo",
        bannerFileUrl.value
          ? bannerFileUrl.value || ""
          : await convertURIToImageData(crop_banner_photo.value)
      );

      formData.append(
        "cover_photo",
        bannerCoverFileUrl.value
          ? bannerCoverFileUrl.value || ""
          : await convertURIToImageData(crop_banner_cover_photo.value)
      );
      formData.append("deep_link", "");
    }

    if (state.isDeepLink && categoryTitle.value == "PROMOTION") {
      formData.append(
        "banner_photo",
        bannerDeeplinkFileUrl.value
          ? bannerDeeplinkFileUrl.value || ""
          : await convertURIToImageData(crop_banner_deeplink_photo.value)
      );
    }

    if (!state.isDeepLink && categoryTitle.value == "ANNOUNCEMENT") {
      formData.append(
        "cover_photo",
        announcementCoverFileUrl.value
          ? announcementCoverFileUrl.value || ""
          : await convertURIToImageData(crop_announcement_cover_photo.value)
      );
      formData.append("deep_link", "");
    }

    if (categoryTitle.value == "NEWSLETTER") {
      formData.append(
        "banner_photo",
        newsletterFileUrl.value
          ? newsletterFileUrl.value || ""
          : await convertURIToImageData(crop_newsletter_photo.value)
      );
    }

    if (
      state.isDeepLink &&
      (categoryTitle.value == "PROMOTION" ||
        categoryTitle.value == "ANNOUNCEMENT")
    ) {
      formData.append("deep_link", state.deepLink);
    }

    // if (state.titleEn || state.titleMm) {
    //   formData.append("title", title);
    // }

    // if (state.contentEn || state.contentMm) {
    //   formData.append("content", content);
    // }

    // formData.append("title[en]", state.titleEn);
    // formData.append("title[mm]", state.titleMm);
    // formData.append("content[en]", state.contentEn);
    // formData.append("content[mm]", state.contentMm);

    formData.append("title[en]", state.titleEn.slice(0, maxNameLength.value));
    formData.append("content[en]", state.contentEn);

    formData.append("category_id", state.categoryId || "");
    formData.append("category_title", categoryTitle.value || "");
    formData.append("status", state.status || "");
    // formData.append(
    //   "published_at",
    //   state.published_at ? dateShort(state.published_at) : null
    // );
    formData.append("is_notifiable", state.is_notifiable ? 1 : 0);

    state.tags.map((value) => formData.append("tags[]", value.name));
    // formData.append("has_notified", state.has_notified ? 1 : 0);
    // formData.append("started_at", dateShort(state.started_at) || null);
    // formData.append("ended_at", dateShort(state.ended_at) || null);
    if (attachment_url.value.length > 0) {
      for (let i = 0; i < attachment_url.value.length; i++) {
        const data = await fetch(attachment_url.value[i].url);
        const blob = await data.blob();
        const file = new File([blob], attachment_url.value[i].url);
        attachments.value.push(file);
      }
    }
    if (
      attachments.value.length > 0 &&
      !state.isDeepLink &&
      categoryTitle.value != "NEWSLETTER"
    ) {
      attachments.value.map((value) => {
        formData.append("attachments[]", value || null);
      });
    }

    if (categoryTitle.value == "NEWSLETTER") {
      let newsLetter = null;
      if (attachment_url.value.length > 0) {
        for (let i = 0; i < attachment_url.value.length; i++) {
          const data = await fetch(attachment_url.value[i]);
          const blob = await data.blob();
          const file = new File([blob], attachment_url.value[i]);
          attachments.value.push(file);
        }
        attachments.value.map((value) => {
          newsLetter = value;
        });
      }

      if (newsletter.value.length > 0) {
        newsLetter = newsletter.value[0];
        newsletter.value = [];
      }
      formData.append("newsletter", newsLetter);
    }

    if (
      categoryTitle.value == "ANNOUNCEMENT" ||
      categoryTitle.value == "NEWSLETTER"
    ) {
      state.order_number = 1;
      formData.append(
        "order_number",
        state.is_pinned ? state.order_number : 10
      );
    }

    if (categoryTitle.value == "PROMOTION") {
      formData.append(
        "order_number",
        state.is_pinned ? state.order_number : 10
      );
    }

    formData.append(
      "is_pinned",
      state.order_number == 10 ? 0 : !state.is_pinned ? 0 : 1
    );

    formData.append(
      "is_deep_link",
      state.categoryId.title == "NEWSLETTER" ? 0 : state.isDeepLink ? 1 : 0
    );

    formData.append("_method", "PUT");

    try {
      await contentStore.updateContentAction(route.params.id, formData);

      const response = contentStore.getUpdateContent;

      if (response) {
        EventBus.emit("show-toast", {
          severity: "success",
          summary: "",
          detail: response.message,
        });
        route.params.type == "back" ? router.back() : editContent();
      }

      isLoading.value = false;
    } catch (error) {
      isLoading.value = false;
      if (error.status === 422) {
        const err = error.data.data;
        errors.record(err);
      }
    }
  };

  // const takeCamera = async () => {
  //   showPictureDialog.value = false;
  //   try {
  //     const cameraOptions = {
  //       quality: 90,
  //       allowEditing: true,
  //       resultType: CameraResultType.Uri,
  //       source: CameraSource.Camera,
  //       saveToGallery: false,
  //       width: 300,
  //       height: 300,
  //     };

  //     const image = await Camera.getPhoto(cameraOptions);

  //     // image.webPath will contain a path that can be set as an image src.
  //     // You can access the original file using image.path, which can be
  //     // passed to the Filesystem API to read the raw data of the image,
  //     // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
  //     photo.value = image.webPath;
  //     showCropDialog.value = true;
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // const takePicture = async () => {
  //   showPictureDialog.value = false;
  //   const cameraOptions = {
  //     quality: 90,
  //     allowEditing: true,
  //     resultType: CameraResultType.Uri,
  //     source: CameraSource.Photos,
  //     saveToGallery: false,
  //     width: 300,
  //     height: 300,
  //   };

  //   const image = await Camera.getPhoto(cameraOptions);

  //   try {
  //     // image.webPath will contain a path that can be set as an image src.
  //     // You can access the original file using image.path, which can be
  //     // passed to the Filesystem API to read the raw data of the image,
  //     // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
  //     photo.value = image.webPath;
  //     showCropDialog.value = true;
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // const cropImage = async ({ canvas }) => {
  //   // const result = this.$refs.cropper.getResult()
  //   // const newTab = window.open()
  //   // // for testing open the result in a new tab
  //   // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL(
  //   //   "image/jpeg"
  //   // )}"></img>`

  //   crop_photo.value = canvas.toDataURL("image/jpeg");

  //   canvas.toBlob(function (blob) {
  //     fileUrl.value = blob;
  //   });
  // };

  const selectedTags = (event) => {
    setTimeout(() => {
      if (!event.query.trim().length) {
        filteredTags.value = [...tags.value];
      } else {
        filteredTags.value = tags.value.filter((tag) => {
          return tag.name.toLowerCase().startsWith(event.query.toLowerCase());
        });

        if (filteredTags.value.length === 0) {
          filteredTags.value.push({ name: event.query, new: true });
        }
      }
    }, 250);
  };

  const showConfirmDialog = (id) => {
    infoDialog.value = "danger";
    confirm.require({
      message: "Are you sure?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Yes, delete it",
      rejectLabel: "Cancel",
      acceptClass: "p-button-danger",
      rejectClass: "p-button-danger p-button-text",
      accept: () => {
        deleteContent(id);
      },
      reject: () => {
        //callback to execute when user rejects the action
      },
      onHide: () => {
        //Callback to execute when dialog is hidden
      },
    });
  };

  const deleteContent = async (id) => {
    isLoading.value = true;
    await contentStore.deleteContentAction({ id: id });

    const response = contentStore.getDeleteContent;

    if (response) {
      router.push({ name: "contentList" });
      EventBus.emit("show-toast", {
        severity: "success",
        summary: "",
        detail: response.message,
      });
    }

    isLoading.value = false;
  };

  const formatSize = async (bytes) => {
    if (bytes === 0) {
      return "0 B";
    }

    let k = 1000,
      dm = 3,
      sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const onSelectedFiles = async (event) => {
    attachments.value = event.files;
    newsletter.value = categoryTitle.value == "NEWSLETTER" ? event.files : [];
  };

  const remove = (index) => {
    attachment_url.value.splice(index, 1);
  };

  const bindPhoto = (key, banner_url = null, cover_url = null) => {
    const categoryName = ref(null);
    optionsCategory.value.map((type) => {
      if (type.id == key) {
        categoryName.value = type.title;
      }
    });

    if (categoryName.value == "PROMOTION" && !state.isDeepLink) {
      crop_banner_photo.value = banner_url;
      crop_banner_cover_photo.value = cover_url;
    }

    if (categoryName.value == "PROMOTION" && state.isDeepLink) {
      crop_banner_deeplink_photo.value = banner_url;
    }

    if (categoryName.value == "ANNOUNCEMENT" && !state.isDeepLink) {
      crop_announcement_cover_photo.value = cover_url;
    }

    if (categoryName.value == "NEWSLETTER") {
      crop_newsletter_photo.value = banner_url;
    }
  };

  // Banner photo
  const takeBannerPhotoCamera = async () => {
    showBannerPhotoPictureDialog.value = false;
    try {
      const cameraOptions = {
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        saveToGallery: false,
        width: 300,
        height: 300,
      };

      const image = await Camera.getPhoto(cameraOptions);

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      banner_photo.value = image.webPath;
      showBannerPhotoCropDialog.value = true;
    } catch (err) {
      noData.value = err;
    }
  };

  const takeBannerPhotoPicture = async () => {
    showBannerPhotoPictureDialog.value = false;
    const cameraOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      saveToGallery: false,
      width: 300,
      height: 300,
    };

    const image = await Camera.getPhoto(cameraOptions);

    try {
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      banner_photo.value = image.webPath;
      showBannerPhotoCropDialog.value = true;
    } catch (err) {
      noData.value = err;
    }
  };

  const cropBannerPhotoImage = async ({ canvas }) => {
    // const result = this.$refs.cropper.getResult()
    // const newTab = window.open()
    // // for testing open the result in a new tab
    // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL(
    //   "image/jpeg"
    // )}"></img>`

    crop_banner_photo.value = canvas.toDataURL("image/jpeg");

    canvas.toBlob(function (blob) {
      bannerFileUrl.value = blob;
    });
  };

  // Banner Photo

  // Banner Cover photo
  const takeBannerCoverPhotoCamera = async () => {
    showBannerCoverPhotoPictureDialog.value = false;
    try {
      const cameraOptions = {
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        saveToGallery: false,
        width: 300,
        height: 300,
      };

      const image = await Camera.getPhoto(cameraOptions);

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      banner_cover_photo.value = image.webPath;
      showBannerCoverPhotoCropDialog.value = true;
    } catch (err) {
      noData.value = err;
    }
  }; // state.is_notifiable = response.data.is_notifiable;
  // state.has_notified = response.data.has_notified;

  const takeBannerCoverPhotoPicture = async () => {
    showBannerCoverPhotoPictureDialog.value = false;
    const cameraOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      saveToGallery: false,
      width: 300,
      height: 300,
    };

    const image = await Camera.getPhoto(cameraOptions);

    try {
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      banner_cover_photo.value = image.webPath;
      showBannerCoverPhotoCropDialog.value = true;
    } catch (err) {
      noData.value = err;
    }
  };

  const cropBannerCoverPhotoImage = async ({ canvas }) => {
    // const result = this.$refs.cropper.getResult()
    // const newTab = window.open()
    // // for testing open the result in a new tab
    // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL(
    //   "image/jpeg"
    // )}"></img>`

    crop_banner_cover_photo.value = canvas.toDataURL("image/jpeg");

    canvas.toBlob(function (blob) {
      bannerCoverFileUrl.value = blob;
    });
  };

  // Banner Cover Photo

  // Banner Deeplink photo
  const takeBannerDeeplinkPhotoCamera = async () => {
    showBannerDeeplinkPhotoPictureDialog.value = false;
    try {
      const cameraOptions = {
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        saveToGallery: false,
        width: 300,
        height: 300,
      };

      const image = await Camera.getPhoto(cameraOptions);

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      banner_deeplink_photo.value = image.webPath;
      showBannerDeeplinkPhotoCropDialog.value = true;
    } catch (err) {
      noData.value = err;
    }
  };

  const takeBannerDeeplinkPhotoPicture = async () => {
    showBannerDeeplinkPhotoPictureDialog.value = false;
    const cameraOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      saveToGallery: false,
      width: 300,
      height: 300,
    };

    const image = await Camera.getPhoto(cameraOptions);

    try {
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      banner_deeplink_photo.value = image.webPath;
      showBannerDeeplinkPhotoCropDialog.value = true;
    } catch (err) {
      noData.value = err;
    }
  };

  const cropBannerDeeplinkPhotoImage = async ({ canvas }) => {
    // const result = this.$refs.cropper.getResult()
    // const newTab = window.open()
    // // for testing open the result in a new tab
    // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL(
    //   "image/jpeg"
    // )}"></img>`

    crop_banner_deeplink_photo.value = canvas.toDataURL("image/jpeg");

    canvas.toBlob(function (blob) {
      bannerDeeplinkFileUrl.value = blob;
    });
  };

  // Banner Deeplink Photo

  // Announcement Cover photo
  const takeAnnouncementCoverPhotoCamera = async () => {
    showAnnouncementCoverPhotoPictureDialog.value = false;
    try {
      const cameraOptions = {
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        saveToGallery: false,
        width: 300,
        height: 300,
      };

      const image = await Camera.getPhoto(cameraOptions);

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      announcement_cover_photo.value = image.webPath;
      showAnnouncementCoverPhotoCropDialog.value = true;
    } catch (err) {
      noData.value = err;
    }
  };

  const takeAnnouncementCoverPhotoPicture = async () => {
    showAnnouncementCoverPhotoPictureDialog.value = false;
    const cameraOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      saveToGallery: false,
      width: 300,
      height: 300,
    };

    const image = await Camera.getPhoto(cameraOptions);

    try {
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      announcement_cover_photo.value = image.webPath;
      showAnnouncementCoverPhotoCropDialog.value = true;
    } catch (err) {
      noData.value = err;
    }
  };

  const cropAnnouncementCoverPhotoImage = async ({ canvas }) => {
    // const result = this.$refs.cropper.getResult()
    // const newTab = window.open()
    // // for testing open the result in a new tab
    // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL(
    //   "image/jpeg"
    // )}"></img>`

    crop_announcement_cover_photo.value = canvas.toDataURL("image/jpeg");

    canvas.toBlob(function (blob) {
      announcementCoverFileUrl.value = blob;
    });
  };

  // Announcement Photo

  // Newsletter photo
  const takeNewsletterPhotoCamera = async () => {
    showNewsletterPhotoPictureDialog.value = false;
    try {
      const cameraOptions = {
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        saveToGallery: false,
        width: 300,
        height: 300,
      };

      const image = await Camera.getPhoto(cameraOptions);

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      newsletter_photo.value = image.webPath;
      showNewsletterPhotoCropDialog.value = true;
    } catch (err) {
      noData.value = err;
    }
  };

  const takeNewsletterPhotoPicture = async () => {
    showNewsletterPhotoPictureDialog.value = false;
    const cameraOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      saveToGallery: false,
      width: 300,
      height: 300,
    };

    const image = await Camera.getPhoto(cameraOptions);

    try {
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      newsletter_photo.value = image.webPath;
      showNewsletterPhotoCropDialog.value = true;
    } catch (err) {
      noData.value = err;
    }
  };

  const cropNewsletterPhotoImage = async ({ canvas }) => {
    // const result = this.$refs.cropper.getResult()
    // const newTab = window.open()
    // // for testing open the result in a new tab
    // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL(
    //   "image/jpeg"
    // )}"></img>`

    crop_newsletter_photo.value = canvas.toDataURL("image/jpeg");

    canvas.toBlob(function (blob) {
      newsletterFileUrl.value = blob;
    });
  };

  // Newsletter Photo

  const pictureDialog = (type) => {
    if (type == "bannerPhoto") {
      showBannerPhotoPictureDialog.value = true;
      showBannerCoverPhotoPictureDialog.value = false;
      showBannerDeeplinkPhotoPictureDialog.value = false;
      showAnnouncementCoverPhotoPictureDialog.value = false;
      showNewsletterPhotoPictureDialog.value = false;
    } else if (type == "bannerCoverPhoto") {
      showBannerPhotoPictureDialog.value = false;
      showBannerCoverPhotoPictureDialog.value = true;
      showBannerDeeplinkPhotoPictureDialog.value = false;
      showAnnouncementCoverPhotoPictureDialog.value = false;
      showNewsletterPhotoPictureDialog.value = false;
    } else if (type == "bannerDeeplinkPhoto") {
      showBannerPhotoPictureDialog.value = false;
      showBannerCoverPhotoPictureDialog.value = false;
      showBannerDeeplinkPhotoPictureDialog.value = true;
      showAnnouncementCoverPhotoPictureDialog.value = false;
      showNewsletterPhotoPictureDialog.value = false;
    } else if (type == "announcementCoverPhoto") {
      showBannerPhotoPictureDialog.value = false;
      showBannerCoverPhotoPictureDialog.value = false;
      showBannerDeeplinkPhotoPictureDialog.value = false;
      showAnnouncementCoverPhotoPictureDialog.value = true;
      showNewsletterPhotoPictureDialog.value = false;
    } else if (type == "newsletterPhoto") {
      showBannerPhotoPictureDialog.value = false;
      showBannerCoverPhotoPictureDialog.value = false;
      showBannerDeeplinkPhotoPictureDialog.value = false;
      showAnnouncementCoverPhotoPictureDialog.value = false;
      showNewsletterPhotoPictureDialog.value = true;
    }
  };

  const convertURIToImageData = async (url) => {
    if (null != url) {
      try {
        const response = await fetch(url, {
          method: "GET",
          cache: "no-cache",
        });
        const data = await response.blob();
        const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
        const metadata = { type: `image/jpeg` };
        return new File([data], filename, metadata);
      } catch (e) {
        console.log(e.message);
      }
    }
    return "";
  };

  const changeCategoryHandler = () => {
    state.order_number = getOrderNumber.value;
  };

  watch(
    () => route.params.id,
    () => {
      if (route.params.id) {
        editContent();
      }
    }
  );

  watch(
    () => state.categoryId,
    () => {
      optionsCategory.value.map((type) => {
        if (type.id == state.categoryId) {
          categoryTitle.value = type.title;
        }
      });
    }
  );

  watch(
    () => state.isDeepLink,
    () => {
      errors.clear();
    }
  );

  const changeTags = (event) => {
    state.tags = event.value;
  };

  const changeOrder = (event) => {
    // for (let i = 1; i < 6; i++) {
    //   if (event == i) {
    //     if (sortOrderArr.value.includes(i)) {
    //       button[`btnColor_${i}`].value = "p-button-secondary";

    //       sortOrderResponse.value.map((type) => {
    //         if (type.order_number == i) {
    //           goLinkId.value = type.id;
    //         }
    //       });

    //       if (categoryTitle.value == "PROMOTION") {
    //         showInfoDialog(goLinkId.value);
    //       }

    //       return;
    //     }

    //     if (i == 1) {
    //       button[`btnColor_2`].value = "p-button-outlined";
    //       button[`btnColor_3`].value = "p-button-outlined";
    //       button[`btnColor_4`].value = "p-button-outlined";
    //       button[`btnColor_5`].value = "p-button-outlined";
    //       for (let j = 0; j < sortOrderArr.value.length; j++) {
    //         if (sortOrderArr.value[j] == 2) {
    //           button[`btnColor_2`].value = "p-button-secondary";
    //         }

    //         if (sortOrderArr.value[j] == 3) {
    //           button[`btnColor_3`].value = "p-button-secondary";
    //         }

    //         if (sortOrderArr.value[j] == 4) {
    //           button[`btnColor_4`].value = "p-button-secondary";
    //         }

    //         if (sortOrderArr.value[j] == 5) {
    //           button[`btnColor_5`].value = "p-button-secondary";
    //         }
    //       }
    //       button[`btnColor_1`].value = "p-button-success";
    //       state.order_number = 1;
    //     }
    //   }
    // }

    if (event == 1) {
      if (sortOrderArr.value.includes(1)) {
        button[`btnColor_1`].value = "p-button-secondary";

        sortOrderResponse.value.map((type) => {
          if (type.order_number == 1) {
            goLinkId.value = type.id;
          }
        });

        if (categoryTitle.value == "PROMOTION") {
          showInfoDialog(goLinkId.value);
        }

        return;
      }
      button[`btnColor_2`].value = "p-button-outlined";
      button[`btnColor_3`].value = "p-button-outlined";
      button[`btnColor_4`].value = "p-button-outlined";
      button[`btnColor_5`].value = "p-button-outlined";
      for (let i = 0; i < sortOrderArr.value.length; i++) {
        if (sortOrderArr.value[i] == 2) {
          button[`btnColor_2`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 3) {
          button[`btnColor_3`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 4) {
          button[`btnColor_4`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 5) {
          button[`btnColor_5`].value = "p-button-secondary";
        }
      }
      button[`btnColor_1`].value = "p-button-success";
      state.order_number = 1;
    } else if (event == 2) {
      if (sortOrderArr.value.includes(2)) {
        button[`btnColor_2`].value = "p-button-secondary";
        sortOrderResponse.value.map((type) => {
          if (type.order_number == 2) {
            goLinkId.value = type.id;
          }
        });
        if (categoryTitle.value == "PROMOTION") {
          showInfoDialog(goLinkId.value);
        }
        return;
      }
      button[`btnColor_1`].value = "p-button-outlined";
      button[`btnColor_3`].value = "p-button-outlined";
      button[`btnColor_4`].value = "p-button-outlined";
      button[`btnColor_5`].value = "p-button-outlined";
      for (let i = 0; i < sortOrderArr.value.length; i++) {
        if (sortOrderArr.value[i] == 1) {
          button[`btnColor_1`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 3) {
          button[`btnColor_3`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 4) {
          button[`btnColor_4`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 5) {
          button[`btnColor_5`].value = "p-button-secondary";
        }
      }
      button[`btnColor_2`].value = "p-button-success";
      state.order_number = 2;
    } else if (event == 3) {
      if (sortOrderArr.value.includes(3)) {
        button[`btnColor_3`].value = "p-button-secondary";

        sortOrderResponse.value.map((type) => {
          if (type.order_number == 3) {
            goLinkId.value = type.id;
          }
        });
        if (categoryTitle.value == "PROMOTION") {
          showInfoDialog(goLinkId.value);
        }
        return;
      }
      button[`btnColor_1`].value = "p-button-outlined";
      button[`btnColor_2`].value = "p-button-outlined";
      button[`btnColor_4`].value = "p-button-outlined";
      button[`btnColor_5`].value = "p-button-outlined";
      for (let i = 0; i < sortOrderArr.value.length; i++) {
        if (sortOrderArr.value[i] == 1) {
          button[`btnColor_1`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 2) {
          button[`btnColor_2`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 4) {
          button[`btnColor_4`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 5) {
          button[`btnColor_5`].value = "p-button-secondary";
        }
      }
      button[`btnColor_3`].value = "p-button-success";
      state.order_number = 3;
    } else if (event == 4) {
      if (sortOrderArr.value.includes(4)) {
        button[`btnColor_4`].value = "p-button-secondary";

        sortOrderResponse.value.map((type) => {
          if (type.order_number == 4) {
            goLinkId.value = type.id;
          }
        });
        if (categoryTitle.value == "PROMOTION") {
          showInfoDialog(goLinkId.value);
        }
        return;
      }
      button[`btnColor_1`].value = "p-button-outlined";
      button[`btnColor_2`].value = "p-button-outlined";
      button[`btnColor_3`].value = "p-button-outlined";
      button[`btnColor_5`].value = "p-button-outlined";
      for (let i = 0; i < sortOrderArr.value.length; i++) {
        if (sortOrderArr.value[i] == 1) {
          button[`btnColor_1`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 2) {
          button[`btnColor_2`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 3) {
          button[`btnColor_3`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 5) {
          button[`btnColor_5`].value = "p-button-secondary";
        }
      }
      button[`btnColor_4`].value = "p-button-success";
      state.order_number = 4;
    } else if (event == 5) {
      if (sortOrderArr.value.includes(5)) {
        button[`btnColor_5`].value = "p-button-secondary";

        sortOrderResponse.value.map((type) => {
          if (type.order_number == 5) {
            goLinkId.value = type.id;
          }
        });
        if (categoryTitle.value == "PROMOTION") {
          showInfoDialog(goLinkId.value);
        }
        return;
      }
      button[`btnColor_1`].value = "p-button-outlined";
      button[`btnColor_2`].value = "p-button-outlined";
      button[`btnColor_3`].value = "p-button-outlined";
      button[`btnColor_4`].value = "p-button-outlined";
      for (let i = 0; i < sortOrderArr.value.length; i++) {
        if (sortOrderArr.value[i] == 1) {
          button[`btnColor_1`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 2) {
          button[`btnColor_2`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 3) {
          button[`btnColor_3`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 4) {
          button[`btnColor_4`].value = "p-button-secondary";
        }
      }
      button[`btnColor_5`].value = "p-button-success";
      state.order_number = 5;
    } else if (event == "remove") {
      button[`btnColor_1`].value = "p-button-outlined";
      button[`btnColor_2`].value = "p-button-outlined";
      button[`btnColor_3`].value = "p-button-outlined";
      button[`btnColor_4`].value = "p-button-outlined";
      button[`btnColor_5`].value = "p-button-outlined";
      for (let i = 0; i < sortOrderArr.value.length; i++) {
        if (sortOrderArr.value[i] == 1) {
          button[`btnColor_1`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 2) {
          button[`btnColor_2`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 3) {
          button[`btnColor_3`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 4) {
          button[`btnColor_4`].value = "p-button-secondary";
        }

        if (sortOrderArr.value[i] == 5) {
          button[`btnColor_5`].value = "p-button-secondary";
        }
      }
      state.order_number = 10;
    }
  };

  const showInfoDialog = (id) => {
    infoDialog.value = "info";
    confirm.require({
      message:
        "The slot is occupied by content '" +
        id +
        "'. Please remove the check from show on home screen if you want to override the slot.",
      icon: "pi pi-info",
      acceptLabel: "Go to link",
      rejectLabel: "Cancel",
      acceptClass: "p-button-primary",
      rejectClass: "p-button-outlined",
      accept: () => {
        router.push({ name: "editContent", params: { id: id, type: "back" } });
      },
      reject: () => {
        //callback to execute when user rejects the action
      },
      onHide: () => {
        //Callback to execute when dialog is hidden
      },
    });
  };

  const getFileExtension = (url) => {
    const parts = url.split(".");
    if (parts.length > 1) {
      return parts[parts.length - 1];
    }
    return null;
  };

  const removeUploadedFile = async (event) => {
    attachments.value = event.files;
  };

  const maxNameLength = computed(() => {
    return categoryTitle.value === "NEWSLETTER" ? 50 : 70;
  });

  return {
    isLoading,
    state,
    submitted,
    errors,
    handleSubmit,
    optionsStatus,
    optionsCategory,
    showConfirmDialog,
    changeOrder,
    crop_photo,
    // cropImage,
    showCropDialog,
    // takePicture,
    photo,
    onSelectedFiles,
    // takeCamera,
    pictureDialog,
    showPictureDialog,
    remove,
    attachment_url,
    formatSize,
    categoryTitle,
    banner_photo,
    banner_cover_photo,
    banner_deeplink_photo,
    announcement_cover_photo,
    newsletter_photo,
    crop_banner_photo,
    crop_banner_cover_photo,
    crop_banner_deeplink_photo,
    crop_announcement_cover_photo,
    crop_newsletter_photo,
    cropBannerPhotoImage,
    cropBannerCoverPhotoImage,
    cropBannerDeeplinkPhotoImage,
    cropAnnouncementCoverPhotoImage,
    cropNewsletterPhotoImage,
    showBannerPhotoCropDialog,
    showBannerCoverPhotoCropDialog,
    showBannerDeeplinkPhotoCropDialog,
    showAnnouncementCoverPhotoCropDialog,
    showNewsletterPhotoCropDialog,
    showBannerPhotoPictureDialog,
    showBannerCoverPhotoPictureDialog,
    showBannerDeeplinkPhotoPictureDialog,
    showAnnouncementCoverPhotoPictureDialog,
    showNewsletterPhotoPictureDialog,
    filteredTags,
    selectedTags,
    changeTags,
    takeBannerPhotoPicture,
    takeBannerCoverPhotoPicture,
    takeBannerDeeplinkPhotoPicture,
    takeAnnouncementCoverPhotoPicture,
    takeNewsletterPhotoPicture,
    takeBannerPhotoCamera,
    takeBannerCoverPhotoCamera,
    takeBannerDeeplinkPhotoCamera,
    takeAnnouncementCoverPhotoCamera,
    takeNewsletterPhotoCamera,
    changeCategoryHandler,
    sortOrderArr,
    infoDialog,
    button,
    fileUpload,
    getFileExtension,
    removeUploadedFile,
    maxNameLength,
  };
};

// function convertUTCDateToLocalDate(utcDate) {
//   var date = new Date(utcDate);
//   // var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
//   return date;
// }
