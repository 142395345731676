import { endpoint } from "@/constants/endpoint";
import api from "@/libs/axios";

export const useUserProfileService = {
  fetchEditUser: async (id, params) => {
    const result = await api.post(`${endpoint.users}/${id}`, params);
    return result.data;
  },

  fetchShowUser: async (id) => {
    const result = await api.get(`${endpoint.users}/${id}`);
    return result.data;
  },

  fetchShowUserProfile: async (token) => {
    const result = await api.get(`${endpoint.users}/profile`, token);
    return result.data;
  },
};
