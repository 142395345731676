import "@/plugins/global-css";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import TabMenu from "primevue/tabmenu";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { Cropper } from "vue-advanced-cropper";
import Card from "primevue/card";
import Badge from "primevue/badge";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import InputMask from "primevue/inputmask";
import MultiSelect from "primevue/multiselect";
import TextArea from "primevue/textarea";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Loading from "@/components/Loading.vue";
import ConfirmDialog from "primevue/confirmdialog";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import Divider from "primevue/divider";
import Tree from "primevue/tree";
import AutoComplete from 'primevue/autocomplete';
import Menu from 'primevue/menu';
import OverlayPanel from 'primevue/overlaypanel';
import InputSwitch from 'primevue/inputswitch';
import Editor from "primevue/editor";
import Image from 'primevue/image';

import { app } from "@/plugins/main-app";

import "@/plugins/abality";
import "@/plugins/confirmation-service";
import "@/plugins/global-components";
import "@/plugins/i18n";
import "@/plugins/moment";
import "@/plugins/pinia";
import "@/plugins/primevue";
import "@/plugins/router";
import "@/plugins/toast-service";
import "@/plugins/theme";
import "./assets/styles/app.css";

app.component("Card", Card);
app.component("Badge", Badge);
app.component("InputText", InputText);
app.component("Calendar", Calendar);
app.component("Dropdown", Dropdown);
app.component("InputMask", InputMask);
app.component("TextArea", TextArea);
app.component("Button", Button);
app.component("Dialog", Dialog);
app.component("Cropper", Cropper);
app.component("Loading", Loading);
app.component("ConfirmDialog", ConfirmDialog);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("MultiSelect", MultiSelect);
app.component("TabMenu", TabMenu);
app.component("Checkbox", Checkbox);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("Divider", Divider);
app.component('Tree', Tree);
app.component('AutoComplete', AutoComplete);
app.component('Menu', Menu);
app.component('OverlayPanel', OverlayPanel);
app.component('InputSwitch', InputSwitch);
app.component('Editor', Editor);
app.component('Image', Image);

app.mount("#app");

defineCustomElements(window);
