const moduleRoutes = [
  {
    path: "/resident/reject-remark",
    name: "reject",
    children: [
      {
        path: "",
        name: "residentRejectRemarkList",
        component: () =>
          import(
            "@/modules/resident/rejectRemark/list/RejectReamrkResidents.vue"
          ),
        meta: {
          action: "view",
          resource: "resident",
          layout: "default",
          title: "Reject Remark",
          breadcrumbs: [
            {
              title: " Reject Remark List",
              routeName: "residentRejectRemarkList",
            },
          ],
        },
      },
    ],
  },
  {
    path: "/resident/reject-remark/create",
    name: "createRejectRemarkResident",
    component: () =>
      import(
        "@/modules/resident/rejectRemark/entry/NewRejectRemarkResident.vue"
      ),
    meta: {
      // action: "create",
      resource: "resident",
      layout: "default",
      title: "Create Reject Remark",
      breadcrumbs: [
        { title: " Reject Remark List", routeName: "residentRejectRemarkList" },
        { title: "Create ", routeName: "createRejectRemarkResident" },
      ],
    },
  },
  {
    path: "/resident/reject-remark/:id/edit",
    name: "editRejectRemarkResident",
    component: () =>
      import(
        "@/modules/resident/rejectRemark/entry/EditRejectRemarkResident.vue"
      ),
    meta: {
      //   action: "index",
      //   resource:
      layout: "default",
      title: "Edit Reject Remark",
      breadcrumbs: [
        { title: "Reject Remark List", routeName: "residentRejectRemarkList" },
        { title: "Edit", routeName: "editRejectRemarkResident" },
      ],
    },
  },
];

export default moduleRoutes;
