<template>
  <div class="card">
    <h5 class="m-0 md:mb-2 block lg:hidden">Create Content</h5>
    <form class="p-fluid" @submit.prevent="handleSubmit()">
      <div class="grid">
        <!-- Photo -->
        <!-- <label class="col-12 m-0 text-center font-bold">Photo</label>
        <div class="col-12 mb-4">
          <div class="custom-upload text-center" @click="pictureDialog">
            <img
              v-if="crop_photo"
              :src="crop_photo"
              class="member-profile"
              alt="profile"
            />
            <i v-else class="pi pi-plus" style="font-size: 2rem"></i>
          </div>
          <small v-if="errors.has('image')" class="p-error">
            <div
              v-for="error in errors.get('image')"
              :key="error"
              class="text-center"
            >
              {{ error }}
            </div>
          </small>
        </div> -->
        <!-- Photo -->

        <div class="field col-12 md:col-6 lg:col-6">
          <label for="name" class="font-bold">Title*</label>
          <InputText
            id="name"
            v-model="state.titleEn"
            placeholder="Content title"
            class="w-full"
            :maxlength="maxNameLength"
          />

          <p v-if="state.titleEn.length >= maxNameLength" style="color: red">
            Title must be {{ maxNameLength }} characters or less.
          </p>

          <small v-if="errors.has('title.en')" class="p-error">
            <div v-for="error in errors.get('title.en')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <!-- <div class="field col-12 md:col-6 lg:col-6">
          <label for="name" class="font-bold">Title (Myanmar)*</label>
          <InputText
            id="name"
            v-model="state.titleMm"
            placeholder="Content title (Myanmar)"
            class="w-full"
          />

          <small v-if="errors.has('title.mm')" class="p-error">
            <div v-for="error in errors.get('title.mm')" :key="error">
              {{ error }}
            </div>
          </small>
        </div> -->

        <div class="field col-12 md:col-6 lg:col-6">
          <label for="category" class="font-bold">Category*</label>

          <Dropdown
            id="category"
            v-model="state.categoryId"
            :options="optionsCategory"
            optionLabel="title"
            placeholder="Select unit"
            class="w-full"
          />

          <small v-if="errors.has('category_id')" class="p-error">
            <div v-for="error in errors.get('category_id')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <!-- <div class="field col-12 md:col-6 lg:col-6">
          <label for="status" class="font-bold">Status*</label>

          <Dropdown
            id="status"
            v-model="state.status"
            :options="optionsStatus"
            optionLabel="name"
            optionValue="code"
            placeholder="Select unit"
            class="w-full"
          />
          <small v-if="errors.has('status')" class="p-error">
            <div v-for="error in errors.get('status')" :key="error">
              {{ error }}
            </div>
          </small>
        </div> -->
        <!-- <div
          class="
            field
            col-6
            md:col-3
            lg:col-3
            flex flex-row
            align-items-center
            h-7rem
          "
        >
          <Checkbox
            class="mr-3"
            inputId="is_notifiable"
            :binary="true"
            v-model="state.is_notifiable"
          />
          <label class="font-bold mt-2" for="is_notifiable">
            Is Notifiable
          </label>
          <br />
        </div> -->

        <div class="field col-12 md:col-3 lg:col-3">
          <div class="grid mt-5 ml-1">
            <div class="flex field-checkbox pr-4 mt-2">
              <Checkbox
                class="mr-1"
                inputId="isNotifiable"
                :binary="true"
                v-model="state.isNotifiable"
              />
              <label class="font-bold ml-2" for="isNotifiable">
                Notify Users
              </label>
            </div>
          </div>
          <small
            ><div class="text-yellow-500 p-ripple">
              <i class="pi pi-exclamation-triangle mr-1"></i>This can't be
              updated once you have created a content
            </div></small
          >
        </div>

        <div class="field col-12 md:col-3 lg:col-3">
          <div class="grid mt-5 ml-1">
            <div class="flex field-checkbox pr-4 mt-2">
              <Checkbox
                class="mr-1"
                inputId="isPublishSchedule"
                :binary="true"
                v-model="state.isPublishSchedule"
              />
              <label class="font-bold ml-2" for="isPublishSchedule">
                Publish Schedule?
              </label>
            </div>
          </div>
        </div>

        <div
          class="field col-12 md:col-6 lg:col-6"
          v-if="
            state.categoryId.title == 'PROMOTION' ||
            state.categoryId.title == 'ANNOUNCEMENT'
          "
        >
          <div class="grid mt-5 ml-1">
            <div class="flex field-checkbox pr-4 mt-2">
              <Checkbox
                class="mr-0"
                inputId="isDeepLink"
                :binary="true"
                v-model="state.isDeepLink"
              />
              <label class="font-bold ml-2" for="isDeepLink">
                Is Deep Link?
              </label>
            </div>
          </div>
        </div>

        <div class="field col-12 md:col-6 lg:col-6">
          <div v-if="state.isPublishSchedule">
            <label class="font-bold"> Published At* </label>
            <Calendar
              class="w-full"
              dateFormat="yy-mm-dd"
              v-model="state.published_at"
              placeholder="Choose published Date"
              :showTime="true"
              :showSeconds="true"
            />

            <small v-if="errors.has('published_at')" class="p-error">
              <div v-for="error in errors.get('published_at')" :key="error">
                {{ error }}
              </div>
            </small>
          </div>
        </div>

        <div class="field col-12 md:col-6 lg:col-6">
          <div
            v-if="
              (state.categoryId.title == 'PROMOTION' ||
                state.categoryId.title == 'ANNOUNCEMENT') &&
              state.isDeepLink
            "
          >
            <label for="deepLink" class="font-bold">Deep Link*</label>
            <InputText
              id="deepLink"
              v-model="state.deepLink"
              placeholder="Deep Link"
              class="w-full"
            />

            <small v-if="errors.has('deep_link')" class="p-error">
              <div v-for="error in errors.get('deep_link')" :key="error">
                {{ error }}
              </div>
            </small>
          </div>
        </div>

        <div
          v-if="state.categoryId.title == 'PROMOTION'"
          class="field col-12 md:col-12 lg:col-12"
        >
          <label class="font-bold"> Tags </label>
          <AutoComplete
            multiple
            v-model="state.tags"
            :suggestions="filteredTags"
            optionLabel="name"
            placeholder="enter tags name"
            @complete="selectedTags"
            @change="changeTags"
          >
            <template #option="slotprops">
              <span> {{ slotprops.option.name }} </span>
              <small class="ml-3 create-new-tag" v-if="slotprops.option.new">
                create new tags
              </small>
            </template>
          </AutoComplete>

          <small v-if="errors.has('tags')" class="p-error">
            <div v-for="error in errors.get('tags')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <div
          class="field col-12 md:col-6 lg:col-6"
          v-if="state.categoryId.title == 'PROMOTION' && !state.isDeepLink"
        >
          <!-- Banner Photo -->
          <label class="col-12 m-0 text-center font-bold">
            Promotion Photo(Home Screen)
          </label>
          <div class="col-12 mb-4">
            <div
              class="custom-upload text-center"
              @click="pictureDialog('bannerPhoto')"
            >
              <img
                v-if="crop_banner_photo"
                :src="crop_banner_photo"
                class="member-profile"
                alt="profile"
              />
              <i v-else class="pi pi-plus" style="font-size: 2rem"></i>
            </div>
            <small v-if="errors.has('banner_photo')" class="p-error">
              <div
                v-for="error in errors.get('banner_photo')"
                :key="error"
                class="text-center"
              >
                {{ error }}
              </div>
            </small>
          </div>
          <!-- Banner Photo -->
        </div>

        <div
          class="field col-12 md:col-6 lg:col-6"
          v-if="state.categoryId.title == 'PROMOTION' && !state.isDeepLink"
        >
          <!-- Banner Cover Photo -->
          <label class="col-12 m-0 text-center font-bold"
            >Cover Photo(Detail Screen)*</label
          >
          <div class="col-12 mb-4">
            <div
              class="custom-upload text-center"
              @click="pictureDialog('bannerCoverPhoto')"
            >
              <img
                v-if="crop_banner_cover_photo"
                :src="crop_banner_cover_photo"
                class="member-profile"
                alt="profile"
              />
              <i v-else class="pi pi-plus" style="font-size: 2rem"></i>
            </div>
            <small v-if="errors.has('cover_photo')" class="p-error">
              <div
                v-for="error in errors.get('cover_photo')"
                :key="error"
                class="text-center"
              >
                {{ error }}
              </div>
            </small>
          </div>
          <!-- Banner Cover Photo -->
        </div>

        <div
          class="field col-12 md:col-12 lg:col-12"
          v-if="state.categoryId.title == 'PROMOTION' && state.isDeepLink"
        >
          <!-- Banner Deeplink Photo -->
          <label class="col-12 m-0 text-center font-bold"
            >Promotion Photo(Home Screen)*</label
          >
          <div class="col-12 mb-4">
            <div
              class="custom-upload text-center"
              @click="pictureDialog('bannerDeeplinkPhoto')"
            >
              <img
                v-if="crop_banner_deeplink_photo"
                :src="crop_banner_deeplink_photo"
                class="member-profile"
                alt="profile"
              />
              <i v-else class="pi pi-plus" style="font-size: 2rem"></i>
            </div>
            <small v-if="errors.has('banner_photo')" class="p-error">
              <div
                v-for="error in errors.get('banner_photo')"
                :key="error"
                class="text-center"
              >
                {{ error }}
              </div>
            </small>
          </div>
          <!-- Banner Deeplink Photo -->
        </div>

        <div
          class="field col-12 md:col-12 lg:col-12"
          v-if="state.categoryId.title == 'ANNOUNCEMENT' && !state.isDeepLink"
        >
          <!-- Announcement Cover Photo -->
          <label class="col-12 m-0 text-center font-bold"
            >Cover Photo(Home Screen)*</label
          >
          <div class="col-12 mb-4">
            <div
              class="custom-upload text-center"
              @click="pictureDialog('announcementCoverPhoto')"
            >
              <img
                v-if="crop_announcement_cover_photo"
                :src="crop_announcement_cover_photo"
                class="member-profile"
                alt="profile"
              />
              <i v-else class="pi pi-plus" style="font-size: 2rem"></i>
            </div>
            <small v-if="errors.has('cover_photo')" class="p-error">
              <div
                v-for="error in errors.get('cover_photo')"
                :key="error"
                class="text-center"
              >
                {{ error }}
              </div>
            </small>
          </div>
          <!-- Announcement Cover Photo -->
        </div>

        <div
          class="field col-12 md:col-12 lg:col-12"
          v-if="state.categoryId.title == 'NEWSLETTER'"
        >
          <!-- Newsletter Photo -->
          <label class="col-12 m-0 text-center font-bold"
            >Newsletter Photo(Home Screen)*</label
          >
          <div class="col-12 mb-4">
            <div
              class="custom-upload text-center"
              @click="pictureDialog('newsletterPhoto')"
            >
              <img
                v-if="crop_newsletter_photo"
                :src="crop_newsletter_photo"
                class="member-profile"
                alt="profile"
              />
              <i v-else class="pi pi-plus" style="font-size: 2rem"></i>
            </div>
            <small v-if="errors.has('banner_photo')" class="p-error">
              <div
                v-for="error in errors.get('banner_photo')"
                :key="error"
                class="text-center"
              >
                {{ error }}
              </div>
            </small>
          </div>
          <!-- Newsletter Photo -->
        </div>

        <div class="field col-12 md:col-12 lg:col-12">
          <label for="entries" class="font-bold">Content*</label>
          <Editor v-model="state.contentEn" editorStyle="height: 320px">
            <template #toolbar>
              <span class="ql-formats">
                <select class="ql-header" defaultValue="0">
                  <option value="1">Heading</option>
                  <option value="2">Subheading</option>
                  <option value="0">Normal</option>
                </select>
                <select class="ql-font">
                  <option></option>
                  <option value="serif"></option>
                  <option value="monospace"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-bold" type="button"></button>
                <button class="ql-italic" type="button"></button>
                <button class="ql-underline" type="button"></button>
              </span>
              <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </span>
              <span class="ql-formats">
                <button class="ql-list" value="ordered" type="button"></button>
                <button class="ql-list" value="bullet" type="button"></button>
                <select class="ql-align">
                  <option defaultValue></option>
                  <option value="center"></option>
                  <option value="right"></option>
                  <option value="justify"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-link" type="button"></button>
                <button class="ql-image" type="button"></button>
                <button class="ql-code-block" type="button"></button>
              </span>
            </template>
          </Editor>
          <small v-if="errors.has('content.en')" class="p-error">
            <div v-for="error in errors.get('content.en')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <!-- <div class="field col-12 md:col-4 lg:col-4">
          <Checkbox
            class="mr-0"
            inputId="Is Pinned"
            :binary="true"
            v-model="state.is_pinned"
          />
          <label class="font-bold ml-2" for="isPinned"> Is Pinned </label>
        </div> -->

        <!-- <div 
          v-if="state.categoryId.title === 'PROMOTION'" 
          class="field col-12 md:col-4 lg:col-4"
        >
          <label> Order </label>
          <div class="flex flex-row">
            <Button 
              :class="`order-btn ${state.order === 1 ? 'order-btn-active' : ''}`"
              label="1" 
              @click="() => { state.order = 1 }" 
            />
            <Button 
              :class="`order-btn ${state.order === 2 ? 'order-btn-active' : ''}`"
              label="2" 
              @click="() => { state.order = 2 }" 
            />
            <Button 
              :class="`order-btn ${state.order === 3 ? 'order-btn-active' : ''}`"
              label="3" 
              @click="() => { state.order = 3 }" 
            />
            <Button 
              :class="`order-btn ${state.order === 4 ? 'order-btn-active' : ''}`" 
              label="4"
               @click="() => { state.order = 4 }" 
            />
            <Button 
              :class="`order-btn ${state.order === 1 ? 'order-btn-active' : ''}`"
              label="5" 
              @click="() => { state.order = 5}"
            />
          </div>
        </div> -->

        <!-- <div 
          v-if="state.categoryId.title !== 'PROMOTION'" 
          class="field col-12 md:col-4 lg:col-4"
        >
          <Button 
            :class="`order-btn ${state.order === 1 ? 'order-btn-active' : ''}`"
            label="1" 
            @click="() => { state.order = 1 }"
          />
      </div> -->

        <!-- <div class="field col-12 md:col-6 lg:col-6">
          <label for="entries" class="font-bold">Content MM*</label>
          <Editor v-model="state.contentMm" editorStyle="height: 320px">
            <template #toolbar>
              <span class="ql-formats">
                <select class="ql-header" defaultValue="0">
                  <option value="1">Heading</option>
                  <option value="2">Subheading</option>
                  <option value="0">Normal</option>
                </select>
                <select class="ql-font">
                  <option></option>
                  <option value="serif"></option>
                  <option value="monospace"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-bold" type="button"></button>
                <button class="ql-italic" type="button"></button>
                <button class="ql-underline" type="button"></button>
              </span>
              <span class="ql-formats">
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </span>
              <span class="ql-formats">
                <button class="ql-list" value="ordered" type="button"></button>
                <button class="ql-list" value="bullet" type="button"></button>
                <select class="ql-align">
                  <option defaultValue></option>
                  <option value="center"></option>
                  <option value="right"></option>
                  <option value="justify"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-link" type="button"></button>
                <button class="ql-code-block" type="button"></button>
              </span>
            </template>
          </Editor>

          <small v-if="errors.has('content.mm')" class="p-error">
            <div v-for="error in errors.get('content.mm')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

         <div class="field col-12 md:col-6 lg:col-6">
          <label class="font-bold"> Published At </label>
          <Calendar
            class="w-full"
            dateFormat="yy-mm-dd"
            v-model="state.published_at"
            placeholder="Choose published Date"
          />

          <small v-if="errors.has('published_at')" class="p-error">
            <div v-for="error in errors.get('published_at')" :key="error">
              {{ error }}
            </div>
          </small>
        </div> -->

        <!-- <div
          class="
            field
            col-6
            md:col-3
            lg:col-3
            flex flex-row
            align-items-center
            h-7rem
          "
        >
          <Checkbox
            class="mr-3"
            inputId="is_notifiable"
            :binary="true"
            v-model="state.is_notifiable"
          />
          <label class="font-bold mt-2" for="is_notifiable">
            Is Notifiable
          </label>
        </div> -->

        <!-- <div
          class="
            field
            col-6
            md:col-3
            lg:col-3
            flex flex-row
            align-items-center
            h-7rem
          "
        >
          <Checkbox
            class="mr-3"
            inputId="has_notified"
            :binary="true"
            v-model="state.has_notified"
          />
          <label class="font-bold mt-2" for="has_notified">
            Has Notified
          </label>
        </div> -->

        <!-- <div class="field col-12 md:col-6 lg:col-6">
          <label class="font-bold"> Start Date </label>
          <Calendar
            class="w-full"
            dateFormat="dd-mm-yy"
            v-model="state.started_at"
            placeholder="Choose Start Date"
          />

          <small v-if="errors.has('started_at')" class="p-error">
            <div v-for="error in errors.get('started_at')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <div class="field col-12 md:col-6 lg:col-6">
          <label class="font-bold"> End Date </label>
          <Calendar
            class="w-full"
            dateFormat="dd-mm-yy"
            v-model="state.ended_at"
            placeholder="Choose End Date"
          />

          <small v-if="errors.has('ended_at')" class="p-error">
            <div v-for="error in errors.get('ended_at')" :key="error">
              {{ error }}
            </div>
          </small>
        </div> -->

        <div
          class="field col-12 md:col-12 lg:col-12"
          v-if="
            ((state.categoryId.title == 'PROMOTION' ||
              state.categoryId.title == 'ANNOUNCEMENT') &&
              !state.isDeepLink) ||
            state.categoryId.title == 'NEWSLETTER'
          "
        >
          <label class="font-bold"> Attachments </label>

          <FileUpload
            v-if="state.categoryId.title == 'NEWSLETTER'"
            :showUploadButton="false"
            :showCancelButton="false"
            :multiple="true"
            :maxFileSize="20000000"
            @select="onSelectedFiles($event)"
            @remove="removeUploadedFile($event)"
            accept="application/pdf"
            :fileLimit="1"
          >
            <template #empty>
              <div
                class="flex align-items-center justify-content-center flex-column"
              >
                <i
                  class="pi pi-cloud-upload border-2 border-circle p-5 text-5xl text-400 border-400"
                />
                <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
              </div>
            </template>
          </FileUpload>

          <FileUpload
            :showUploadButton="false"
            :showCancelButton="false"
            :multiple="true"
            :maxFileSize="20000000"
            @select="onSelectedFiles($event)"
            @remove="removeUploadedFile($event)"
            v-else
          >
            <template #empty>
              <div
                class="flex align-items-center justify-content-center flex-column"
              >
                <i
                  class="pi pi-cloud-upload border-2 border-circle p-5 text-5xl text-400 border-400"
                />
                <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
              </div>
            </template>
          </FileUpload>

          <small v-if="errors.has('attachments')" class="p-error">
            <div v-for="error in errors.get('attachments')" :key="error">
              {{ error }}
            </div>
          </small>

          <small v-if="errors.has('newsletter')" class="p-error">
            <div v-for="error in errors.get('newsletter')" :key="error">
              {{ error }}
            </div>
          </small>
        </div>

        <!-- <div
          class="field col-12 md:col-12 lg:col-12"
          v-if="state.categoryId.title == 'NEWSLETTER'"
        >
          <label for="attachment">Attachment</label>
          <FileUpload
            mode="basic"
            accept="pdf/*"
            :maxFileSize="1000000"
            :chooseLabel="attachment ? 'Change' : 'Browse'"
            :customUpload="true"
            :auto="true"
            uploadIcon="ri-upload-2-fill"
            @uploader="onUpload($event)"
          />
          <div class="field mt-3">
            <Image
              :src="photoURL"
              alt="Image"
              width="200"
              height="200"
              preview
            />
          </div>
        </div> -->
      </div>

      <div class="col-12 md:col-12 flex justify-content-end">
        <router-link :to="{ name: 'contentList' }">
          <div>
            <Button label="Cancel" class="mt-2 p-button-outlined" />
          </div>
        </router-link>
        <!-- v-if="$can('index', 'cms-contents')" -->
        <div class="mr-2 ml-2">
          <Button
            v-if="$can('create', 'content')"
            label="Save as Draft"
            class="mt-2 p-button-secondary"
            @click="draft()"
          />
        </div>

        <div>
          <Button
            v-if="$can('create', 'content')"
            label="Publish"
            class="mt-2"
            @click="publish()"
          />
        </div>
      </div>
    </form>

    <!-- Banner Photo -->
    <Dialog
      header="Crop Image"
      :closable="false"
      v-model:visible="showBannerPhotoCropDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '300px' }"
    >
      <div style="max-width: 300px">
        <cropper
          class="cropper"
          v-if="banner_photo"
          :src="banner_photo"
          :stencil-props="{
            aspectRatio: 2 / 1,
            movable: true,
            resizable: true,
          }"
          @change="cropBannerPhotoImage"
        ></cropper>
      </div>

      <template #footer>
        <Button label="Got it" @click="showBannerPhotoCropDialog = false" />
      </template>
    </Dialog>

    <Dialog
      header="Photo"
      v-model:visible="showBannerPhotoPictureDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      position="bottom"
      :modal="true"
    >
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeBannerPhotoPicture"
      >
        <i class="ri-image-line mr-3"></i>From Photos
      </div>
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeBannerPhotoCamera"
      >
        <i class="ri-camera-line mr-3"></i>Take Picture
      </div>
    </Dialog>
    <!-- Banner Photo -->

    <!-- Banner Cover Photo -->
    <Dialog
      header="Crop Image"
      :closable="false"
      v-model:visible="showBannerCoverPhotoCropDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '300px' }"
    >
      <div style="max-width: 300px">
        <cropper
          class="cropper"
          v-if="banner_cover_photo"
          :src="banner_cover_photo"
          :stencil-props="{
            aspectRatio: 2 / 1,
            movable: true,
            resizable: true,
          }"
          @change="cropBannerCoverPhotoImage"
        ></cropper>
      </div>

      <template #footer>
        <Button
          label="Got it"
          @click="showBannerCoverPhotoCropDialog = false"
        />
      </template>
    </Dialog>

    <Dialog
      header="Photo"
      v-model:visible="showBannerCoverPhotoPictureDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      position="bottom"
      :modal="true"
    >
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeBannerCoverPhotoPicture"
      >
        <i class="ri-image-line mr-3"></i>From Photos
      </div>
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeBannerCoverPhotoCamera"
      >
        <i class="ri-camera-line mr-3"></i>Take Picture
      </div>
    </Dialog>
    <!-- Banner Cover Photo -->

    <!-- Banner Deeplink Photo -->
    <Dialog
      header="Crop Image"
      :closable="false"
      v-model:visible="showBannerDeeplinkPhotoCropDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '300px' }"
    >
      <div style="max-width: 300px">
        <cropper
          class="cropper"
          v-if="banner_deeplink_photo"
          :src="banner_deeplink_photo"
          :stencil-props="{
            aspectRatio: 2 / 1,
            movable: true,
            resizable: true,
          }"
          @change="cropBannerDeeplinkPhotoImage"
        ></cropper>
      </div>

      <template #footer>
        <Button
          label="Got it"
          @click="showBannerDeeplinkPhotoCropDialog = false"
        />
      </template>
    </Dialog>

    <Dialog
      header="Photo"
      v-model:visible="showBannerDeeplinkPhotoPictureDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      position="bottom"
      :modal="true"
    >
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeBannerDeeplinkPhotoPicture"
      >
        <i class="ri-image-line mr-3"></i>From Photos
      </div>
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeBannerDeeplinkPhotoCamera"
      >
        <i class="ri-camera-line mr-3"></i>Take Picture
      </div>
    </Dialog>
    <!-- Banner Deeplink Photo -->

    <!-- Announcement Cover Photo -->
    <Dialog
      header="Crop Image"
      :closable="false"
      v-model:visible="showAnnouncementCoverPhotoCropDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '300px' }"
    >
      <div style="max-width: 300px">
        <cropper
          class="cropper"
          v-if="announcement_cover_photo"
          :src="announcement_cover_photo"
          :stencil-props="{
            aspectRatio: 2 / 1,
            movable: true,
            resizable: true,
          }"
          @change="cropAnnouncementCoverPhotoImage"
        ></cropper>
      </div>

      <template #footer>
        <Button
          label="Got it"
          @click="showAnnouncementCoverPhotoCropDialog = false"
        />
      </template>
    </Dialog>

    <Dialog
      header="Photo"
      v-model:visible="showAnnouncementCoverPhotoPictureDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      position="bottom"
      :modal="true"
    >
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeAnnouncementCoverPhotoPicture"
      >
        <i class="ri-image-line mr-3"></i>From Photos
      </div>
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeAnnouncementCoverPhotoCamera"
      >
        <i class="ri-camera-line mr-3"></i>Take Picture
      </div>
    </Dialog>
    <!-- Announcement Photo -->

    <!-- Newsletter Photo -->
    <Dialog
      header="Crop Image"
      :closable="false"
      v-model:visible="showNewsletterPhotoCropDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '300px' }"
    >
      <div style="max-width: 300px">
        <cropper
          class="cropper"
          v-if="newsletter_photo"
          :src="newsletter_photo"
          :stencil-props="{
            aspectRatio: 4 / 5,
            movable: true,
            resizable: true,
          }"
          @change="cropNewsletterPhotoImage"
        ></cropper>
      </div>

      <template #footer>
        <Button label="Got it" @click="showNewsletterPhotoCropDialog = false" />
      </template>
    </Dialog>

    <Dialog
      header="Photo"
      v-model:visible="showNewsletterPhotoPictureDialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      position="bottom"
      :modal="true"
    >
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeNewsletterPhotoPicture"
      >
        <i class="ri-image-line mr-3"></i>From Photos
      </div>
      <div
        class="flex align-items-center text-xl p-3 cursor-pointer p-ripple transition-colors transition-duration-150 hover:bg-gray-200"
        @click="takeNewsletterPhotoCamera"
      >
        <i class="ri-camera-line mr-3"></i>Take Picture
      </div>
    </Dialog>
    <!-- Newsletter Photo -->

    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
// import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Loading from "@/components/Loading.vue";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Editor from "primevue/editor";
import FileUpload from "@/components/FileUpload.vue";
// import Image from "@/components/Image";
import { Cropper } from "vue-advanced-cropper";
import { defineComponent } from "vue";
import { useNewContent } from "@/modules/cms/content/entry/useNewContent";

import "vue-advanced-cropper/dist/style.css";

export default defineComponent({
  name: "NewContent",
  components: {
    Loading,
    Button,
    InputText,
    Dialog,
    Cropper,
    Dropdown,
    Editor,
    FileUpload,
    // Image,
  },
  setup() {
    const {
      isLoading,
      state,
      submitted,
      handleSubmit,
      errors,
      optionsStatus,
      optionsCategory,
      onSelectedFiles,
      pictureDialog,
      banner_photo,
      banner_cover_photo,
      banner_deeplink_photo,
      announcement_cover_photo,
      newsletter_photo,
      crop_banner_photo,
      crop_banner_cover_photo,
      crop_banner_deeplink_photo,
      crop_announcement_cover_photo,
      crop_newsletter_photo,
      cropBannerPhotoImage,
      cropBannerCoverPhotoImage,
      cropBannerDeeplinkPhotoImage,
      cropAnnouncementCoverPhotoImage,
      cropNewsletterPhotoImage,
      showBannerPhotoCropDialog,
      showBannerCoverPhotoCropDialog,
      showBannerDeeplinkPhotoCropDialog,
      showAnnouncementCoverPhotoCropDialog,
      showNewsletterPhotoCropDialog,
      showBannerPhotoPictureDialog,
      showBannerCoverPhotoPictureDialog,
      showBannerDeeplinkPhotoPictureDialog,
      showAnnouncementCoverPhotoPictureDialog,
      showNewsletterPhotoPictureDialog,
      filteredTags,
      selectedTags,
      changeTags,
      takeBannerPhotoPicture,
      takeBannerCoverPhotoPicture,
      takeBannerDeeplinkPhotoPicture,
      takeAnnouncementCoverPhotoPicture,
      takeNewsletterPhotoPicture,
      takeBannerPhotoCamera,
      takeBannerCoverPhotoCamera,
      takeBannerDeeplinkPhotoCamera,
      takeAnnouncementCoverPhotoCamera,
      takeNewsletterPhotoCamera,
      draft,
      publish,
      removeUploadedFile,
      maxNameLength,
    } = useNewContent();

    return {
      isLoading,
      state,
      submitted,
      handleSubmit,
      errors,
      optionsStatus,
      optionsCategory,
      onSelectedFiles,
      pictureDialog,
      banner_photo,
      banner_cover_photo,
      banner_deeplink_photo,
      announcement_cover_photo,
      newsletter_photo,
      crop_banner_photo,
      crop_banner_cover_photo,
      crop_banner_deeplink_photo,
      crop_announcement_cover_photo,
      crop_newsletter_photo,
      cropBannerPhotoImage,
      cropBannerCoverPhotoImage,
      cropBannerDeeplinkPhotoImage,
      cropAnnouncementCoverPhotoImage,
      cropNewsletterPhotoImage,
      showBannerPhotoCropDialog,
      showBannerCoverPhotoCropDialog,
      showBannerDeeplinkPhotoCropDialog,
      showAnnouncementCoverPhotoCropDialog,
      showNewsletterPhotoCropDialog,
      showBannerPhotoPictureDialog,
      showBannerCoverPhotoPictureDialog,
      showBannerDeeplinkPhotoPictureDialog,
      showAnnouncementCoverPhotoPictureDialog,
      showNewsletterPhotoPictureDialog,
      filteredTags,
      selectedTags,
      changeTags,
      takeBannerPhotoPicture,
      takeBannerCoverPhotoPicture,
      takeBannerDeeplinkPhotoPicture,
      takeAnnouncementCoverPhotoPicture,
      takeNewsletterPhotoPicture,
      takeBannerPhotoCamera,
      takeBannerCoverPhotoCamera,
      takeBannerDeeplinkPhotoCamera,
      takeAnnouncementCoverPhotoCamera,
      takeNewsletterPhotoCamera,
      draft,
      publish,
      removeUploadedFile,
      maxNameLength,
    };
  },
});
</script>
<style lang="scss">
.card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-upload {
  width: 8rem;
  height: 8rem;
  border: 1px solid #ced4da;
  border-radius: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem auto;
}

.member-profile {
  width: calc(8rem - 8px);
  height: calc(8rem - 8px);
  border-radius: 30%;
  object-fit: cover;
}

.p-fileupload-row > div > .p-button,
.p-fileupload-row > div > .p-button:hover {
  padding: 5px;
  background-color: transparent;
  color: red;
  border: 0px;
}

.p-fileupload-row {
  margin-bottom: 8px;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 2%), 0px 0px 2px rgb(0 0 0 / 5%),
    0px 1px 4px rgb(0 0 0 / 8%) !important;
}

.order-btn {
  border-radius: 0px;
}

.order-btn-active {
  background-color: green;
}

.create-new-tag {
  color: #22c55e;
  font-weight: 900;
}

.p-autocomplete-token {
  background: var(--primary-color) !important;
  color: var(--primary-color-text) !important;
}
</style>
