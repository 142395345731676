const moduleRoutes = [
  {
    path: "/resident/reject",
    name: "rejectList",
    children: [
      {
        path: "",
        name: "residentReject",
        component: () =>
          import("@/modules/resident/reject/list/RejectResident.vue"),
        meta: {
          action: "view",
          resource: "resident",
          layout: "default",
          title: "Reject List",
          breadcrumbs: [{ title: " Reject List", routeName: "residentReject" }],
        },
      },

      {
        path: ":id/detail",
        name: "RejectResidentDetail",
        component: () =>
          import("@/modules/resident/reject/entry/RejectListDetail.vue"),
        meta: {
          action: "view",
          resource: "resident",
          layout: "default",
          title: "Reject Detail",
          breadcrumbs: [
            { title: "Reject List", routeName: "residentReject" },
            { title: "Detail", routeName: "RejectResidentDetail" },
          ],
        },
      },
    ],
  },
];

export default moduleRoutes;
