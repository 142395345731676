export const endpoint = {
  /** [Master] NRC */
  nrc: "master/nrc",
  nrc_state: "master/nrc/states",
  nrc_type: "master/nrc/type",
  nrc_township: "master/nrc/township",

  /** Authorization */
  roles: "authorization/roles",
  permissions: "authorization/permissions",
  adminDashboardRoles: "authorization/admin-dashboard-roles",

  /** Auth */
  login: "auth/dashboard-login",
  logout: "auth/logout",
  verifyToken: "auth/verify-token",
  setPasword: "auth/set-password",
  setPasscode: "auth/set-passcode",

  /** Residents */
  residents: "resident/residents",
  residentApartments: "resident/apartments",
  rejectRemark: "resident/reject-remark",
  bills: "bill/bills",
  residentEmailRecipient: "resident/email-recipient",

  /** Case Management */
  caseCategories: "case-management/categories",
  cases: "case-management/cases",
  caseManagement: "case-management",

  /** SCSC */
  scscDashbarod: "scsc/dashboard",
  scscPrepaidPackages: "scsc/prepaid-packages",
  scscSubscriptions: "scsc/subscriptions",
  scscMember: "scsc/members",
  scscMemberPrepaidPackages: "scsc/member-prepaid-packages",
  scscMemberSubscriptions: "scsc/member-subscriptions",

  /** Facility */
  facilityBookings: "facility/bookings",
  facilities: "facility/facilities",
  facilitySlots: "facility/slots",
  facilityCourts: "facility/courts",
  facilityDashboard: "facility/dashboard",

  /** CMS */
  cmsContents: "cms/contents",
  cmsTags: "cms/tags",
  cmsCategories: "cms/categories",

  /** Community Feeds */
  communityAdminUsers: "users/community-admin",
  communityRules: "community/rules",
  communityMembers: "community/members",
  communityReasons: "community/reasons",
  communities: "community/communities",
  communityFeeds: "community/feeds",

  /** User Management */
  users: "users",

  /** FAQ */
  faqCategories: "faq/categories",
  faqs: "faq/faqs",

  /** Terms And Conditions */
  termsAndConditions: "terms-and-conditions",

  /** Settings */
  setting: "setting",

  /** App Versions */
  appVersions: "app-versions",

  /** Notifications */
  notifications: "notification/notifications",
};
