// Import the necessary libraries
const CryptoJS = require("crypto-js");

const encryptionKey = process.env.VUE_APP_ENCRYPTION_KEY;

// Encryption function
const encryptData = (data) => {
  try {
    const encryptedData = CryptoJS.AES.encrypt(data, encryptionKey).toString();
    return encryptedData;
  } catch {
    return null;
  }
};

// Decryption function
const decryptData = (encryptedData) => {
  try {
    const decryptedData = CryptoJS.AES.decrypt(
      encryptedData,
      encryptionKey
    ).toString(CryptoJS.enc.Utf8);
    return decryptedData;
  } catch {
    return null;
  }
};

const getUserRoles = () => {
  if (localStorage.getItem("userRoles")) {
    if (decryptData(localStorage.getItem("userRoles"))) {
      return JSON.parse(decryptData(localStorage.getItem("userRoles")));
    }
    return [];
  }
  return [];
};

const getUserData = () => {
  if (localStorage.getItem("userData")) {
    if (decryptData(localStorage.getItem("userData"))) {
      return JSON.parse(decryptData(localStorage.getItem("userData")));
    }
    return null;
  }
  return null;
};

const getUserPermissions = () => {
  if (localStorage.getItem("probability")) {
    if (decryptData(localStorage.getItem("probability"))) {
      return JSON.parse(decryptData(localStorage.getItem("probability")));
    }
    return null;
  }
  return null;
};

export {
  encryptData,
  decryptData,
  getUserRoles,
  getUserData,
  getUserPermissions,
};
