const moduleRoutes = [
  {
    path: "/facility-booking/bookings",
    name: "booking",
    children: [
      {
        path: "",
        name: "bookingList",
        component: () =>
          import("@/modules/facilityBooking/booking/list/List.vue"),
        meta: {
          action: "view",
          resource: "facility-booking",
          layout: "default",
          title: "Booking",
          breadcrumbs: [{ title: "Booking List", routeName: "bookingList" }],
        },
      },
      {
        path: ":id/detail",
        name: "detailBooking",
        component: () =>
          import("@/modules/facilityBooking/booking/entry/Detail.vue"),
        meta: {
          action: "view",
          resource: "facility-booking",
          layout: "default",
          title: "Detail Booking",
          breadcrumbs: [
            { title: "Booking List", routeName: "bookingList" },
            { title: "Detail", routeName: "detailBooking" },
          ],
        },
      },
    ],
  },
];

export default moduleRoutes;
