import { ref, onMounted, watch, onBeforeUnmount } from "vue";
import { useCategoryStore } from "@/modules/cms/category/categoryStore";
import { useConfirm } from "primevue/useconfirm";
import { useDebounceFn } from "@/utils/debounce";
import { multisortConvert } from "@/utils/multisort";
import EventBus from "@/libs/AppEventBus";

export const useCategoryList = () => {
  const loading = ref(true);
  const store = useCategoryStore();

  const confirm = useConfirm();
  const dt = ref();
  const lazyParams = ref({});
  const categoriesList = ref([]);
  const totalRecords = ref(10);
  const search = ref("");

  onMounted(() => {
    resetPagination();
    fetchCategoryList();
  });

  onBeforeUnmount(() => {
    store.$reset();
    store.$dispose();
  });

  const fetchCategoryList = async () => {
    loading.value = true;

    await store.allCategoryAction({
      page: (lazyParams.value.page += 1),
      per_page: lazyParams.value.rows,
      order: multisortConvert(lazyParams.value.multiSortMeta),
      search: search.value,
    });

    const response = store.getAllCategory;

    if (response) {
      categoriesList.value = response.data.data;
      totalRecords.value = response.data.total;
    }

    loading.value = false;
  };

  const resetPagination = () => {
    lazyParams.value = {
      page: 0,
      rows: dt.value.rows,
      multiSortMeta: [],
    };
  };

  const onPage = (event) => {
    lazyParams.value = event;
    lazyParams.value.multiSortMeta = [];
    fetchCategoryList();
  };

  const onSort = (event) => {
    lazyParams.value = event;
    lazyParams.value.page = 0;
    fetchCategoryList();
  };

  const showConfirmDialog = (id) => {
    confirm.require({
      message: "Are you sure?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Yes, delete it",
      rejectLabel: "Cancel",
      acceptClass: "p-button-danger",
      rejectClass: "p-button-danger p-button-text",
      accept: () => {
        deleteCategory(id);
      },
      reject: () => {
        //callback to execute when user rejects the action
      },
      onHide: () => {
        //Callback to execute when dialog is hidden
      },
    });
  };

  const deleteCategory = async (id) => {
    loading.value = true;
    const response = await store.deleteCategoryAction({ id: id });

    if (response) {
      resetPagination();
      fetchCategoryList();
      EventBus.emit("show-toast", {
        severity: "success",
        summary: "",
        detail: response.message,
      });
    }

    loading.value = false;
  };

  watch(
    [search],
    useDebounceFn(() => {
      resetPagination();
      fetchCategoryList();
    }, 500)
  );

  return {
    dt,
    lazyParams,
    totalRecords,
    categoriesList,
    loading,
    store,
    search,
    showConfirmDialog,
    onPage,
    onSort,
  };
};
