import { defineStore } from "pinia";
import { useContentService } from "@/modules/cms/content/contentService";

export const useContentStore = defineStore({
  id: "useContentStore",
  state: () => ({
    publishedContentState: null,
    draftContentState: null,
    addContentState: null,
    editContentState: null,
    updateContentState: null,
    deleteContentState: null,
    resendNotificationState: null,
    tagsListState: null,
    createTagsState: null,
    sortOrderState: null,
  }),

  getters: {
    getAllPulbishedContent(state) {
      return state.publishedContentState;
    },

    getTagsList(state) {
      return state.tagsListState;
    },

    getAllDraftContent(state) {
      return state.draftContentState;
    },

    getAddContent(state) {
      return state.addContentState;
    },

    getEditContent(state) {
      return state.editContentState;
    },

    getUpdateContent(state) {
      return state.updateContentState;
    },

    getDeleteContent(state) {
      return state.deleteContentState;
    },

    getResendNotification(state) {
      return state.resendNotificationState;
    },

    getCreateTagsState(state) {
      return state.createTagsState;
    },

    getSortOrderState(state) {
      return state.sortOrderState;
    },
  },

  actions: {
    async getAllPublishedContentAction(params) {
      const response = await useContentService.fetchAllContent(params);
      this.publishedContentState = response;
      return this.publishedContentState;
    },

    async getAllDraftContentAction(param) {
      const response = await useContentService.fetchAllContent(param);
      this.draftContentState = response;
      return this.draftContentState;
    },

    async addContentAction(params) {
      const response = await useContentService.fechAddContent(params);
      this.addContentState = response;
    },

    async editContentAction(params) {
      const response = await useContentService.fetchEditContent(params);
      this.editContentState = response;
    },

    async updateContentAction(id, params) {
      const response = await useContentService.fetchUpdateContent(id, params);
      this.updateContentState = response;
    },

    async deleteContentAction(params) {
      const response = await useContentService.fetchDeleteContent(params);
      this.deleteContentState = response;
    },

    async resendNotificationAction(params) {
      const response = await useContentService.fetchResendNotification(params);
      this.resendNotificationState = response;
    },

    async tagsListAction() {
      this.tagsListState = await useContentService.fetchTags();
      return this.tagsListState;
    },

    async createTagsAction(payload) {
      const response = await useContentService.fetchCreateTags(payload);
      this.createTagsState = response;
    },

    async sortOrderAction(params) {
      const response = await useContentService.fetchSortOrder(params);
      this.sortOrderState = response;
    },
  },
});
