import { ref, watch, watchEffect, onBeforeUnmount } from "vue";
import { useDebounceFn } from "@/utils/debounce";
import { multisortConvert } from "@/utils/multisort";
import { useContentStore } from "@/modules/cms/content/contentStore";

export const useDraftList = () => {
  const isLoading = ref(false);
  const draftList = ref([]);
  const totalRecords = ref(0);
  const dt = ref({});
  const lazyParams = ref({});
  const search = ref("");

  const store = useContentStore();

  onBeforeUnmount(() => {
    store.$reset();
    store.$dispose();
  });

  watch(
    [search],
    useDebounceFn(() => {
      resetPagination();
      fetchData();
      loadingData();
    }, 500)
  );

  watchEffect(async () => {
    const result = await store.draftContentState;
    if (result) {
      setData(result);
    }
  });

  const setData = (result) => {
    const { data, total } = result.data;
    data.map((value) => {
      value.severity =
        value.status === "ACTIVE"
          ? "success"
          : value.status === "DRAFT"
          ? "warning"
          : "info";

      return value;
    });

    draftList.value = data;
    totalRecords.value = total;
    return;
  };

  const loadingData = async () => {
    const result = await store.draftContentState;
    setData(result);
  };

  const fetchData = async () => {
    isLoading.value = true;

    await store.getAllDraftContentAction({
      platform: "dashboard",
      page: (lazyParams.value.page += 1),
      per_page: lazyParams.value.rows,
      type: "DRAFT",
      order: multisortConvert(lazyParams.value.multiSortMeta),
      search: search.value,
    });

    isLoading.value = false;
  };

  const resetPagination = () => {
    lazyParams.value = {
      page: 0,
      rows: dt.value.rows ? dt.value.rows : 10,
      multiSortMeta: [],
      first: 0,
    };
  };

  const onPage = (event) => {
    lazyParams.value = event;
    // lazyParams.value.multiSortMeta = [];
    fetchData();
    loadingData();
  };

  const onSort = (event) => {
    lazyParams.value = event;
    lazyParams.value.page = 0;
    lazyParams.value.first = 0;
    fetchData();
    loadingData();
  };

  return {
    isLoading,
    draftList,
    totalRecords,
    dt,
    lazyParams,
    search,
    onPage,
    onSort,
  };
};
