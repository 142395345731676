const scscDashboardRoutes = [
  {
    path: "/scsc/dashboard",
    name: "scsc-dashboard",
    children: [
      {
        path: "",
        name: "scscDashboard",
        component: () =>
          import("@/modules/scsc/dashboard/view/scscDashboard.vue"),
        meta: {
          action: "view",
          resource: "scsc-member",
          layout: "default",
          title: "SCSC Dashboard",
          breadcrumbs: [
            { title: "SCSC Dashboard", routeName: "scscDashboard" },
          ],
        },
      },
    ],
  },
];

export default scscDashboardRoutes;
