import { endpoint } from "@/constants/endpoint";
import api from "@/libs/axios";

const authService = {
  login: async (params) => {
    const result = await api.post(endpoint.login, params);
    return result.data;
  },

  logout: async (pushyToken) => {
    try {
      const result = await api.post(endpoint.logout, pushyToken);
      return result.data;
    } catch {
      return null;
    }
  },

  setPassword: async (token, request) => {
    const result = await api.put(`${endpoint.setPasword}/${token}`, request);
    return result.data;
  },

  setPasscodeWithToken: async (token, request) => {
    const result = await api.put(`${endpoint.setPasscode}/${token}`, request);
    return result.data;
  },

  verifyToken: async (token) => {
    const result = await api.put(`${endpoint.verifyToken}`, token);
    return result.data;
  },

  setPasscode: async (params) => {
    const result = await api.put(`${endpoint.setPasscode}`, params);
    return result.data;
  },
};

export { authService };
