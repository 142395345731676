const moduleRoutes = [
  {
    path: "/resident/inactive",
    name: "inactive",
    children: [
      {
        path: "",
        name: "residentInactive",
        component: () =>
          import("@/modules/resident/inactive/list/InactiveResident.vue"),
        meta: {
          action: "view",
          resource: "resident",
          layout: "default",
          title: "Inactive List",
          breadcrumbs: [
            { title: " Inactive List", routeName: "residentInactive" },
          ],
        },
      },

      {
        path: ":id/edit",
        name: "InactiveResidentDetail",
        component: () =>
          import(
            "@/modules/resident/inactive/entry/InactiveResidentDetail.vue"
          ),
        meta: {
          layout: "default",
          action: "view",
          resource: "resident",
          title: "Inactive Detail",
          breadcrumbs: [
            { title: "Inactive List", routeName: "residentInactive" },
            { title: "Edit", routeName: "InactiveResidentDetail" },
          ],
        },
      },
    ],
  },
];

export default moduleRoutes;
