import { Ability } from "@casl/ability";
import { initialAbility } from "./config";
import { decryptData } from "@/utils/encrypt";

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userPermission = localStorage.getItem("probability")
  ? JSON.parse(decryptData(localStorage.getItem("probability")))
  : null;
  
const existingAbility = userPermission ? userPermission : initialAbility;

export default new Ability(existingAbility || initialAbility);
