import CategoryList from "@/modules/cms/category/list/CategoryList";
// import NewCategory from "@/modules/cms/category/entry/NewCategory";
// import EditCategory from "@/modules/cms/category/entry/EditCategory";

const routes = [
  {
    path: "/cms/categories",
    name: "cms-categories",
    children: [
      {
        path: "",
        name: "categoryList",
        component: CategoryList,
        meta: {
          // resource: "scsc-categories",
          // action: "index",
          layout: "default",
          title: "Categories",
          breadcrumbs: [{ title: "Category List", routeName: "categoryList" }],
        },
      },
      // {
      //   path: "create",
      //   name: "newCategory",
      //   component: NewCategory,
      //   meta: {
      //     resource: "scsc-categories",
      //     action: "store",
      //     layout: "default",
      //     title: "Create Category",
      //     breadcrumbs: [
      //       { title: "Category List", routeName: "CategoryList" },
      //       { title: "Create", routeName: "newCategory" },
      //     ],
      //   },
      // },
      // {
      //   path: ":id/edit",
      //   name: "editCategory",
      //   component: EditCategory,
      //   meta: {
      //     resource: "scsc-categories",
      //     action: "update",
      //     layout: "default",
      //     title: "Edit Category",
      //     breadcrumbs: [
      //       { title: "Category List", routeName: "CategoryList" },
      //       { title: "Edit", routeName: "editCategory" },
      //     ],
      //   },
      // },
    ],
  },
];

export default routes;
