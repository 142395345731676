import { reactive, ref, onMounted, watch, onBeforeUnmount, computed } from "vue";
import { useContentStore } from "@/modules/cms/content/contentStore";
import { useCategoryStore } from "@/modules/cms/category/categoryStore";
import { useRouter } from "vue-router";
import EventBus from "@/libs/AppEventBus";
import { Errors } from "@/utils/validation";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { LocalToUTC } from "@/utils/formatter";
import moment from "moment";

export const useNewContent = () => {
  const contentStore = useContentStore();
  const categoryStore = useCategoryStore();

  const router = useRouter();

  const isLoading = ref(false);
  const submitted = ref(false);
  const errors = new Errors();
  const showBannerPhotoCropDialog = ref(false);
  const showBannerCoverPhotoCropDialog = ref(false);
  const showBannerDeeplinkPhotoCropDialog = ref(false);
  const showAnnouncementCoverPhotoCropDialog = ref(false);
  const showNewsletterPhotoCropDialog = ref(false);
  const crop_banner_photo = ref(null);
  const crop_banner_cover_photo = ref(null);
  const crop_banner_deeplink_photo = ref(null);
  const crop_announcement_cover_photo = ref(null);
  const crop_newsletter_photo = ref(null);
  const banner_photo = ref(null);
  const banner_cover_photo = ref(null);
  const banner_deeplink_photo = ref(null);
  const announcement_cover_photo = ref(null);
  const newsletter_photo = ref(null);
  const bannerFileUrl = ref("");
  const bannerCoverFileUrl = ref("");
  const bannerDeeplinkFileUrl = ref("");
  const announcementCoverFileUrl = ref("");
  const newsletterFileUrl = ref("");
  const optionsCategory = ref([]);
  const attachments = ref([]);
  const newsletter = ref([]);
  const showBannerPhotoPictureDialog = ref(false);
  const showBannerCoverPhotoPictureDialog = ref(false);
  const showBannerDeeplinkPhotoPictureDialog = ref(false);
  const showAnnouncementCoverPhotoPictureDialog = ref(false);
  const showNewsletterPhotoPictureDialog = ref(false);
  const noData = ref("");

  const state = reactive({
    titleEn: "",
    titleMm: "",
    status: "",
    categoryId: "",
    contentEn: "",
    contentMm: "",
    published_at: null,
    isNotifiable: true,
    isDeepLink: false,
    deepLink: "",
    order: null,
    isPublishSchedule: false,
    tags: [],
    // has_notified: false,
    // started_at: null,
    // ended_at: null,
  });

  const optionsStatus = ref([
    { name: "Active", code: "ACTIVE" },
    { name: "Disabled", code: "DISABLED" },
    { name: "Draft", code: "DRAFT" },
  ]);

  const tags = ref([]);

  const filteredTags = ref([]);

  const tagsData = async () => {
    isLoading.value = true;

    try {
      const response = await contentStore.tagsListAction();
      if (response) {
        tags.value = response.data;
      }
      isLoading.value = false;
    } catch (error) {
      isLoading.value = false;
      if (error.status === 422) {
        const err = error.data.data;
        errors.record(err);
      }
    }
  };

  const categoryData = async () => {
    isLoading.value = true;

    try {
      await categoryStore.allCategoryAction();

      const response = categoryStore.getAllCategory;

      if (response) {
        optionsCategory.value = response.data;
        state.categoryId = response.data[0];
      }

      isLoading.value = false;
    } catch (error) {
      isLoading.value = false;
      if (error.status === 422) {
        const err = error.data.data;
        errors.record(err);
      }
    }
  };

  onMounted(() => {
    categoryData();
    tagsData();
  });

  onBeforeUnmount(() => {
    contentStore.$reset();
    contentStore.$dispose();
    categoryStore.$reset();
    categoryStore.$dispose();
  });

  const handleSubmit = () => {
    addContent();
  };

  const draft = () => {
    state.status = "DRAFT";
    handleSubmit();
  };

  const publish = () => {
    state.status = "ACTIVE";
    handleSubmit();
  };

  const addContent = async () => {
    errors.clear();
    isLoading.value = true;

    // const content = JSON.stringify({
    //   en: state.contentEn ? state.contentEn : state.contentMm,
    //   mm: state.contentMm ? state.contentMm : state.contentEn,
    // });

    // const title = JSON.stringify({
    //   en: state.titleEn ? state.titleEn : state.titleMm,
    //   mm: state.titleMm ? state.titleMm : state.titleEn,
    // });

    var formData = new FormData();

    if (!state.isDeepLink && state.categoryId.title == "PROMOTION") {
      if (bannerFileUrl.value.length != 0) {
        formData.append("banner_photo", bannerFileUrl.value);
      }

      if (bannerCoverFileUrl.value.length != 0) {
        formData.append("cover_photo", bannerCoverFileUrl.value);
      }
    }

    if (state.isDeepLink && state.categoryId.title == "PROMOTION") {
      if (bannerDeeplinkFileUrl.value.length != 0) {
        formData.append("banner_photo", bannerDeeplinkFileUrl.value);
      }
    }

    if (!state.isDeepLink && state.categoryId.title == "ANNOUNCEMENT") {
      if (announcementCoverFileUrl.value.length != 0) {
        formData.append("cover_photo", announcementCoverFileUrl.value);
      }
    }

    if (state.categoryId.title == "NEWSLETTER") {
      if (newsletterFileUrl.value.length != 0) {
        formData.append("banner_photo", newsletterFileUrl.value);
      }
    }

    if (
      state.isDeepLink &&
      (state.categoryId.title == "PROMOTION" ||
        state.categoryId.title == "ANNOUNCEMENT")
    ) {
      formData.append("deep_link", state.deepLink);
    }

    if (
      ((state.categoryId.title == "PROMOTION" ||
        state.categoryId.title == "ANNOUNCEMENT") &&
        !state.isDeepLink) ||
      state.categoryId.title == "NEWSLETTER"
    ) {
      attachments.value.map((value) => {
        formData.append("attachments[]", value || null);
      });
    }

    formData.append(
      "is_deep_link",
      state.categoryId.title == "NEWSLETTER" ? 0 : state.isDeepLink ? 1 : 0
    );

    // if (state.categoryId.title == "NEWSLETTER") {
    //   formData.append("is_deep_link", 0);
    // }

    // if (state.titleEn || state.titleMm) {
    //   formData.append("title", title);
    // }

    // if (state.contentEn || state.contentMm) {
    //   formData.append("content", content);
    // }
    // formData.append("title[en]", JSON.stringify({ en: state.titleEn }));
    // formData.append("title[mm]", JSON.stringify({ mm: state.titleMm }));
    // formData.append("title[en]", state.titleEn);
    // formData.append("title[mm]", state.titleMm);
    // formData.append("content[en]", state.contentEn);
    // formData.append("content[mm]", state.contentMm);

    formData.append("title[en]", state.titleEn.slice(0, maxNameLength.value));
    formData.append("content[en]", state.contentEn);

    formData.append("category_id", state.categoryId.id);
    formData.append("category_title", state.categoryId.title);

    const publishedAt = state.isPublishSchedule
      ? LocalToUTC(state.published_at)
      : moment.utc().format("YYYY-MM-DD HH:mm:ss");

    formData.append("published_at", publishedAt);
    formData.append("is_notifiable", state.isNotifiable ? 1 : 0);
    formData.append("status", state.status);

    state.tags.map((value) => formData.append("tags[]", value.name));
    // formData.append("has_notified", state.has_notified ? 1 : 0);
    // formData.append("started_at", dateShort(state.started_at) || null);
    // formData.append("ended_at", dateShort(state.ended_at) || null);

    if (state.categoryId.title == "NEWSLETTER") {
      let newsLetter = null;
      if (newsletter.value.length > 0) {
        newsLetter = newsletter.value[0];
        newsletter.value = [];
      }
      formData.append("newsletter", newsLetter);
    }

    try {
      await contentStore.addContentAction(formData);

      const response = contentStore.getAddContent;

      if (response) {
        router.push({ name: "contentList" });
        EventBus.emit("show-toast", {
          severity: "success",
          summary: "",
          detail: response.message,
        });
      }

      isLoading.value = false;
    } catch (error) {
      isLoading.value = false;
      if (error.status === 422) {
        const err = error.data.data;
        errors.record(err);
      }
    }
  };

  // Banner photo
  const takeBannerPhotoCamera = async () => {
    showBannerPhotoPictureDialog.value = false;
    try {
      const cameraOptions = {
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        saveToGallery: false,
        width: 300,
        height: 300,
      };

      const image = await Camera.getPhoto(cameraOptions);

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      banner_photo.value = image.webPath;
      showBannerPhotoCropDialog.value = true;
    } catch (err) {
      noData.value(err);
    }
  };

  const takeBannerPhotoPicture = async () => {
    showBannerPhotoPictureDialog.value = false;
    const cameraOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      saveToGallery: false,
      width: 300,
      height: 300,
    };

    const image = await Camera.getPhoto(cameraOptions);

    try {
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      banner_photo.value = image.webPath;
      showBannerPhotoCropDialog.value = true;
    } catch (err) {
      noData.value(err);
    }
  };

  const cropBannerPhotoImage = async ({ canvas }) => {
    // const result = this.$refs.cropper.getResult()
    // const newTab = window.open()
    // // for testing open the result in a new tab
    // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL(
    //   "image/jpeg"
    // )}"></img>`

    crop_banner_photo.value = canvas.toDataURL("image/jpeg");

    canvas.toBlob(function (blob) {
      bannerFileUrl.value = blob;
    });
  };

  // Banner Photo

  // Banner Cover photo
  const takeBannerCoverPhotoCamera = async () => {
    showBannerCoverPhotoPictureDialog.value = false;
    try {
      const cameraOptions = {
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        saveToGallery: false,
        width: 300,
        height: 300,
      };

      const image = await Camera.getPhoto(cameraOptions);

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      banner_cover_photo.value = image.webPath;
      showBannerCoverPhotoCropDialog.value = true;
    } catch (err) {
      noData.value(err);
    }
  };

  const takeBannerCoverPhotoPicture = async () => {
    showBannerCoverPhotoPictureDialog.value = false;
    const cameraOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      saveToGallery: false,
      width: 300,
      height: 300,
    };

    const image = await Camera.getPhoto(cameraOptions);

    try {
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      banner_cover_photo.value = image.webPath;
      showBannerCoverPhotoCropDialog.value = true;
    } catch (err) {
      noData.value(err);
    }
  };

  const cropBannerCoverPhotoImage = async ({ canvas }) => {
    // const result = this.$refs.cropper.getResult()
    // const newTab = window.open()
    // // for testing open the result in a new tab
    // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL(
    //   "image/jpeg"
    // )}"></img>`

    crop_banner_cover_photo.value = canvas.toDataURL("image/jpeg");

    canvas.toBlob(function (blob) {
      bannerCoverFileUrl.value = blob;
    });
  };

  // Banner Cover Photo

  // Banner Deeplink photo
  const takeBannerDeeplinkPhotoCamera = async () => {
    showBannerDeeplinkPhotoPictureDialog.value = false;
    try {
      const cameraOptions = {
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        saveToGallery: false,
        width: 300,
        height: 300,
      };

      const image = await Camera.getPhoto(cameraOptions);

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      banner_deeplink_photo.value = image.webPath;
      showBannerDeeplinkPhotoCropDialog.value = true;
    } catch (err) {
      noData.value(err);
    }
  };

  const takeBannerDeeplinkPhotoPicture = async () => {
    showBannerDeeplinkPhotoPictureDialog.value = false;
    const cameraOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      saveToGallery: false,
      width: 300,
      height: 300,
    };

    const image = await Camera.getPhoto(cameraOptions);

    try {
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      banner_deeplink_photo.value = image.webPath;
      showBannerDeeplinkPhotoCropDialog.value = true;
    } catch (err) {
      noData.value(err);
    }
  };

  const cropBannerDeeplinkPhotoImage = async ({ canvas }) => {
    // const result = this.$refs.cropper.getResult()
    // const newTab = window.open()
    // // for testing open the result in a new tab
    // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL(
    //   "image/jpeg"
    // )}"></img>`

    crop_banner_deeplink_photo.value = canvas.toDataURL("image/jpeg");

    canvas.toBlob(function (blob) {
      bannerDeeplinkFileUrl.value = blob;
    });
  };

  // Banner Deeplink Photo

  // Announcement Cover photo
  const takeAnnouncementCoverPhotoCamera = async () => {
    showAnnouncementCoverPhotoPictureDialog.value = false;
    try {
      const cameraOptions = {
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        saveToGallery: false,
        width: 300,
        height: 300,
      };

      const image = await Camera.getPhoto(cameraOptions);

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      announcement_cover_photo.value = image.webPath;
      showAnnouncementCoverPhotoCropDialog.value = true;
    } catch (err) {
      noData.value(err);
    }
  };

  const takeAnnouncementCoverPhotoPicture = async () => {
    showAnnouncementCoverPhotoPictureDialog.value = false;
    const cameraOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      saveToGallery: false,
      width: 300,
      height: 300,
    };

    const image = await Camera.getPhoto(cameraOptions);

    try {
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      announcement_cover_photo.value = image.webPath;
      showAnnouncementCoverPhotoCropDialog.value = true;
    } catch (err) {
      noData.value(err);
    }
  };

  const cropAnnouncementCoverPhotoImage = async ({ canvas }) => {
    // const result = this.$refs.cropper.getResult()
    // const newTab = window.open()
    // // for testing open the result in a new tab
    // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL(
    //   "image/jpeg"
    // )}"></img>`

    crop_announcement_cover_photo.value = canvas.toDataURL("image/jpeg");

    canvas.toBlob(function (blob) {
      announcementCoverFileUrl.value = blob;
    });
  };

  // Announcement Photo

  // Newsletter photo
  const takeNewsletterPhotoCamera = async () => {
    showNewsletterPhotoPictureDialog.value = false;
    try {
      const cameraOptions = {
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        saveToGallery: false,
        width: 300,
        height: 300,
      };

      const image = await Camera.getPhoto(cameraOptions);

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      newsletter_photo.value = image.webPath;
      showNewsletterPhotoCropDialog.value = true;
    } catch (err) {
      noData.value(err);
    }
  };

  const takeNewsletterPhotoPicture = async () => {
    showNewsletterPhotoPictureDialog.value = false;
    const cameraOptions = {
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      saveToGallery: false,
      width: 300,
      height: 300,
    };

    const image = await Camera.getPhoto(cameraOptions);

    try {
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      newsletter_photo.value = image.webPath;
      showNewsletterPhotoCropDialog.value = true;
    } catch (err) {
      noData.value(err);
    }
  };

  const cropNewsletterPhotoImage = async ({ canvas }) => {
    // const result = this.$refs.cropper.getResult()
    // const newTab = window.open()
    // // for testing open the result in a new tab
    // newTab.document.body.innerHTML = `<img src="${canvas.toDataURL(
    //   "image/jpeg"
    // )}"></img>`

    crop_newsletter_photo.value = canvas.toDataURL("image/jpeg");

    canvas.toBlob(function (blob) {
      newsletterFileUrl.value = blob;
    });
  };

  // Newsletter Photo

  const pictureDialog = (type) => {
    if (type == "bannerPhoto") {
      showBannerPhotoPictureDialog.value = true;
      showBannerCoverPhotoPictureDialog.value = false;
      showBannerDeeplinkPhotoPictureDialog.value = false;
      showAnnouncementCoverPhotoPictureDialog.value = false;
      showNewsletterPhotoPictureDialog.value = false;
    } else if (type == "bannerCoverPhoto") {
      showBannerPhotoPictureDialog.value = false;
      showBannerCoverPhotoPictureDialog.value = true;
      showBannerDeeplinkPhotoPictureDialog.value = false;
      showAnnouncementCoverPhotoPictureDialog.value = false;
      showNewsletterPhotoPictureDialog.value = false;
    } else if (type == "bannerDeeplinkPhoto") {
      showBannerPhotoPictureDialog.value = false;
      showBannerCoverPhotoPictureDialog.value = false;
      showBannerDeeplinkPhotoPictureDialog.value = true;
      showAnnouncementCoverPhotoPictureDialog.value = false;
      showNewsletterPhotoPictureDialog.value = false;
    } else if (type == "announcementCoverPhoto") {
      showBannerPhotoPictureDialog.value = false;
      showBannerCoverPhotoPictureDialog.value = false;
      showBannerDeeplinkPhotoPictureDialog.value = false;
      showAnnouncementCoverPhotoPictureDialog.value = true;
      showNewsletterPhotoPictureDialog.value = false;
    } else if (type == "newsletterPhoto") {
      showBannerPhotoPictureDialog.value = false;
      showBannerCoverPhotoPictureDialog.value = false;
      showBannerDeeplinkPhotoPictureDialog.value = false;
      showAnnouncementCoverPhotoPictureDialog.value = false;
      showNewsletterPhotoPictureDialog.value = true;
    }
  };

  const onSelectedFiles = async (event) => {
    attachments.value = event.files;
    newsletter.value =
      state.categoryId.title == "NEWSLETTER" ? event.files : [];
  };

  const selectedTags = (event) => {
    setTimeout(() => {
      if (!event.query.trim().length) {
        filteredTags.value = [...tags.value];
      } else {
        filteredTags.value = tags.value.filter((tag) => {
          return tag.name.toLowerCase().startsWith(event.query.toLowerCase());
        });

        if (filteredTags.value.length === 0) {
          filteredTags.value.push({ name: event.query, new: true });
        }
      }
    }, 250);
  };

  const removeUploadedFile = async (event) => {
    attachments.value = event.files;
  };

  const changeTags = (event) => {
    state.tags = event.value;
  };

  const maxNameLength = computed(() => {
    return state.categoryId.title === 'NEWSLETTER' ? 50 : 70;
  });

  watch(
    () => state.isDeepLink,
    () => {
      errors.clear();
    }
  );

  return {
    isLoading,
    state,
    submitted,
    errors,
    handleSubmit,
    optionsStatus,
    optionsCategory,
    onSelectedFiles,
    pictureDialog,
    banner_photo,
    banner_cover_photo,
    banner_deeplink_photo,
    announcement_cover_photo,
    newsletter_photo,
    crop_banner_photo,
    crop_banner_cover_photo,
    crop_banner_deeplink_photo,
    crop_announcement_cover_photo,
    crop_newsletter_photo,
    cropBannerPhotoImage,
    cropBannerCoverPhotoImage,
    cropBannerDeeplinkPhotoImage,
    cropAnnouncementCoverPhotoImage,
    cropNewsletterPhotoImage,
    showBannerPhotoCropDialog,
    showBannerCoverPhotoCropDialog,
    showBannerDeeplinkPhotoCropDialog,
    showAnnouncementCoverPhotoCropDialog,
    showNewsletterPhotoCropDialog,
    showBannerPhotoPictureDialog,
    showBannerCoverPhotoPictureDialog,
    showBannerDeeplinkPhotoPictureDialog,
    showAnnouncementCoverPhotoPictureDialog,
    showNewsletterPhotoPictureDialog,
    filteredTags,
    selectedTags,
    changeTags,
    takeBannerPhotoPicture,
    takeBannerCoverPhotoPicture,
    takeBannerDeeplinkPhotoPicture,
    takeAnnouncementCoverPhotoPicture,
    takeNewsletterPhotoPicture,
    takeBannerPhotoCamera,
    takeBannerCoverPhotoCamera,
    takeBannerDeeplinkPhotoCamera,
    takeAnnouncementCoverPhotoCamera,
    takeNewsletterPhotoCamera,
    draft,
    publish,
    removeUploadedFile,
    maxNameLength
  };
};
