import api from "@/libs/axios";
import qs from "qs";
import { endpoint } from "@/constants/endpoint";

const useCategoryService = {
  fetchAllCategory: async (params) => {
    try {
      const result = await api.get(`${endpoint.cmsCategories}`, {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });

      return result.data;
    } catch {
      return null;
    }
  },

  // fetchShowCategory: async (params) => {
  //     try {
  //     const result = await api.get(
  //         `${endpoints.scsc}/subscriptions/${params.id}`
  //     );
  //     return result.data;
  //     } catch {
  //         return null;
  //     }
  // },

  // fetchUpdateCategory: async (params) => {
  //     const result = await api.put(`${endpoints.scsc}/subscriptions/${params.id}`,params);
  //     return result.data;
  // },

  // fechCreateCategory: async (params) => {
  //     const result = await api.post(`${endpoints.scsc}/subscriptions`, params);
  //     return result.data;
  // },

  // fetchDeleteCategory: async (params) => {
  //     try {
  //     const result = await api.delete(
  //         `${endpoints.scsc}/subscriptions/${params.id}`
  //     );
  //     return result.data;
  //     } catch {
  //     return null;
  //     }
  // },
};

export { useCategoryService };
