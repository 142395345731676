const routes = [
  {
    path: "/users/dashboard-user",
    name: "dashboardUsers",
    children: [
      {
        path: "",
        name: "dashboardUserList",
        component: () =>
          import("@/modules/userManagement/users/list/DashboardUsers.vue"),
        meta: {
          action: "view",
          resource: "user",
          layout: "default",
          title: "Dashboard Users",
          breadcrumbs: [
            { title: "Dashboard User List", routeName: "dashboardUserList" },
          ],
        },
      },
      {
        path: "create",
        name: "newDashboardUser",
        component: () =>
          import("@/modules/userManagement/users/entry/NewDashboardUser.vue"),
        meta: {
          action: "create",
          resource: "user",
          layout: "default",
          title: "Create Dashboard User",
          breadcrumbs: [
            { title: "Dashboard User List", routeName: "dashboardUserList" },
            { title: "Create", routeName: "newDashboardUser" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "editDashboardUser",
        component: () =>
          import("@/modules/userManagement/users/entry/EditDashboardUser.vue"),
        meta: {
          action: "view",
          resource: "user",
          layout: "default",
          title: "Edit Dashboard User",
          breadcrumbs: [
            { title: "Dashboard User List", routeName: "dashboardUserList" },
            { title: "Edit", routeName: "editDashboardUser" },
          ],
        },
      },
      {
        path: ":id/view",
        name: "viewDashboardUser",
        component: () =>
          import("@/modules/userManagement/users/entry/ViewDashboardUser.vue"),
        meta: {
          action: "view",
          resource: "user",
          layout: "default",
          title: "View Dashboard User",
          breadcrumbs: [
            { title: "Dashboard User List", routeName: "dashboardUserList" },
            { title: "View", routeName: "viewDashboardUser" },
          ],
        },
      },
    ],
  },
  {
    path: "/users/app-user",
    name: "appUsers",
    children: [
      {
        path: "",
        name: "appUserList",
        component: () =>
          import("@/modules/userManagement/users/list/AppUsers.vue"),
        meta: {
          action: "view",
          resource: "user",
          layout: "default",
          title: "App Users",
          breadcrumbs: [{ title: "App User List", routeName: "appUserList" }],
        },
      },
      {
        path: "create",
        name: "newAppUser",
        component: () =>
          import("@/modules/userManagement/users/entry/NewAppUser.vue"),
        meta: {
          action: "create",
          resource: "user",
          layout: "default",
          title: "Create App User",
          breadcrumbs: [
            { title: "App User List", routeName: "appUserList" },
            { title: "Create", routeName: "newAppUser" },
          ],
        },
      },
      {
        path: ":id/view",
        name: "viewAppUser",
        component: () =>
          import("@/modules/userManagement/users/entry/ViewAppUser.vue"),
        meta: {
          action: "view",
          resource: "user",
          layout: "default",
          title: "View App User",
          breadcrumbs: [
            { title: "App User List", routeName: "appUserList" },
            { title: "View", routeName: "viewAppUser" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "editAppUser",
        component: () =>
          import("@/modules/userManagement/users/entry/EditAppUser.vue"),
        meta: {
          action: "view",
          resource: "user",
          layout: "default",
          title: "Edit App User",
          breadcrumbs: [
            { title: "App User List", routeName: "appUserList" },
            { title: "Edit", routeName: "editAppUser" },
          ],
        },
      },
    ],
  },
];

export default routes;
