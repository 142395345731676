<template>
  <DataTable
    ref="dt"
    data-key="id"
    sortMode="multiple"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    responsiveLayout="scroll"
    scrollHeight="50vh"
    :lazy="true"
    :paginator="true"
    :value="publishedList"
    :multiSortMeta="lazyParams.multiSortMeta"
    :totalRecords="totalRecords"
    :rows="10"
    :first="lazyParams.first"
    :rowsPerPageOptions="[10, 25, 50]"
    @page="onPage($event)"
    @sort="onSort($event)"
    removableSort
  >
    <template #header>
      <div class="flex flex-wrap justify-content-between align-items-center">
        <h5 class="m-0 md:mb-2 block lg:hidden">Contents</h5>

        <div class="mt-3 md:mt-0 ml-0 lg:ml-auto">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <div class="mt-3 md:mt-0">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="search" placeholder="Keyword Search" />
              </span>

              <router-link
                v-if="$can('create', 'content')"
                :to="{ name: 'newContent' }"
              >
                <Button label="Create" class="p-button-primary ml-2" />
              </router-link>
            </div>
          </span>
        </div>
      </div>
    </template>

    <template #empty> Published content data not found. </template>
    <template #loading> Loading published content data. Please wait. </template>

    <Column
      header="Title"
      field="title"
      style="min-width: 200px"
      :sortable="true"
      :frozen="true"
      alignFrozen="left"
    >
      <template #body="{ data }">
        <router-link
          v-if="$can('view', 'content')"
          :to="{ name: 'editContent', params: { id: data.id, type: 'none' } }"
        >
          {{ data.title.en }}
        </router-link>
      </template>
    </Column>

    <Column header="Category" field="category_title" :sortable="true" />

    <Column header="Published Date" field="published_at" :sortable="true">
      <template #body="{ data }">
        {{ utcToLocal(data.published_at) }}
      </template>
    </Column>

    <Column field="status" header="Status" :sortable="true">
      <template #body="{ data }">
        <Badge :value="data.status" :severity="data.severity"></Badge>
      </template>
    </Column>

    <Column field="is_pinned" header="Is Pinned" :sortable="true">
      <template #body="{ data }">
        <Badge
          v-if="data.is_pinned"
          :severity="data.is_pinned ? 'primary' : 'warning'"
        >
          Is Pinned
        </Badge>
      </template>
    </Column>

    <Column
      field="resend_notification"
      header="Action"
      v-if="$can('resent', 'content-notification')"
    >
      <template #body="{ data }">
        <Button
          v-if="data.status === 'ACTIVE'"
          label="Resend Notification"
          @click="() => showConfirmDialog(data.id)"
          class="p-button-sm"
        />
      </template>
    </Column>
  </DataTable>

  <ConfirmDialog :showHeader="false">
    <template #message="slotProps">
      <div class="text-center w-full">
        <Button
          type="button"
          :icon="slotProps.message.icon"
          class="p-button-lg p-button-warning p-button-rounded p-button-outlined mt-4"
        />
        <h4>{{ slotProps.message.message }}</h4>
      </div>
    </template>
  </ConfirmDialog>

  <Loading v-if="isLoading"></Loading>
</template>

<script>
import { defineComponent } from "vue";
import { usePublishedList } from "@/modules/cms/content/list/usePublishedList";
import { utcToLocal } from "@/utils/formatter";
import ConfirmDialog from "primevue/confirmdialog";

export default defineComponent({
  name: "PublishedList",
  components: { ConfirmDialog },
  setup() {
    const {
      isLoading,
      publishedList,
      totalRecords,
      dt,
      lazyParams,
      search,
      onPage,
      onSort,
      showConfirmDialog,
    } = usePublishedList();

    return {
      isLoading,
      publishedList,
      totalRecords,
      dt,
      lazyParams,
      search,
      onPage,
      onSort,
      utcToLocal,
      showConfirmDialog,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/css/custom-table.scss";
</style>