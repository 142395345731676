import ability from "@/libs/acl/ability";

export const canNavigate = (to) => {
  if (to.meta.resource) {
    const myResource = to.meta.resource.split("|");
    return myResource.some((resource) =>
      ability.can(to.meta.action || "view", resource)
    );
  }
  return true;
};

export const _ = undefined;
