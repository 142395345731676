export const initialAbility = [
  {
    action: "read",
    subject: "user",
  },
  {
    action: "read",
    subject: "home",
  },
  {
    action: "read",
    subject: "locale",
  },
];

export const _ = undefined;
