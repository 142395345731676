import { defineStore } from "pinia";
import { authService } from "./authService";
import router from "@/routers";
import { initialAbility } from "@/libs/acl/config";
import ability from "@/libs/acl/ability";
import Pushy from "pushy-sdk-web";
import { useNotificationStore } from "@/modules/notification/notificationStore";
import { nrcService } from "@/services/nrcService";
import { useUserProfileStore } from "@/modules/user/userProfileStore";
import { encryptData, getUserData } from "@/utils/encrypt";
import { removeLocalStorageItem } from "@/utils/httpHandlers";

export const useAuthStore = defineStore({
  id: "useAuthStore",
  state: () => ({
    token: localStorage.getItem("userToken") || null,
    userData: getUserData(),
    loginResponse: null,
    setPasswordState: null,
    setPasscodeWithTokenState: null,
    verifyTokenState: null,
    setPasscodeState: null,
  }),
  getters: {
    getToken(state) {
      return state.token;
    },
    getUserData(state) {
      return state.userData;
    },
    getLoginResponse(state) {
      return state.loginResponse;
    },
    isAuth(state) {
      return state.token ? true : false;
    },
    getSetPassword(state) {
      return state.setPasswordState;
    },
    getSetPasscodeWithToken(state) {
      return state.setPasscodeWithTokenState;
    },
    getVerifyToken(state) {
      return state.verifyTokenState;
    },
    getSetPasscode(state) {
      return state.setPasscodeState;
    },
  },

  actions: {
    async login(params) {
      const response = await authService.login(params);
      const store = useUserProfileStore();
      this.loginResponse = response;
      if (response) {
        nrcService.nrcList().then((result) => {
          localStorage.setItem("nrc", JSON.stringify(result));
        });

        this.token = response.data.access_token;

        await store.showUserProfileAction(this.token);

        const userData = store.getShowUserProfile;

        var responseAbility = userData.data.allowed_permissions;

        var currentInitialAbility = initialAbility;
        var userAbility = responseAbility.concat(currentInitialAbility);
        var encodeUserAbility = JSON.stringify(userAbility);

        localStorage.setItem("probability", encryptData(encodeUserAbility));
        //update abality
        ability.update(JSON.parse(encodeUserAbility));

        localStorage.setItem("userToken", this.token);

        localStorage.setItem(
          "userData",
          encryptData(JSON.stringify(userData.data))
        );
        localStorage.setItem(
          "userRoles",
          userData.data.roles
            ? encryptData(JSON.stringify(userData.data.roles))
            : []
        );
        this.userData = userData.data;
        this.setPushyData();
      }
    },
    async logout() {
      const pushyToken = localStorage.getItem("pushyToken");
      await authService.logout({ pushy_token: pushyToken });
      removeLocalStorageItem();
      // localStorage.removeItem("apartment");
      this.token = null;
      this.userData = null;
      router.push({ name: "login" });
    },

    async setUserData(data) {
      this.userData = data;
    },

    async setPushyData() {
      Pushy.register({
        appId: process.env.VUE_APP_PUSHY_APP_ID,
      })
        .then(function (deviceToken) {
          // Print device token to console
          // console.log("Pushy device token: " + deviceToken);
          const notificationStore = useNotificationStore();

          notificationStore.sendPushyTokenAction({
            pushy_token: deviceToken,
          });

          // Send the token to your backend server via an HTTP GET request
          //fetch('https://your.api.hostname/register/device?token=' + deviceToken);

          // Succeeded, optionally do something to alert the user
        })
        .catch(function (err) {
          // Handle registration errors
          console.error(err);
        });
    },

    async setPasswordAction(id, params) {
      const response = await authService.setPassword(id, params);
      this.setPasswordState = response;
    },

    async setPasscodeWithTokenAction(id, params) {
      const response = await authService.setPasscodeWithToken(id, params);
      this.setPasscodeWithTokenState = response;
    },

    async verifyTokenAction(token) {
      const response = await authService.verifyToken(token);
      this.verifyTokenState = response;
    },

    async setPasscodeAction(id, params) {
      const response = await authService.setPasscode(id, params);
      this.setPasscodeState = response;
    },
  },
});
