const moduleRoutes = [
  {
    path: "/resident/pending",
    name: "residentRoutes",
    children: [
      {
        path: "",
        name: "residentPending",
        component: () =>
          import("@/modules/resident/pending/list/PendingResident.vue"),
        meta: {
          action: "view",
          layout: "default",
          resource: "resident",
          title: "Pending List",
          breadcrumbs: [
            { title: "Pending List", routeName: "residentPending" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "PendingResidentDetail",
        component: () =>
          import("@/modules/resident/pending/entry/PendingResidentDetail.vue"),
        meta: {
          layout: "default",
          action: "view",
          resource: "resident",
          title: "Pending Detail",
          breadcrumbs: [
            { title: "Pending List", routeName: "residentPending" },
            { title: "Edit", routeName: "PendingResidentDetail" },
          ],
        },
      },
    ],
  },
];

export default moduleRoutes;
