import { onMounted, ref } from "vue";
import { useContentStore } from "@/modules/cms/content/contentStore";
import { multisortConvert } from "@/utils/multisort";

export const useContent = () => {

    const isLoading = ref(false);
    const activeIndex = ref(0);
    const lazyParams = ref({});

    const store = useContentStore();

    onMounted(async () => {
        isLoading.value = true;
        await fetchPublishedContent();
        isLoading.value = false;
    });

    const fetchPublishedContent = async () => {
        await store.getAllPublishedContentAction({
            platform: "dashboard",
            page: 1,
            per_page: 10,
            order: multisortConvert(lazyParams.value.multiSortMeta)
        });
    }

    const fetchDraftContent = async () => {
        await store.getAllDraftContentAction({
            platform: "dashboard",
            page: 1,
            per_page: 10,
            type: "DRAFT",
            order: multisortConvert(lazyParams.value.multiSortMeta) 
        })
    }

    const changeTabIndex = async ($event) => {
        isLoading.value = true;
        activeIndex.value = $event.index;

        if(activeIndex.value === 0) {
            await fetchPublishedContent();
            isLoading.value = false;
        }

        if(activeIndex.value === 1) {
            await fetchDraftContent();
            isLoading.value = false;
        }
    }

    return {
        isLoading,
        activeIndex,
        changeTabIndex
    }
}