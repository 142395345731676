export const communityRoutes = [
  {
    // to open when use community module start
    //   path: "/community/management",
    //   name: "communityManagementRoutes",
    //   children: [
    //     {
    //       path: "",
    //       name: "managementCommunity",
    //       component: () =>
    //         import("@/modules/community/view/ManagementCommunity.vue"),
    //       meta: {
    //         action: "view",
    //         resource: "community",
    //         layout: "default",
    //         title: "Management Community",
    //         breadcrumbs: [
    //           { title: "Management Community", routeName: "managementCommunity" },
    //         ],
    //       },
    //     },
    //     {
    //       path: ":id/detail",
    //       name: "reportPostDetail",
    //       component: () =>
    //         import("@/modules/community/entry/ReportPostDetail.vue"),
    //       meta: {
    //         action: "view",
    //         resource: "community",
    //         layout: "default",
    //         title: "Report Post Detail",
    //         breadcrumbs: [
    //           { title: "Management Community", routeName: "managementCommunity" },
    //           { title: "Detail", routeName: "reportPostDetail" },
    //         ],
    //       },
    //     },
    //     {
    //       path: ":id/community-detail",
    //       name: "communityDetail",
    //       component: () =>
    //         import("@/modules/community/entry/CommunityDetail.vue"),
    //       meta: {
    //         action: "view",
    //         resource: "community",
    //         layout: "default",
    //         title: "Community Detail",
    //         breadcrumbs: [
    //           { title: "Management Community", routeName: "managementCommunity" },
    //           { title: "Detail", routeName: "communityDetail" },
    //         ],
    //       },
    //     },
    //   ],
    // },
    // {
    //   path: "/community",
    //   name: "communityRouts",
    //   children: [
    //     {
    //       path: "feed",
    //       name: "communityFeed",
    //       component: () => import("@/modules/community/lists/CommunityList.vue"),
    //       meta: {
    //         // action: "manage",
    //         // resource: "community",
    //         layout: "default",
    //         title: "Community List",
    //         breadcrumbs: [
    //           { title: "Community List", routeName: "communityFeed" },
    //         ],
    //       },
    //     },
    //     {
    //       path: "member",
    //       name: "manageMember",
    //       component: () =>
    //         import("@/modules/community/lists/CommunityMember.vue"),
    //       meta: {
    //         action: "view",
    //         resource: "community-member",
    //         layout: "default",
    //         title: "Community Member",
    //         breadcrumbs: [
    //           { title: "Community Member", routeName: "manageMember" },
    //         ],
    //       },
    //     },
    //     {
    //       path: "reason-and-rule",
    //       name: "manageReasonRule",
    //       component: () =>
    //         import("@/modules/community/view/ManageReasonRule.vue"),
    //       meta: {
    //         action: "view",
    //         resource: "community-rule|community-reason",
    //         layout: "default",
    //         title: "Manage Reason / Rule",
    //         breadcrumbs: [
    //           { title: "Manage Reason / Rule", routeName: "manageReasonRule" },
    //         ],
    //       },
    //     },
    //     // {
    //     //   path: "activities-log",
    //     //   name: "activitiesLog",
    //     //   component: () => import("@/modules/community/lists/ActivityLog.vue"),
    //     //   meta: {
    //     //     // action: "manage",
    //     //     // resource: "community",
    //     //     layout: "default",
    //     //     title: "Activities Log",
    //     //     breadcrumbs: [
    //     //       { title: "Activities Log", routeName: "activitiesLog" },
    //     //     ],
    //     //   },
    //     // },
    //     {
    //       path: "manage-admin",
    //       name: "manageAdminRoutes",
    //       children: [
    //         {
    //           path: "",
    //           name: "manageAdmin",
    //           component: () =>
    //             import("@/modules/community/lists/ManageAdmin.vue"),
    //           meta: {
    //             action: "view",
    //             resource: "community-admin",
    //             layout: "default",
    //             title: "Manage Admin",
    //             breadcrumbs: [
    //               { title: "Manage Admin List", routeName: "manageAdmin" },
    //             ],
    //           },
    //         },
    //         {
    //           path: "assign-community-admin",
    //           name: "assignManageAdmin",
    //           component: () =>
    //             import("@/modules/community/entry/AssignManageAdmin.vue"),
    //           meta: {
    //             action: "view",
    //             resource: "community-admin",
    //             layout: "default",
    //             title: "Assign Community Admin",
    //             breadcrumbs: [
    //               { title: "Manage Admin List", routeName: "manageAdmin" },
    //               { title: "Assign", routeName: "assignManageAdmin" },
    //             ],
    //           },
    //         },
    //       ],
    //     },
    //   ],
    // to open when use community module end
  },
];
