const memberRoutes = [
  {
    path: "/members",
    name: "scsc-member",
    children: [
      {
        path: "",
        name: "MemberRegistered",
        component: () =>
          import("@/modules/scsc/member/view/MemberRegistered.vue"),
        meta: {
          action: "view",
          resource: "scsc-member",
          layout: "default",
          title: "SCSC Members List",
          breadcrumbs: [
            { title: "Member List", routeName: "MemberRegistered" },
          ],
        },
      },
      {
        path: ":id",
        name: "MemberDetail",
        component: () => import("@/modules/scsc/member/view/MemberDetail.vue"),
        meta: {
          action: "view",
          resource: "scsc-member",
          layout: "default",
          title: "Member Detail",
          breadcrumbs: [
            { title: "Member List", routeName: "MemberRegistered" },
            { title: "Member Detail", routeName: "MemberDetail" },
          ],
        },
      },
    ],
  },
  {
    path: "/pending-members",
    name: "scsc-pending-member",
    children: [
      {
        path: "",
        name: "PendingMember",
        component: () => import("@/modules/scsc/member/view/PendingMember.vue"),
        meta: {
          action: "view",
          resource: "scsc-member",
          layout: "default",
          title: "SCSC Pending Members List",
          breadcrumbs: [{ title: "Member List", routeName: "PendingMember" }],
        },
      },
    ],
  },
  {
    path: "/inactive-members",
    name: "scsc-inactive-member",
    children: [
      {
        path: "",
        name: "InactiveMember",
        component: () =>
          import("@/modules/scsc/member/view/InactiveMember.vue"),
        meta: {
          action: "view",
          resource: "scsc-member",
          layout: "default",
          title: "SCSC Inactive Members List",
          breadcrumbs: [{ title: "Member List", routeName: "InactiveMember" }],
        },
      },
    ],
  },
];

export default memberRoutes;
