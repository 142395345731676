import { defineStore } from "pinia";
import { useUserProfileService } from "@/modules/user/userProfileService";

export const useUserProfileStore = defineStore({
  id: "useUserProfileStore",
  state: () => ({
    editUserState: null,
    showUserState: null,
    showUserProfileState: null,
  }),

  getters: {
    getEditUser(state) {
      return state.editUserState;
    },
    getShowUser(state) {
      return state.showUserState;
    },
    getShowUserProfile(state) {
      return state.showUserProfileState;
    },
  },

  actions: {
    async editUserAction(id, data) {
      const response = await useUserProfileService.fetchEditUser(id, data);
      this.editUserState = response;
    },

    async showUserAction(id) {
      const response = await useUserProfileService.fetchShowUser(id);
      this.showUserState = response;
    },

    async showUserProfileAction(token) {
      const response = await useUserProfileService.fetchShowUserProfile(token);
      this.showUserProfileState = response;
    },
  },
});
