export const userAccountRoutes = [
  {
    path: "/user",
    name: "user-profile",
    children: [
      {
        path: "profile",
        name: "EditUserProfile",
        component: () => import("@/modules/user/entry/EditUserProfile.vue"),
        meta: {
          // resource: "scsc-users",
          // action: "update",
          layout: "default",
          title: "Users",
          breadcrumbs: [
            { title: "Edit User Profile", routeName: "EditUserProfile" },
          ],
        },
      },
    ],
  },
];
