import api from "@/libs/axios";
import qs from "qs";
import { endpoint } from "@/constants/endpoint";

const useContentService = {
  /**
   * Retrive Content List
   * @param {*} params
   * @returns
   */
  fetchAllContent: async (params) => {
    try {
      const result = await api.get(`${endpoint.cmsContents}`, {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });

      return result.data;
    } catch {
      return null;
    }
  },

  fechAddContent: async (params) => {
    const headers = { "Content-Type": "multipart/form-data" };
    const result = await api.post(`${endpoint.cmsContents}`, params, headers);
    return result.data;
  },

  fetchEditContent: async (params) => {
    try {
      const result = await api.get(`${endpoint.cmsContents}/${params.id}`);
      return result.data;
    } catch {
      return null;
    }
  },

  fetchUpdateContent: async (id, params) => {
    const headers = { "Content-Type": "multipart/form-data" };
    const result = await api.post(
      `${endpoint.cmsContents}/${id}`,
      params,
      headers
    );
    return result.data;
  },

  fetchDeleteContent: async (params) => {
    try {
      const result = await api.delete(`${endpoint.cmsContents}/${params.id}`);
      return result.data;
    } catch {
      return null;
    }
  },

  fetchResendNotification: async (params) => {
    try {
      const result = await api.post(
        `${endpoint.cmsContents}/${params.id}/resend-notification`
      );
      return result.data;
    } catch {
      return null;
    }
  },

  fetchTags: async () => {
    try {
      const result = await api.get(`${endpoint.cmsTags}`);
      return result.data;
    } catch {
      return null;
    }
  },

  fetchCreateTags: async (payload) => {
    try {
      const result = await api.post(`${endpoint.cmsTags}`, payload);
      return result.data;
    } catch {
      return null;
    }
  },

  fetchSortOrder: async (params) => {
    try {
      const result = await api.get(`${endpoint.cmsContents}/sort-orders`, {
        params: params,
      });
      return result.data;
    } catch {
      return null;
    }
  },
};

export { useContentService };
