const moduleRoutes = [
  // {
  //   path: "/resident/billpayment",
  //   name: "residentBillPyamentRoutes",
  //   children: [
  //     {
  //       path: "",
  //       name: "residentBillpayment",
  //       component: () =>
  //         import("@/modules/resident/billpayment/list/BillPayment.vue"),
  //       meta: {
  //         action: "view",
  //         resource: "bill",
  //         layout: "default",
  //         title: "Bill Payment",
  //         breadcrumbs: [
  //           { title: "Bill List", routeName: "residentBillpayment" },
  //         ],
  //       },
  //     },
  //     {
  //       path: ":id/detail",
  //       name: "BillpaymentDetail",
  //       component: () =>
  //         import("@/modules/resident/billpayment/entry/BillPaymentDetail.vue"),
  //       meta: {
  //         action: "view",
  //         resource: "bill",
  //         layout: "default",
  //         title: "Payment Detail",
  //         breadcrumbs: [
  //           { title: "Bill List", routeName: "residentBillpayment" },
  //           { title: "Edit", routeName: "BillpaymentDetail" },
  //         ],
  //       },
  //     },
  //     {
  //       path: "import",
  //       name: "BillpaymentImport",
  //       component: () =>
  //         import("@/modules/resident/billpayment/entry/ImportBill.vue"),
  //       meta: {
  //         action: "create",
  //         resource: "bill",
  //         title: "Bill Import",
  //         breadcrumbs: [
  //           { title: "Bill Import", routeName: "BillpaymentImport" },
  //         ],
  //       },
  //     },
  //   ],
  // },
];

export default moduleRoutes;
