<template>
  <div class="card">
    <div class="col-12">
      <h5 class="m-0 md:mb-2 block lg:hidden">Content List</h5>
    </div>

    <div class="col-12">
      <TabView
        ref="management_community"
        :activeIndex="activeIndex"
        @tab-change="changeTabIndex($event)"
      >
        <TabPanel header="Published CMS">
          <PublishedList />
        </TabPanel>

        <TabPanel header="Draft CMS">
          <DraftList />
        </TabPanel>
      </TabView>
    </div>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useContent } from "@/modules/cms/content/view/useContent";
import PublishedList from "@/modules/cms/content/list/PublishedList.vue";
import DraftList from "@/modules/cms/content/list/DraftList.vue";

export default defineComponent({
  name: "content",
  components: { PublishedList, DraftList },
  setup() {
    const { isLoading, activeIndex, changeTabIndex } = useContent();

    return {
      isLoading,
      activeIndex,
      changeTabIndex,
    };
  },
});
</script>