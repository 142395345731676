const routes = [
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    children: [
      {
        path: "",
        name: "termsAndConditionsList",
        component: () =>
          import("@/modules/termsAndConditions/list/TermsAndConditions.vue"),
        meta: {
          action: "view",
          resource: "terms-and-conditions",
          layout: "default",
          title: "Terms And Conditions",
          breadcrumbs: [
            {
              title: "Terms And Conditions List",
              routeName: "termsAndConditionsList",
            },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "editTermsAndConditions",
        component: () =>
          import(
            "@/modules/termsAndConditions/entry/EditTermsAndConditions.vue"
          ),
        meta: {
          action: "view",
          resource: "terms-and-conditions",
          layout: "default",
          title: "Edit Terms And Conditions",
          breadcrumbs: [
            { title: "Terms And Conditions", routeName: "termsAndConditionsList" },
            { title: "Edit", routeName: "editTermsAndConditions" },
          ],
        },
      },
    ],
  },
];

export default routes;
