const menuList = [
  {
    // label: "",
    // icon: "pi pi-fw pi-search",
    items: [
      // {
      //   label: "Home",
      //   icon: "",
      //   items: [
      //     {
      //       label: "Dashboard",
      //       icon: "pi pi-fw pi-home",
      //       to: "/",
      //       action: "read",
      //       resource: "home",
      //     },
      //   ],
      // },
      {
        label: "resident.menu",
        icon: "ri-community-line ri-lg",
        action: "view",
        resource: "resident",
        items: [
          {
            label: "active_request.menu",
            icon: "ri-user-follow-line",
            to: "/resident/active",
            action: "view",
            resource: "resident",
          },
          {
            label: "pending_request.menu",
            icon: "ri-shield-user-line",
            to: "/resident/pending",
            action: "view",
            resource: "resident",
          },
          {
            label: "inactive_request.menu",
            icon: "pi pi-user-minus",
            to: "/resident/inactive",
            action: "view",
            resource: "resident",
          },
          {
            label: "reject_request.menu",
            icon: "ri-user-unfollow-line",
            to: "/resident/reject",
            action: "view",
            resource: "resident",
          },
          {
            label: "reject_remark.menu",
            icon: "ri-edit-box-line",
            to: "/resident/reject-remark",
            action: "view",
            resource: "resident",
          },
          // {
          //   label: "email_recipient_setting.menu",
          //   icon: "ri-mail-send-line",
          //   to: "/resident/email-recipient",
          //   action: "view",
          //   resource: "resident",
          // },
        ],
      },
      // {
      //   label: "billpayment.menu",
      //   icon: "ri-bank-card-line ri-lg",
      //   action: "view",
      //   resource: "bill",
      //   items: [
      //     {
      //       label: "payment_list.menu",
      //       icon: "ri-bill-line",
      //       to: "/resident/billpayment",
      //       action: "view",
      //       resource: "bill",
      //     },
      //   ],
      // },
      {
        label: "case_management.menu",
        icon: "ri-price-tag-3-line ri-lg",
        action: "view",
        resource: "case|case-category",
        items: [
          {
            label: "dashboard.menu",
            icon: "ri-function-line",
            to: "/case-management/dashboard",
            action: "view",
            resource: "case",
          },
          {
            label: "categories.menu",
            icon: "ri-list-check-2",
            to: "/case-management/categories",
            action: "view",
            resource: "case-category",
          },
          // {
          //   label: "services.menu",
          //   icon: "ri-tools-line",
          //   to: "/case-management/services",
          //   action: "view",
          //   resource: "case-category|case",
          // },
          {
            label: "cases.menu",
            icon: "ri-briefcase-3-line",
            to: "/case-management/cases",
            action: "view",
            resource: "case",
          },
          {
            label: "cases_settings.menu",
            icon: "ri-settings-2-line",
            to: "/setting/working-days-and-holidays",
            action: "view",
            resource: "case-setting",
          },
          // {
          //   label: "email_recipient_setting.menu",
          //   icon: "ri-mail-send-line",
          //   to: "/case-management/email-recipient",
          //   action: "view",
          //   resource: "case",
          // },
        ],
      },
      {
        label: "scsc.menu",
        icon: "icon-scsc text-lg",
        action: "view",
        resource: "scsc-subscription|scsc-prepaid-package|scsc-member",
        items: [
          {
            label: "dashboard.menu",
            icon: "ri-function-line",
            to: "/scsc/dashboard",
            action: "view",
            resource: "scsc-member",
          },
          {
            label: "sales.menu",
            icon: "ri-pie-chart-line",
            action: "view",
            resource: "scsc-subscription|scsc-prepaid-package",
            items: [
              {
                label: "subscriptions.menu",
                icon: "ri-ticket-line",
                to: "/scsc/subscriptions",
                action: "view",
                resource: "scsc-subscription",
              },
              {
                label: "prepaid_packages.menu",
                icon: "ri-ticket-line",
                to: "/scsc/prepaid-packages",
                action: "view",
                resource: "scsc-prepaid-package",
              },
            ],
          },
          {
            label: "members.menu",
            icon: "ri-team-line ri-lg",
            action: "view",
            resource: "scsc-member",
            items: [
              {
                label: "members.active_members",
                icon: "ri-user-follow-line",
                to: "/members",
                action: "view",
                resource: "scsc-member",
              },
              // {
              //   label: "Registered Members",
              //   icon: "pi pi-fw pi-users",
              //   to: "/registered-members",
              //   action: "read",
              //   resource: "user",
              // },
              {
                label: "pending_members.menu",
                icon: "ri-user-line",
                to: "/pending-members",
                action: "view",
                resource: "scsc-member",
              },
              {
                label: "inactive_members.menu",
                icon: "ri-user-unfollow-line",
                to: "/inactive-members",
                action: "view",
                resource: "scsc-member",
              },
            ],
          },
        ],
      },
      {
        label: "facility_booking.menu",
        icon: "ri-calendar-todo-line ri-lg",
        action: "view",
        resource: "facility|facility-slot|facility-booking|facility-court",
        items: [
          {
            label: "facility_booking.dashboard",
            icon: "ri-function-line",
            to: "/facility-booking/dashboard",
            action: "view",
            resource: "facility|facility-slot|facility-booking|facility-court",
          },
          {
            label: "facility_booking.sport_type",
            icon: "ri-football-line",
            to: "/facility-booking/sport-types",
            action: "view",
            resource: "facility",
          },
          {
            label: "facility_booking.court",
            icon: "ri-building-2-line",
            to: "/facility-booking/courts",
            action: "view",
            resource: "facility-court",
          },
          {
            label: "facility_booking.time_slot",
            icon: "ri-time-line",
            to: "/facility-booking/timeslots",
            action: "view",
            resource: "facility-slot",
          },
          {
            label: "facility_booking.booking",
            icon: "ri-calendar-todo-line",
            to: "/facility-booking/bookings",
            action: "view",
            resource: "facility-booking",
          },
        ],
      },
      {
        label: "cms.menu",
        icon: "ri-file-list-3-line ri-lg",
        action: "view",
        resource: "content",
        items: [
          {
            label: "categories.menu",
            icon: "ri-list-check-2",
            to: "/cms/categories",
            action: "view",
            resource: "content",
          },
          {
            label: "contents.menu",
            icon: "ri-edit-box-line",
            to: "/cms/contents",
            action: "view",
            resource: "content",
          },
          // {
          //   label: "contents.setting",
          //   icon: "ri-settings-2-line",
          //   to: "/cms/setting",
          //   action: "manage",
          //   resource: "cms",
          // },
        ],
      },

      // to open when use community module start

      // {
      //   label: "community.menu",
      //   icon: "ri-cast-line ri-lg",
      //   action: "view",
      //   resource:
      //     "community|community-rule|community-reason|community-member|community-admin",
      //   items: [

      // to open when use community module end
      // {
      //   label: "community.menu",
      //   icon: "ri-chat-voice-line",
      //   to: "/community/feed",
      //   action: "manage",
      //   resource:"community",
      // },
      // to open when use community module start
      // {
      //   label: "community.management",
      //   icon: "ri-gallery-line",
      //   to: "/community/management",
      //   action: "view",
      //   resource: "community",
      // },
      // {
      //   label: "community.manageRule",
      //   icon: "ri-file-shield-2-line",
      //   to: "/community/reason-and-rule",
      //   action: "view",
      //   resource: "community-rule|community-reason",
      // },
      // {
      //   label: "community.manageMember",
      //   icon: "ri-group-line",
      //   to: "/community/member",
      //   action: "view",
      //   resource: "community-member",
      // },
      // {
      //   label: "community.manageAdmin",
      //   icon: "ri-reserved-line",
      //   to: "/community/manage-admin",
      //   action: "view",
      //   resource: "community-admin",
      // },
      // to open when use community module end
      // {
      //   label: "community.activitylog",
      //   icon: "ri-history-line",
      //   to: "/community/activities-log",
      //   // action: "manage",
      //   // resource: "settings",
      // },

      // to open when use community module start

      //   ],
      // },

      // to open when use community module end
      {
        label: "user.menu",
        icon: "ri-group-line ri-lg",
        action: "view",
        resource: "user",
        items: [
          {
            label: "users.dashboard_menu",
            icon: "ri-user-settings-line",
            to: "/users/dashboard-user",
            action: "view",
            resource: "user",
          },
          {
            label: "users.app_menu",
            icon: "ri-user-line",
            to: "/users/app-user",
            action: "view",
            resource: "user",
          },
        ],
      },
      {
        label: "permission.menu",
        icon: "ri-shield-check-line ri-lg",
        action: "view",
        resource: "role",
        items: [
          {
            label: "roles.menu",
            icon: "ri-shield-user-line",
            to: "/roles",
            action: "view",
            resource: "role",
          },
          // {
          //   label: "permissions.menu",
          //   icon: "ri-shield-user-line",
          //   to: "/permissions",
          //   // action: "view",
          //   // resource: "permission",
          // },
        ],
      },
      {
        label: "faq.menu",
        icon: "ri-question-answer-line ri-lg",
        action: "create",
        resource: "faq|faq-category",
        items: [
          {
            label: "faq.categories",
            icon: "ri-list-check-2",
            to: "/faq/categories",
            action: "create",
            resource: "faq-category",
          },
          {
            label: "faq.q&a",
            icon: "ri-questionnaire-line",
            to: "/faq/q&a",
            action: "create",
            resource: "faq",
          },
        ],
      },
      {
        label: "terms_and_conditions.menu",
        icon: "ri-file-list-3-line ri-lg",
        to: "/terms-and-conditions",
        action: "view",
        resource: "terms-and-conditions",
      },
      {
        label: "setting.menu",
        icon: "ri-settings-2-line ri-lg",
        to: "/settings",
        action: "view",
        resource: "setting",
      },
      {
        label: "app_version.menu",
        icon: "ri-smartphone-line ri-lg",
        to: "/appversion",
        action: "create",
        resource: "app-version",
      },
    ],
  },
];

export default menuList;
