import { app } from "./main-app";
import { reactive } from "vue";
import { useThemeStore } from "@/store/themeStore";

const themeStore = useThemeStore();

app.config.globalProperties.$appState = reactive({
  theme: themeStore.getCurrentTheme,
  darkTheme: themeStore.getCurrentTheme === "lara-dark-indigo" ? true : false,
});
