const routes = [
  {
    path: "/roles",
    name: "roles",
    children: [
      {
        path: "",
        name: "roleList",
        component: () =>
          import("@/modules/applicationPermission/role/list/Role.vue"),
        meta: {
          action: "view",
          resource: "role",
          layout: "default",
          title: "Roles",
          breadcrumbs: [{ title: "Role List", routeName: "roleList" }],
        },
      },
      {
        path: "create",
        name: "newRole",
        component: () =>
          import("@/modules/applicationPermission/role/entry/NewRole.vue"),
        meta: {
          action: "create",
          resource: "role",
          layout: "default",
          title: "Create Role",
          breadcrumbs: [
            { title: "Role List", routeName: "roleList" },
            { title: "Create", routeName: "newRole" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "editRole",
        component: () =>
          import("@/modules/applicationPermission/role/entry/EditRole.vue"),
        meta: {
          action: "view",
          resource: "role",
          layout: "default",
          title: "Edit Role",
          breadcrumbs: [
            { title: "Role List", routeName: "roleList" },
            { title: "Edit", routeName: "editRole" },
          ],
        },
      },
      {
        path: ":id/view",
        name: "viewRole",
        component: () =>
          import("@/modules/applicationPermission/role/entry/ViewRole.vue"),
        meta: {
          action: "view",
          resource: "role",
          layout: "default",
          title: "View Role",
          breadcrumbs: [
            { title: "Role List", routeName: "roleList" },
            { title: "View", routeName: "viewRole" },
          ],
        },
      },
    ],
  },
];

export default routes;
