const routes = [
  {
    path: "/appversion/",
    name: "appVersion",
    children: [
      {
        path: "",
        name: "appVersionList",
        component: () => import("@/modules/appVersion/list/AppVersionList.vue"),
        meta: {
          action: "create",
          resource: "app-version",
          layout: "default",
          title: "App Versions",
          breadcrumbs: [
            { title: "Versions List", routeName: "appVersionList" },
          ],
        },
      },
      {
        path: "create",
        name: "newAppVersion",
        component: () => import("@/modules/appVersion/entry/NewAppVersion.vue"),
        meta: {
          action: "create",
          resource: "app-version",
          layout: "default",
          title: "Create AppVersion",
          breadcrumbs: [
            { title: "Versions List", routeName: "appVersionList" },
            { title: "New", routeName: "newAppVersion" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "editAppVersion",
        component: () =>
          import("@/modules/appVersion/entry/EditAppVersion.vue"),
        meta: {
          action: "update",
          resource: "app-version",
          layout: "default",
          title: "Edit Version",
          breadcrumbs: [
            { title: "Versions List", routeName: "appVersionList" },
            { title: "Edit", routeName: "editAppVersion" },
          ],
        },
      },
    ],
  },
];

export default routes;
