import EventBus from "@/libs/AppEventBus";

export const httpErrorHandler = (error) => {
  let info = {
    severity: "error",
    summary: "Network Error",
    detail: "Data can not be retrived",
  };

  if (error.status === 422) {
    info.summary = error.statusText;
    info.detail = error.data.message;
  }

  EventBus.emit("show-toast", info);
  return error;
};

export const httpResponseHandler = (response, summary) => {
  EventBus.emit("show-toast", {
    severity: "success",
    summary: summary,
    detail: response.data.message,
  });

  return response;
};

export const removeLocalStorageItem = () => {
  localStorage.removeItem("userToken");
  localStorage.removeItem("userData");
  localStorage.removeItem("probability");
  localStorage.removeItem("userRoles");
  localStorage.removeItem("nrc");
  localStorage.removeItem("pushyTokenAppId");
  localStorage.removeItem("pushyTokenAuth");
  localStorage.removeItem("pushyToken");
};
