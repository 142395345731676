const routes = [
  {
    path: "/settings",
    name: "application-setting",
    children: [
      {
        path: "",
        name: "settingList",
        component: () => import("@/modules/setting/entry/EditSetting.vue"),
        meta: {
          action: "view",
          resource: "setting",
          layout: "default",
          title: "Settings",
          breadcrumbs: [{ title: "Setting List", routeName: "settingList" }],
        },
      },
    ],
  },
];

export default routes;
