<template>
  <Menu ref="userMenu" :model="profileMenuItems" :popup="true" />
  <div
    class="flex justify-content-center align-items-center cursor-pointer ml-3"
    @click="togglePanel"
  >
    <Avatar
      :image="user && user.profile_picture ? user.profile_picture : userImage()"
      size="large"
      shape="circle"
    />
    <div class="user-menu ml-4 hidden lg:block">
      <div>{{ user ? user.name : "" }}</div>
    </div>
    <i
      class="pi pi-angle-down ml-2 hidden lg:block"
      style="color: var(--primary-color)"
    ></i>
  </div>
</template>
<script>
import { ref, defineComponent, onMounted, onUnmounted, computed } from "vue";

import { useAuthStore } from "@/modules/auth/authStore";
import { useRouter } from "vue-router";
import { useConfirm } from "primevue/useconfirm";

import Avatar from "primevue/avatar";
import Menu from "primevue/menu";

export default defineComponent({
  name: "AppUserProfile",
  components: {
    Avatar,
    Menu,
  },
  methods: {
    userImage() {
      return require("@/assets/images/profile.png");
    },
  },
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();
    const userMenu = ref();
    const confirm = useConfirm();

    const profileMenuItems = ref([
      {
        label: "Profile",
        icon: "pi pi-user",
        command: () => {
          router.push("/user/profile");
        },
      },
      {
        label: "Logout",
        icon: "pi pi-sign-out",
        command: () => {
          userLogout();
        },
      },
    ]);

    const togglePanel = (event) => {
      userMenu.value.toggle(event);
    };

    const handleScroll = () => {
      if (userMenu.value) {
        userMenu.value.hide();
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    const user = computed(() => {
      return authStore.getUserData;
    });

    const showConfirmDialog = () => {
      confirm.require({
        message: "Are you sure?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sign out",
        rejectLabel: "Cancel",
        acceptClass: "p-button-danger",
        rejectClass: "p-button-secondary p-button-text",

        accept: () => {
          userLogout();
        },
        reject: () => {
          //callback to execute when corporate rejects the action
        },
        onHide: () => {
          //Callback to execute when dialog is hidden
        },
      });
    };

    const userLogout = () => {
      authStore.logout();
    };

    return {
      user,
      userMenu,
      profileMenuItems,
      togglePanel,
      userLogout,
      showConfirmDialog,
    };
  },
});
</script>
<style scoped>
.user-menu {
  min-width: 100px;
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 0 !important;
}
.p-dialog-footer {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
</style>
