const moduleRoutes = [
  {
    path: "/facility-booking/timeslots",
    name: "timeslot",
    children: [
      {
        path: "",
        name: "timeslotList",
        component: () =>
          import("@/modules/facilityBooking/timeslot/list/List.vue"),
        meta: {
          action: "view",
          resource: "facility-slot",
          layout: "default",
          title: "Timeslot",
          breadcrumbs: [{ title: "Timeslot List", routeName: "timeslotList" }],
        },
      },
      {
        path: "create",
        name: "newTimeslot",
        component: () =>
          import("@/modules/facilityBooking/timeslot/entry/New.vue"),
        meta: {
          action: "create",
          resource: "facility-slot",
          layout: "default",
          title: "Create Timeslot",
          breadcrumbs: [
            { title: "Timeslot List", routeName: "timeslotList" },
            { title: "New", routeName: "newTimeslot" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "editTimeslot",
        component: () =>
          import("@/modules/facilityBooking/timeslot/entry/Edit.vue"),
        meta: {
          action: "view",
          resource: "facility-slot",
          layout: "default",
          title: "Edit Timeslot",
          breadcrumbs: [
            { title: "Timeslot List", routeName: "timeslotList" },
            { title: "Edit", routeName: "editTimeslot" },
          ],
        },
      },
    ],
  },
];

export default moduleRoutes;
