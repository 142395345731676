const routes = [
  {
    path: "/faq/q&a",
    name: "faqRoutes",
    children: [
      {
        path: "",
        name: "faqQAndAList",
        component: () => import("@/modules/faq/list/FAQQAndAList.vue"),
        meta: {
          action: "create",
          resource: "faq",
          layout: "default",
          title: "FAQ Q&A",
          breadcrumbs: [{ title: "FAQ Q&A List", routeName: "faqQAndAList" }],
        },
      },
      {
        path: "create",
        name: "newQAndA",
        component: () => import("@/modules/faq/entry/NewFAQQAndA.vue"),
        meta: {
          action: "create",
          resource: "faq",
          layout: "default",
          title: "Create FAQ Q&A",
          breadcrumbs: [
            { title: "FAQ Q&A List", routeName: "faqQAndAList" },
            { title: "Create FAQ", routeName: "newQAndA" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "editQAndA",
        component: () => import("@/modules/faq/entry/EditFAQQAndA.vue"),
        meta: {
          //   action: "index",
          //   resource:
          layout: "default",
          title: "Edit FAQ Q&A",
          breadcrumbs: [
            { title: "FAQ Q&A List", routeName: "faqQAndAList" },
            { title: "Edit FAQ", routeName: "editQAndA" },
          ],
        },
      },
    ],
  },
  {
    path: "/faq/categories",
    name: "faqCategories",
    children: [
      {
        path: "",
        name: "FAQCategoriesList",
        component: () => import("@/modules/faq/list/FAQCategoriesList.vue"),
        meta: {
          action: "create",
          resource: "faq-category",
          layout: "default",
          title: "FAQ Categories",
          breadcrumbs: [
            { title: "FAQ Category List", routeName: "FAQCategoriesList" },
          ],
        },
      },
      {
        path: "create",
        name: "newFAQCategories",
        component: () => import("@/modules/faq/entry/NewFAQCategory.vue"),
        meta: {
          action: "create",
          resource: "faq-category",
          layout: "default",
          title: "Create FAQ Category",
          breadcrumbs: [
            { title: "FAQ Category List", routeName: "FAQCategoriesList" },
            { title: "Create FAQ Category", routeName: "newFAQCategories" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "editFAQCategory",
        component: () => import("@/modules/faq/entry/EditFAQCategory.vue"),
        meta: {
          //   action: "index",
          //   resource:
          layout: "default",
          title: "Edit FAQ Category",
          breadcrumbs: [
            { title: "FAQ Category List", routeName: "FAQCategoriesList" },
            { title: "Edit FAQ Category", routeName: "editFAQCategory" },
          ],
        },
      },
    ],
  },
];

export default routes;
