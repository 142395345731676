import { nrcService } from "@/services/nrcService";
import moment from "moment";
import numeral from "numeral";

const dateLong = (val) => {
  if (val) return moment(val).format("YYYY-MM-DD HH:mm:ss");
  return "";
};

const dateShort = (val) => {
  if (val) return moment(val).format("YYYY-MM-DD");
  return "";
};

const fileSizeFormat = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const currencyFormat = (val) => {
  if (val) return numeral(val).format("0,0");
  return 0;
};

const convertToYear = (value) => {
  if (value) return moment().diff(value, "years");
  return 0;
};

const utcToLocal = (val) => {
  if (val) return moment.utc(val).local().format("YYYY-MM-DD HH:mm:ss");
  // return moment.utc().local().format("YYYY-MM-DD HH:mm:ss");
  return "";
};

const LocalToUTC = (val) => {
  if (val) return moment(val).utc().format("YYYY-MM-DD HH:mm:ss");
  return moment.utc().format("YYYY-MM-DD HH:mm:ss");
};

const fromNow = (val) => {
  if (val) return moment(val).local().fromNow();
  return "";
};

const nrcFormat = (nrc) => {
  const getNrc = localStorage.getItem("nrc");
  let dataSource = getNrc ? JSON.parse(getNrc) : getNrc;

  if (!getNrc) {
    nrcService.nrcList().then((result) => {
      localStorage.setItem("nrc", JSON.stringify(result));
      dataSource = result;
    });
  }

  let nrcArray = [1, 1, 1, null];

  if (nrc) {
    nrcArray = nrc.split("-");
  }

  const state = dataSource.state.filter(
    (state) => state.id === Number(nrcArray[0])
  )[0];
  const township = dataSource.township.filter(
    (township) => township.id === Number(nrcArray[1])
  )[0];
  const townshipList = dataSource.township.filter(
    (ts) => ts.nrc_state_id === township.nrc_state_id
  );
  const nrcType = dataSource.type.filter(
    (type) => type.id === Number(nrcArray[2])
  )[0];
  const nrcNumber = nrcArray[3];

  const result = {
    state: state,
    township: township,
    townshipList: townshipList,
    type: nrcType,
    number: nrcNumber,
    nrc_format: nrcNumber
      ? `${state.code}/${township.code}(${nrcType.code})${nrcNumber}`
      : "",
  };

  return result;
};

const getTime = (timestamps) => {
  return moment(timestamps).format("HH:mm A");
};

const covertTimeFormat = (val) => {
  if (val) {
    if (val.length === 8) return val;
    else return moment(val).second(0).millisecond(0).format("HH:mm:ss");
  }
  return "";
};

const convertDateTime = (date, hour) => {
  if (date && hour) {
    const datetimeString = moment(
      `${date} ${hour}`,
      "YYYY-MM-DD HH:mm:ss"
    ).format();
    return datetimeString;
  }
  return "";
};

const utcToLocalDateOnly = (val) => {
  if (val) return moment.utc(val).local().format("YYYY-MM-DD");
  return "";
};

const yearMonth = (val) => {
  if (val) return moment(val).format("YYYY-MM");
  return "";
};

export {
  dateLong,
  dateShort,
  currencyFormat,
  convertToYear,
  nrcFormat,
  utcToLocal,
  fileSizeFormat,
  fromNow,
  LocalToUTC,
  getTime,
  covertTimeFormat,
  convertDateTime,
  utcToLocalDateOnly,
  yearMonth
};
