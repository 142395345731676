const moduleRoutes = [
  {
    path: "/facility-booking/courts",
    name: "court",
    children: [
      {
        path: "",
        name: "courtList",
        component: () =>
          import("@/modules/facilityBooking/court/list/List.vue"),
        meta: {
          action: "view",
          resource: "facility-court",
          layout: "default",
          title: "Court",
          breadcrumbs: [{ title: "Court List", routeName: "courtList" }],
        },
      },
      {
        path: "create",
        name: "newCourt",
        component: () =>
          import("@/modules/facilityBooking/court/entry/New.vue"),
        meta: {
          action: "create",
          resource: "facility-court",
          layout: "default",
          title: "Create Court",
          breadcrumbs: [
            { title: "Court List", routeName: "courtList" },
            { title: "New", routeName: "newCourt" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "editCourt",
        component: () =>
          import("@/modules/facilityBooking/court/entry/Edit.vue"),
        meta: {
          action: "view",
          resource: "facility-court",
          layout: "default",
          title: "Edit Court",
          breadcrumbs: [
            { title: "Court List", routeName: "courtList" },
            { title: "Edit", routeName: "editCourt" },
          ],
        },
      },
    ],
  },
];

export default moduleRoutes;
