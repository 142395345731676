<template>
  <div class="layout-collapse-menu-header">
    <div class="flex align-items-center justify-content-between">
      <router-link :to="{ name: 'dashboard' }" class="w-full">
        <div class="flex justify-content-start align-items-center">
          <img
            class="menu-collapse-logo fadeinup animation-duration-500"
            alt="Logo"
            :src="topbarImage()"
          />
          <img
            class="menu-collapse-label fadeinleft animation-duration-500"
            alt="Logo"
            :src="require('@/assets/images/starcity.svg')"
          />
          <!-- <span class="menu-collapse-label ml-2 text-2xl text-bold"
            >Star City
          </span> -->
        </div>
      </router-link>
      <button
        v-if="!mobileMenuActive"
        class="p-link layout-collapse-menu-button layout-collapse-topbar-button ml-0 menu-toggle"
        @click="onMenuToggle"
      >
        <i
          class="pi"
          :class="menuActive ? 'ri-lock-unlock-line' : 'ri-lock-line'"
          style="font-size: 1.5rem; color: var(--text-color)"
          v-tooltip.bottom="menuActive ? 'Sidebar Unlock' : 'Sidebar Lock'"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuActive: Boolean,
    mobileMenuActive: Boolean,
  },
  methods: {
    onMenuToggle(event) {
      // eslint-disable-next-line vue/require-explicit-emits
      this.$emit("menu-toggle", event);
    },
    topbarImage() {
      return this.$appState.darkTheme
        ? require("@/assets/images/logo.png")
        : require("@/assets/images/logo.png");
    },
  },
};
</script>
