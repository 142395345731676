const authRoutes = [
  {
    path: "/login",
    name: "login",
    component: () => import("./login/Login"),
    meta: { redirectIfLoggedIn: true },
  },
  {
    path: "/set-password/:token",
    name: "SetPassword",
    component: () => import("./setPassword/SetPassword"),
    meta: {
      layout: "full",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/set-passcode/:token",
    name: "SetPasscodeWithToken",
    component: () => import("./setPasscodeWithToken/SetPasscodeWithToken"),
    meta: {
      layout: "full",
      redirectIfLoggedIn: false,
    },
  },
  {
    path: "/set-passcode",
    name: "SetPasscode",
    component: () => import("./setPasscode/SetPasscode"),
    meta: {
      layout: "full",
      redirectIfLoggedIn: false,
    },
  },

  // {
  //   path: "/set-password/success",
  //   name: "SetPasswordSuccess",
  //   component: () => import("@/pages/SetPasswordSuccess.vue"),
  //   meta: {
  //     layout: "full",
  //     redirectIfLoggedIn: false,
  //   },
  // },
];

export default authRoutes;
