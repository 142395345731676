export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "mm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မြန်မာ"])},
  "scsc": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCSC"])}
  },
  "dashboard": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
  },
  "sales": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])}
  },
  "prepaid_packages": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepaid Packages"])}
  },
  "subscriptions": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])}
  },
  "members": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
    "active_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Members"])}
  },
  "app_version": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Versions"])}
  },
  "pending_members": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Members"])}
  },
  "cms": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CMS"])}
  },
  "resident": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residents"])}
  },
  "billpayment": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill Payment"])}
  },
  "payment_list": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resident Bill"])}
  },
  "active_request": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Resident"])}
  },
  "pending_request": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Resident"])}
  },
  "inactive_request": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive Resident"])}
  },
  "reject_request": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject Resident"])}
  },
  "reject_remark": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject Remark"])}
  },
  "categories": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])}
  },
  "contents": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contents"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting"])}
  },
  "user": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
  },
  "users": {
    "dashboard_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Users"])},
    "app_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Users"])}
  },
  "permission": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission"])}
  },
  "roles": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])}
  },
  "permissions": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])}
  },
  "case_management": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case Management"])}
  },
  "cases": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cases"])}
  },
  "cases_settings": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting"])}
  },
  "setting": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
  },
  "community": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community Feed"])},
    "activitylog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Log"])},
    "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])},
    "manageRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason / Rule"])},
    "manageMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community Member"])},
    "manageAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Admin"])}
  },
  "terms_and_conditions": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms And Conditions"])}
  },
  "inactive_members": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive Members"])}
  },
  "facility_booking": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Booking"])},
    "sport_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport Type"])},
    "court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Court"])},
    "court_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Court Feature"])},
    "time_slot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeslot"])},
    "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])}
  },
  "services": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])}
  },
  "faq": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "q&a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q&A"])}
  }
}