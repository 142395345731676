const moduleRoutes = [
  {
    path: "/facility-booking/sport-types",
    name: "sportType",
    children: [
      {
        path: "",
        name: "sportTypeList",
        component: () =>
          import("@/modules/facilityBooking/sportType/list/List.vue"),
        meta: {
          action: "view",
          resource: "facility",
          layout: "default",
          title: "Sport Type",
          breadcrumbs: [
            { title: "Sport Type List", routeName: "sportTypeList" },
          ],
        },
      },
      {
        path: "create",
        name: "newSportType",
        component: () =>
          import("@/modules/facilityBooking/sportType/entry/New.vue"),
        meta: {
          action: "create",
          resource: "facility",
          layout: "default",
          title: "Create Sport Type",
          breadcrumbs: [
            { title: "Sport Type List", routeName: "sportTypeList" },
            { title: "New", routeName: "newSportType" },
          ],
        },
      },
      {
        path: ":id/edit",
        name: "editSportType",
        component: () =>
          import("@/modules/facilityBooking/sportType/entry/Edit.vue"),
        meta: {
          action: "view",
          resource: "facility",
          layout: "default",
          title: "Edit Sport Type",
          breadcrumbs: [
            { title: "Sport Type List", routeName: "sportTypeList" },
            { title: "Edit", routeName: "editSportType" },
          ],
        },
      },
    ],
  },
];

export default moduleRoutes;
