import { ref, watch, watchEffect, onBeforeUnmount } from "vue";
import { useDebounceFn } from "@/utils/debounce";
import { multisortConvert } from "@/utils/multisort";
import { useContentStore } from "@/modules/cms/content/contentStore";
import EventBus from "@/libs/AppEventBus";
import { useConfirm } from "primevue/useconfirm";

export const usePublishedList = () => {
  const isLoading = ref(false);
  const publishedList = ref([]);
  const totalRecords = ref(0);
  const dt = ref({});
  const lazyParams = ref({});
  const search = ref("");

  const store = useContentStore();
  const confirm = useConfirm();

  onBeforeUnmount(() => {
    store.$reset();
    store.$dispose();
  });

  watch(
    [search],
    useDebounceFn(() => {
      resetPagination();
      fetchData();
      loadingData();
    }, 500)
  );

  watchEffect(async () => {
    const result = await store.publishedContentState;
    if (result) {
      setData(result);
    }
  });

  const setData = (result) => {
    const { data, total } = result.data;
    data.map((value) => {
      value.severity =
        value.status === "ACTIVE"
          ? "success"
          : value.status === "DRAFT"
          ? "warning"
          : "info";

      return value;
    });

    publishedList.value = data;
    totalRecords.value = total;
    return;
  };

  const loadingData = async () => {
    const result = await store.publishedContentState;
    setData(result);
  };

  const fetchData = async () => {
    isLoading.value = true;

    await store.getAllPublishedContentAction({
      platform: "dashboard",
      page: (lazyParams.value.page += 1),
      per_page: lazyParams.value.rows,
      order: multisortConvert(lazyParams.value.multiSortMeta),
      search: search.value,
    });

    isLoading.value = false;
  };

  const resetPagination = () => {
    lazyParams.value = {
      page: 0,
      rows: dt.value.rows ? dt.value.rows : 10,
      multiSortMeta: [],
      first: 0,
    };
  };

  const onPage = (event) => {
    lazyParams.value = event;
    // lazyParams.value.multiSortMeta = [];
    fetchData();
    loadingData();
  };

  const onSort = (event) => {
    lazyParams.value = event;
    lazyParams.value.page = 0;
    lazyParams.value.first = 0;
    fetchData();
    loadingData();
  };

  const showConfirmDialog = (id) => {
    confirm.require({
      message: "Are you sure resend notification?",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Yes, sure",
      rejectLabel: "Cancel",
      acceptClass: "p-button-primary",
      rejectClass: "p-button-danger p-button-text",
      accept: () => {
        resendNotification(id);
      },
      reject: () => {
        //callback to execute when user rejects the action
      },
      onHide: () => {
        //Callback to execute when dialog is hidden
      },
    });
  };

  const resendNotification = async (id) => {
    isLoading.value = true;

    await store.resendNotificationAction({
      id: id,
    });

    const response = store.getResendNotification;

    if (response) {
      EventBus.emit("show-toast", {
        severity: "success",
        summary: "",
        detail: response.message,
      });
    }

    isLoading.value = false;
  };

  return {
    isLoading,
    publishedList,
    totalRecords,
    dt,
    lazyParams,
    search,
    onPage,
    onSort,
    showConfirmDialog,
  };
};
